import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DefaultLayout } from '~/layouts/default-layout'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import UsersAnalytics from './components/users-analytics'
import { UsersSubmissions } from './components/users-submissions'

export const CourseAnalyticsPage = () => {
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)
  const [value, setValue] = React.useState(0)

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`Quest Analytics "${course?.name}"`} />}
    >
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Users Analytics" />
        <Tab label="Users submissions" />
      </Tabs>
      <Box pt={2}>
        {value === 0 && <UsersAnalytics />}
        {value === 1 && <UsersSubmissions />}
      </Box>
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
