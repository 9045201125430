import React from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

type Props = {
  value: string | any
  label?: string
  onChange: (value: any) => void
  options: {
    text?: string
    label?: string
    value: string | any
  }[]
  onOpen?: (value: any) => void
}

export const InputSelect = ({ value, label, options, onChange, onOpen }: Props) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange} onOpen={onOpen} label={label}>
        {options.map(({ text, label, value }, index) => (
          <MenuItem key={`${value}-${index}`} value={value}>
            {label ?? text ?? value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
