import React, { useContext, useEffect } from 'react'
import { CourseMainInfoForm } from '~/components/course-main-info-form'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'

export const EditCourseOptions = () => {
  const { id } = useCourseRoute()
  const { course, getCourse, handleUpdateCourse } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [getCourse, id])

  if (!course || course.id !== id) {
    return null
  }

  return <CourseMainInfoForm course={course} onSave={handleUpdateCourse} />
}
