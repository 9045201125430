import React from 'react'
import { Link } from 'react-router-dom'

import { Box, BoxProps, Typography } from '@mui/material'

import { CustomButton } from './button'

type Props = {
  title?: string
  buttons?: {
    text: string
    type?: 'upload' | 'navigate' | string
    disabled?: boolean
    onPress: () => void
    handleOpenFile?: (e: React.ChangeEvent<HTMLInputElement>) => void
    uploadJSONRef?: React.RefObject<HTMLInputElement>
  }[]
}

export const Header = ({ title, buttons, ...boxProps }: Props & BoxProps) => {
  const uploadButton = buttons?.find(btn => btn.type === 'upload')

  return (
    <Box width="100%" minHeight={60} px={2} py={1} flexDirection="row" display="flex" alignItems="center" {...boxProps}>
      <Box mr={2}>
        <Typography>{title}</Typography>
      </Box>
      <Link to="/skills">
        <Box mr={2}>
          <Typography>Skills</Typography>
        </Box>
      </Link>
      <Link to="/specialization">
        <Box mr={2}>
          <Typography>Specialization</Typography>
        </Box>
      </Link>
      <Link to="/code-tasks">
        <Box mr={2}>
          <Typography>IDE Tasks</Typography>
        </Box>
      </Link>
      <Link to="/achievements">
        <Box mr={2}>
          <Typography>Achievements</Typography>
        </Box>
      </Link>
      {buttons && (
        <Box flex={1} display="flex" justifyContent="flex-end">
          {buttons?.map(({ text, disabled, onPress }, index) => (
            <Box key={`${text}-${index}`} ml={1}>
              <CustomButton variant="contained" color="blue" disabled={disabled} onClick={onPress}>
                <Typography>{text}</Typography>
              </CustomButton>
            </Box>
          ))}
        </Box>
      )}

      {uploadButton && (
        <input
          style={{ display: 'none' }}
          type={'file'}
          multiple={false}
          accept={'.json'}
          onChange={uploadButton.handleOpenFile}
          ref={uploadButton.uploadJSONRef}
        />
      )}
    </Box>
  )
}
