import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useCallback, useState, useMemo } from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components'
import { AddMessageOrGroup } from '~/components/add-message-or-group'
import { Direction } from '~/domain/types'
import { MessageModal } from '~/modals/message-modal/message-modal'
import { Media } from '~/modals/message-modal/message-modal-types/previews/slider'
import { courseUsersMetaStore } from '~/store'
import { IMessage, LessonGoalType } from '~/types/course'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import { colors } from './colors'
import { MoveMessage, MoveGroup } from './components'
import { getAuthorName } from './helpers'

interface MessageProps {
  message: IMessage
  onDeleteMessage: () => void
  onUpdateMessage: (message: IMessage) => void
  onMoveMessage: (message: IMessage, direction: Direction) => void
  onMoveToSeparateGroup: (message: IMessage) => void
  onMoveMessageBetweenGroups: (message: IMessage, direction: Direction) => void
  onCreateMessageButtonClick: (messageId?: number | string) => void
  goals?: LessonGoalType[]
}

export const Message: FunctionComponent<MessageProps> = observer(
  ({
    message,
    onDeleteMessage,
    onUpdateMessage,
    onMoveMessage,
    onMoveToSeparateGroup,
    onMoveMessageBetweenGroups,
    onCreateMessageButtonClick,
    goals,
  }) => {
    const [messageModalIsOpen, setMessageModalIsOpen] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const handleCloseChapterModal = useCallback(() => setMessageModalIsOpen(false), [])
    const handleEditMessageButtonClick = useCallback(() => {
      setMessageModalIsOpen(true)
    }, [])

    const courseUsersMeta = toJS(courseUsersMetaStore.courseUsersMeta)

    // @ts-ignore
    const { content = null, slides = null } = message
    const { authorId = undefined } = message

    const authorName = useMemo(() => {
      const author = courseUsersMeta.find(author => author?.id === authorId) ?? null

      return getAuthorName(authorId, author)
    }, [authorId, courseUsersMeta])

    return (
      <>
        <Box
          mb={2}
          p={2}
          bgcolor={colors[message.type]}
          borderRadius="16px"
          style={{ maxWidth: ' 55vw', width: '100%', position: 'relative' }}
          onMouseEnter={() => setIsShow(true)}
          onMouseLeave={() => setIsShow(false)}
        >
          <AddMessageOrGroup isDisplay={isShow} onClick={() => onCreateMessageButtonClick(message.id)} />

          <Header>
            <Typography color="textSecondary">Mesage type: {message.type}</Typography>
            <Typography color="textSecondary">uuid of the message (id): {message.id}</Typography>

            <Group isShow={isShow}>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button size="small" onClick={handleEditMessageButtonClick}>
                  <EditIcon />
                </Button>
                <Button color="error" onClick={onDeleteMessage}>
                  <DeleteIcon />
                </Button>
              </ButtonGroup>
              <MoveGroup
                onMoveToSeparateGroup={() => onMoveToSeparateGroup(message)}
                onMoveGroupUp={() => onMoveMessageBetweenGroups(message, Direction.UP)}
                onMoveGroupDown={() => onMoveMessageBetweenGroups(message, Direction.DOWN)}
              />
              <MoveMessage
                onClickUp={() => onMoveMessage(message, Direction.UP)}
                onClickDown={() => onMoveMessage(message, Direction.DOWN)}
              />
            </Group>
          </Header>
          {slides && <Media slides={slides} />}
          {content && <ReactQuill value={content} readOnly theme="bubble" />}
          {authorId && <Typography>Автор: {authorId || authorName}</Typography>}
        </Box>
        {messageModalIsOpen && (
          <MessageModal
            isOpen={messageModalIsOpen}
            onClose={handleCloseChapterModal}
            onSave={onUpdateMessage}
            message={message}
            goals={goals}
          />
        )}
      </>
    )
  },
)

const Header = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: space-between;
`

const Group = styled(Box)<{ isShow: boolean }>`
  display: flex;
  justify-content: space-between;
  transition: opacity 0.1s;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  gap: 8px;
`
