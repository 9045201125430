import { makeAutoObservable } from 'mobx'
import { CSSProperties } from 'react'

interface IDialogSettings {
  title?: string
  style?: CSSProperties
  contentStyle?: CSSProperties
}

export class DialogStore {
  constructor() {
    makeAutoObservable(this, { body: false, style: false, contentStyle: false })
  }

  isOpen = false
  body: JSX.Element | null = null
  title: string | undefined = undefined
  style: CSSProperties | undefined = undefined
  contentStyle: CSSProperties | undefined = undefined

  showDialog = (body: JSX.Element, settings: IDialogSettings): void => {
    this.isOpen = true
    this.title = settings.title
    this.body = body
    this.style = settings.style
    this.contentStyle = settings.contentStyle
  }

  closeDialog = (): void => {
    this.isOpen = false
    this.body = null
  }
}
