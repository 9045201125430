import { PaymentType } from '~/api/firebase/payment'
import { Timestamp, FieldValue } from '~/infra/firebase/firestore'

export enum PaymentStatuses {
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
}

export interface IPaymentProps {
  id: string
  orderId?: string | null
  paymentId: string
  paymentUrl: string
  status: PaymentStatuses
  paymentProvider: string
  amount: number
  currency: string
  userId: string
  courseId: string
  createdAt?: Timestamp | FieldValue | number | null
  invoiceTime: number | string | null
  expirationTime?: number | string | null
  type: PaymentType
  paymentLinkId?: string | null
}

class Payment {
  id: string
  orderId?: string | null
  paymentId: string
  paymentUrl: string
  status: PaymentStatuses
  paymentProvider: string
  amount: number
  currency: string
  userId: string
  courseId: string
  createdAt?: Timestamp | FieldValue | number | null
  invoiceTime: number | string | null
  expirationTime?: number | string | null
  type: PaymentType
  paymentLinkId?: string | null

  constructor(paymentProps: IPaymentProps) {
    this.id = paymentProps.id
    this.orderId = paymentProps.orderId
    this.paymentId = paymentProps.paymentId
    this.paymentUrl = paymentProps.paymentUrl
    this.status = paymentProps.status
    this.paymentProvider = paymentProps.paymentProvider
    this.amount = paymentProps.amount
    this.currency = paymentProps.currency
    this.userId = paymentProps.userId
    this.courseId = paymentProps.courseId ?? null
    this.invoiceTime = paymentProps.invoiceTime ?? null
    this.expirationTime = paymentProps.expirationTime ?? null
    this.createdAt = paymentProps.createdAt ?? Date.now()
    this.type = paymentProps.type
    this.paymentLinkId = paymentProps.paymentLinkId ?? null
  }

  static toRaw(payment: IPaymentProps) {
    return new Payment(payment)
  }

  static fromRaw(rawPayment: any): IPaymentProps {
    return Object.assign({}, rawPayment)
  }
}

export { Payment }
