import React, { useEffect } from 'react'
import { SKILL_NEST_TYPE } from '~/domain/skills'
import { DefaultLayout } from '~/layouts/default-layout'
import { skillStore } from '~/store'

import { Container, Typography } from '@mui/material'

// import { AddNewSkillModal } from './add-new-skill'
import { SkillsListGroup } from './skills-list-group'

export const Skills = () => {
  useEffect(() => {
    skillStore.fetchAllSkillsByType(SKILL_NEST_TYPE.AREA)
  }, [])

  return (
    <DefaultLayout>
      <Container maxWidth="lg">
        <Typography>Skills</Typography>
        {/* Remove add skill modal bacause we will make new one from scratch
      <AddNewSkillModal /> */}
        <SkillsListGroup />
      </Container>
    </DefaultLayout>
  )
}
