/**
 * Splits an option string into an array
 * @param rawOptions option strings
 * @returns array options
 */

export const splitRawOptions = (rawOptions: string) => {
  const options = clearLastRawOptions(rawOptions.split('|'))

  if (options.length > 1) {
    return options
  }

  // TODO: left for back support
  return clearLastRawOptions(rawOptions.split(';'))
}

export const clearLastRawOptions = <T extends string>(options: T[]): T[] => {
  const lastElement = options[options.length - 1]

  const clearText = lastElement.replaceAll(/\s+/g, '')

  if (clearText.length === 0) {
    return options.slice(0, -1)
  }

  return options
}
