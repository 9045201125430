import { getUniqueId } from '~/helpers/id'
import { getServerTimestamp, FieldValue, Timestamp } from '~/infra/firebase/firestore'

export enum SKILL_NEST_TYPE {
  AREA = 'AREA', // 1 level skill
  GROUP = 'GROUP', // 2 level skill
  SKILL = 'SKILL', // 3 level skill
}

export type SkillTree = {
  skills: SkillType[]
  group: SkillType[]
  area: SkillType[]
}

export type SkillType = {
  id?: string
  name?: string
  parentIds?: string[]
  description?: string | null
  type?: SKILL_NEST_TYPE | null
  isVisible?: boolean
  imageUrl?: string | null
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp
}

export class Skill {
  id: string
  name?: string
  parentIds?: string[]
  description?: string | null
  type?: SKILL_NEST_TYPE | null
  isVisible?: boolean
  imageUrl?: string | null
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp

  constructor(skill: SkillType) {
    const updatedAt = getServerTimestamp()

    this.id = skill.id ?? getUniqueId()
    this.name = skill.name ?? ''
    this.description = skill.description ?? ''
    this.parentIds = skill.parentIds ?? []
    this.type = skill.type ?? null
    this.imageUrl = skill?.imageUrl ?? null
    this.isVisible = skill.isVisible ?? true
    this.createdAt = skill.createdAt ?? updatedAt
    this.updatedAt = updatedAt
  }

  static fromRaw(rawSkill: any) {
    return new Skill(rawSkill)
  }

  static toRaw(skill: SkillType) {
    return Object.assign({}, skill)
  }
}
