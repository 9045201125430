import Cookies from 'js-cookie'
import { flow, makeAutoObservable } from 'mobx'
import { UserApi } from '~/api/firebase/user'
import { JWT_KEY } from '~/constants/cookie'
import { User, IUserProps } from '~/domain/user'
import { getUserJwt } from '~/infra/firebase/auth'

class UserStore {
  users: User[] | null = []
  currentUser: User | null = null
  jwt: string | undefined | null = null
  isAuthenticationInitialized: boolean = false
  isFetching = false
  isFetched = false
  isCreating = false
  isUpdating = false

  constructor() {
    makeAutoObservable(this)
  }

  get isAuthenticated() {
    return this.currentUser !== null
  }

  initAuthentication() {
    if (!this.isAuthenticationInitialized) {
      this.isAuthenticationInitialized = true
    }
  }

  signOut() {
    this.currentUser = null
    this.jwt = null

    Cookies.remove(JWT_KEY)

    this.initAuthentication()
  }

  fetchUserById = flow(function* (this: UserStore, userId: string) {
    try {
      const user = yield UserApi.getUserById(userId)
      this.users = [...(this.users || []), user]
      return user
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    }
  })

  fetchUserByEmail = flow(function* (this: UserStore, email: string) {
    try {
      const user = yield UserApi.fetchUserByEmail(email)

      return user
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    }
  })

  signIn = flow(function* (this: UserStore, userProps: IUserProps) {
    const userId = userProps.id
    const isExistingUser = yield UserApi.checkIsExistingUser(userId)
    const jwt = yield getUserJwt()

    this.jwt = jwt

    Cookies.set(JWT_KEY, jwt)

    if (isExistingUser) {
      this.currentUser = yield UserApi.getUser(userId)

      return
    }

    const user = new User(userProps)

    this.currentUser = user
    yield UserApi.createOrUpdateUser(this.currentUser)
    this.initAuthentication()
  })
}

export { UserStore }
