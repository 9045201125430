// base api realization
import { cloudFunctionsApiUrl, isDevelopment } from '~/constants/platform'
import logger from '~/helpers/logger'

// const TIMEOUT = 20000 // 20 sec timeout
const baseUrl = cloudFunctionsApiUrl

type Props = {
  url: string
  method?: string
  headers?: Headers
  body?: any
  token?: string
}

type Params = Omit<Props, 'url'>

const fetchWithTimeout = (uri: string, params: Params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    // const t = setTimeout(() => {
    //   resolve({ status: 0, error: 'Request timed out' })
    // }, TIMEOUT)
    try {
      const res = await fetch(uri, params)
      resolve(res)
    } catch (error: any) {
      resolve({ status: 0, error: error?.message ?? 'Request filed' })
    } finally {
      // clearTimeout(t)
    }
  })
}

/**
 * Throws connection error.
 */
const throwConnectionError = (error: string) => {
  throw new Error(error)
}

const authHeaders = (token: string) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${token}`)
  headers.append('Accept', 'application/json')
  return headers
}

const BaseAPI = <T>(method: string, { url, token, body }: Props, mockResponse?: T): Promise<T> => {
  const baseHeaders = new Headers()
  baseHeaders.append('Content-Type', 'application/json')
  baseHeaders.append('Accept', 'application/json')
  const uri = `${baseUrl}/${url}`

  const params: Params = {
    method,
    headers: token ? authHeaders(token) : baseHeaders,
  }

  if (method !== 'GET') {
    params.body = JSON.stringify(body)
  }

  logger.info(`${method}: `, uri, params)

  if (mockResponse) {
    return new Promise(resolve => resolve(mockResponse))
  }

  return fetchWithTimeout(uri, params)
    .catch(throwConnectionError)
    .then(async (response: any) => {
      await validateResponse(response)

      return response
    })
    .then((response: Response & { error?: string }) => {
      if (response.status === 401) {
        return { code: response.status, message: response.statusText }
      }

      if (response.status === 0 && response?.error) {
        return throwConnectionError(response?.error)
      }

      return response.json()
    })
}

export const POST = <T>(url: string, body: Props['body'], token?: Props['token'], mockResponse?: T) => {
  return BaseAPI<T>('POST', { url, body, token }, mockResponse)
}

export const PUT = <T>(url: string, body: Props['body'], token?: Props['token'], mockResponse?: T) => {
  return BaseAPI('PUT', { url, body, token }, mockResponse)
}

export const PATCH = <T>(url: string, body: Props['body'], token?: Props['token'], mockResponse?: T) => {
  return BaseAPI('PATCH', { url, body, token }, mockResponse)
}

export const GET = <T>(url: string, token?: Props['token'], mockResponse?: T) => {
  return BaseAPI('GET', { url, token }, mockResponse)
}

export const DELETE = <T>(url: string, body: Props['body'], token?: Props['token'], mockResponse?: T) => {
  return BaseAPI('DELETE', { url, body, token }, mockResponse)
}

/**
 * Validates HTTP response and throws error if something goes wrong.
 */
const validateResponse = async (response: Response) => {
  // Redirect user to login screen if he is not authorized
  if (response.status === 401) {
    return Promise.resolve(true)
  }

  if (response.status !== 200) {
    logger.error(response)
  }

  // if (Config.__TEST__) return Promise.resolve(true)
  if (isDevelopment && typeof response.clone === 'function') {
    const clonedResponse = response.clone()
    const body = await clonedResponse.json()

    logger.info({
      response,
      body,
    })
  } else {
    logger.info(response)
  }

  return Promise.resolve(true)
}
