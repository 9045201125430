import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { MediaSlideOptionType } from '~/types/course'

import { Box, Typography as Text } from '@mui/material'

import { SliderButton } from './slider-button'
import { sliderSettings } from './slider-settings'

export type MediaProps = {
  slides: MediaSlideOptionType[]
}

export const Media = ({ slides }: MediaProps) => {
  const slider = useRef<Slider>(null)

  if (slides?.length === 0) return null

  if (slides?.length === 1)
    return (
      <>
        {slides?.map(({ elementId, source, description, contentType }) => (
          <SlideShadow key={elementId} style={{ width: '100%' }}>
            <Slide>
              {contentType === 'IMAGE' ? (
                <img src={source} alt={description} width="100%" height="auto" style={{ borderRadius: 10 }} />
              ) : (
                <iframe src={source} title={description} width="100%" height="auto" style={{ borderRadius: 10 }} />
              )}
              {description ? <SlideDescription>{description}</SlideDescription> : null}
            </Slide>
          </SlideShadow>
        ))}
      </>
    )

  return (
    <SliderWrapper>
      <Slider {...sliderSettings} ref={slider}>
        {slides?.map(({ elementId, source, description, contentType }) => (
          <SlideShadow key={elementId}>
            <Slide>
              {contentType === 'IMAGE' ? (
                <img src={source} alt={description} width={300} height={300} style={{ borderRadius: 10 }} />
              ) : (
                <iframe src={source} title={description} width={300} height={300} style={{ borderRadius: 10 }} />
              )}
              {description ? <SlideDescription>{description}</SlideDescription> : null}
            </Slide>
          </SlideShadow>
        ))}
      </Slider>
      {slides && slides?.length > 1 ? (
        <SliderControls>
          <SliderButton
            dir="prev"
            onClick={() => slider.current?.slickPrev()}
            style={{ width: 56, height: 56, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
          <SliderButton
            dir="next"
            onClick={() => slider.current?.slickNext()}
            style={{
              position: 'inherit',
              right: 40,
              width: 56,
              height: 56,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </SliderControls>
      ) : null}
    </SliderWrapper>
  )
}

const SliderWrapper = styled(Box)`
  display: block;
  width: 370px;
  margin: 10px auto;
  position: relative;
`

const Slide = styled(Box)`
  width: fit-content;
  outline: none;
  & img {
    cursor: pointer;
  }
`

const SlideShadow = styled(Box)`
  width: 300px;
  margin: 20px auto;
  position: relative;
  flex-direction: column;
  &::after {
    content: '';
    position: absolute;
    width: 90%;
    height: 100px;
    background: silver;
    bottom: 60px;
    left: 18px;
    align-self: center;
    z-index: -10;
    border-radius: 10px;
  }
`

const SlideDescription = styled(Text)`
  display: block;
  padding: 15px 80px 0 40px;
`

const SliderControls = styled(Box)`
  position: absolute;
  width: 100%;
  bottom: -20px;
  justify-content: space-between;
`
