import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'

type LoadingSpinnerProps = {
  className?: string
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className }) => {
  return (
    <LoaderContainer className={className}>
      <Loader type="Oval" color={'blue'} height={32} width={32} />
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div`
  margin: 0 16px;
`

export { LoadingSpinner }
