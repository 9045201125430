import { CourseDropletsStore } from './course-droplets'
import { CourseUsersMetaStore } from './course-meta-user'
import { PaymentLinksStore } from './payment-links'
import { PaymentsStore } from './payments'
import { SkillStore } from './skills'
import { TaskStore } from './task'
import { UserStore } from './user'
import { UserDropletsStore } from './user-droplets'

export const userStore = new UserStore()
export const courseUsersMetaStore = new CourseUsersMetaStore()
export const courseDropletsStore = new CourseDropletsStore()
export const userDropletsStore = new UserDropletsStore()
export const skillStore = new SkillStore()
export const taskStore = new TaskStore()
export const paymentLinksStore = new PaymentLinksStore()
export const paymentsStore = new PaymentsStore()
