import { Task } from '~/domain/task'
import { UserRole } from '~/domain/user'
import { dbProduction } from '~/infra/firebase/firebase'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'
import { userStore } from '~/store'

import { Firestore } from '@firebase/firestore'

const TASK_COLLECTION_KEY = 'tasks'

const getTasksCollection = (taskId?: string, db?: Firestore) =>
  getCollectionRef(TASK_COLLECTION_KEY, {
    id: taskId,
    customDb: db,
    converter: {
      fromFirestore: (taskSnapshot: any) => Task.fromRaw(taskSnapshot.data()),
      toFirestore: (task: Task) => Task.toRaw(task),
    },
  })

const getTask = async (taskId: string) => {
  const taskSnapshot = await getTasksCollection(taskId).get()

  return taskSnapshot.exists() ? taskSnapshot.data() : null
}

const getAllTasks = async () => {
  const taskDocs = await getQuerySnapshot(TASK_COLLECTION_KEY).get()

  // @ts-ignore
  return taskDocs.docs.map(doc => Task.fromRaw({ ...doc.data(), id: doc.id }))
}

const getAllAvailableTasks = async () => {
  const tasks = await getAllTasks()

  const { currentUser } = userStore

  if (currentUser?.roles?.includes(UserRole.admin)) {
    return tasks
  }

  return tasks.filter(task => task.creatorId === currentUser?.id)
}

const checkIsExistingTask = async (taskId: string) => {
  const taskSnapshot = await getTasksCollection(taskId).get()

  return taskSnapshot.exists()
}

const createOrUpdateTaskHandler = async (task: Task, db?: Firestore) => {
  const taskDocument = getTasksCollection(task.id, db)
  await taskDocument.set(task)

  return task
}

const createOrUpdateTask = async (task: Task) => await createOrUpdateTaskHandler(task)

const createOrUpdateTaskInProductionDB = async (task: Task) => await createOrUpdateTaskHandler(task, dbProduction)

const getTaskById = async (taskId: string) => {
  const taskSnapshot = await getTasksCollection(taskId).get()

  return taskSnapshot.data()
}

export const TaskApi = {
  getTask,
  getAllTasks,
  getAllAvailableTasks,
  checkIsExistingTask,
  createOrUpdateTask,
  getTaskById,
  /**
   * production
   */
  createOrUpdateTaskInProductionDB,
}
