import { makeAutoObservable, flow, toJS } from 'mobx'
import { PaymentLinksApi } from '~/api/firebase/payment-links'
import { PaymentLinksType, PaymentLinks } from '~/domain/payment-links'

class PaymentLinksStore {
  paymentLinks: PaymentLinks[] = []
  isFetching = false
  isFetched = false
  isCreating = false
  isUpdating = false

  constructor() {
    makeAutoObservable(this)
  }

  fetchLinks = flow(function* (this: PaymentLinksStore, courseId: string) {
    this.isFetching = true
    try {
      this.paymentLinks = yield PaymentLinksApi.fetchLinks(courseId)
      this.isFetched = true
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isFetching = false
    }
  })

  createLink = flow(function* (this: PaymentLinksStore, rawData: PaymentLinksType) {
    this.isCreating = true
    try {
      const data = PaymentLinks.toRaw(rawData)
      yield PaymentLinksApi.createOrUpdate(data)
      this.paymentLinks = [...toJS(this.paymentLinks), { ...rawData, id: data.id }]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isCreating = false
    }
  })

  updateLink = flow(function* (this: PaymentLinksStore, rawData: PaymentLinksType) {
    this.isUpdating = true
    try {
      const updated = PaymentLinks.toRaw(rawData)
      yield PaymentLinksApi.createOrUpdate(updated)

      const links = toJS(this.paymentLinks).map(item => {
        if (item.id === updated.id) {
          return updated
        }

        return item
      })

      this.paymentLinks = links
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdating = false
    }
  })
}

export { PaymentLinksStore }
