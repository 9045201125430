import React, { ChangeEvent, useCallback, useEffect } from 'react'
import { DefaultInputFieldProps } from '~/hooks/use-input'
import { IMessage } from '~/types/course'

import { Box, TextField, Typography } from '@mui/material'

import { CommentFields, CommentFieldsProps } from './fields/comment-fields'

type TextInputProps = {
  message?: IMessage
  textInputCorrectAnswers: string
  setTextInputCorrectAnswers: (str: string) => void
  title: DefaultInputFieldProps
  hint: DefaultInputFieldProps
} & CommentFieldsProps

const TextInput = ({
  message,
  textInputCorrectAnswers,
  setTextInputCorrectAnswers,
  hint,
  title,
  wrongAnswer,
  successAnswer,
}: TextInputProps) => {
  useEffect(() => {
    if (!message) return
    if (message.type === 'CARD_INPUT') {
      setTextInputCorrectAnswers(message.answers.toString())
      title?.setValue?.(message.title)
      hint?.setValue?.(message.hint)
      wrongAnswer?.setValue?.(message.wrongAnswer)
      successAnswer?.setValue?.(message.successAnswer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, setTextInputCorrectAnswers])

  const handleTextField = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setTextInputCorrectAnswers(e.target.value)
    },
    [setTextInputCorrectAnswers],
  )

  return (
    <Box>
      <Box py={2}>
        <TextField
          fullWidth
          label="Title"
          value={title.value}
          onChange={e => title?.handleSetValue?.(e.target.value)}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
        <Typography style={{ fontSize: 14 }}>
          If this question requires a specific answer, list the possible answers separated by commas.
        </Typography>
        <TextField
          fullWidth
          label="Answers, separated by commas"
          value={textInputCorrectAnswers}
          onChange={e => handleTextField(e)}
          variant="outlined"
          multiline
          style={{ marginTop: 10 }}
        />
        <Typography style={{ fontSize: 14 }}>Text of a hint</Typography>
        <TextField
          fullWidth
          label="Hint"
          value={hint.value}
          onChange={e => hint?.handleSetValue?.(e.target.value)}
          variant="outlined"
          multiline
          style={{ marginTop: 10 }}
        />

        <CommentFields wrongAnswer={wrongAnswer} successAnswer={successAnswer} />
      </Box>
    </Box>
  )
}

export default TextInput
