import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { UserRole } from '~/domain/user'
//import { firebaseLogout } from '~/infra/firebase/auth'
import { userStore } from '~/store'

import { Box, Typography } from '@mui/material'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>
  roles?: UserRole[]
}

const PrivateRoute = ({ component: Component, roles, ...restRouteProps }: PrivateRouteProps) => {
  const currentUser = userStore.currentUser
  const isSucceed = currentUser?.roles
    ? currentUser?.roles.reduce((hasRole, role) => {
        return Number(hasRole) + Number(roles?.includes(role))
      }, 0)
    : 1

  if (!isSucceed) {
    //firebaseLogout()

    setTimeout(() => {
      ;<Redirect
        to={{
          pathname: '/',
          //state: { from: location },
        }}
      />
    }, 5000)
  }

  return (
    <Route
      {...restRouteProps}
      render={({ location }) =>
        userStore.isAuthenticated ? (
          <>
            {isSucceed ? (
              <Component />
            ) : (
              <Box px={4} py={4}>
                <Typography align="center">
                  The page|site is unavailable due to unsufficent rights on your account
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          </>
        )
      }
    />
  )
}

export { PrivateRoute }
