export enum Visible {
  YES = 'YES',
  NO = 'NO',
}

export enum Difficulty {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD',
  LEGENDARY = 'LEGENDARY',
}

export const difficulty = Object.values(Difficulty).map(value => ({
  label: value,
  value,
}))

export enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
}
