import { UserDropletType } from '~/domain/user-droplet'
import { getToken } from '~/utils/get-token'

import { POST } from './rest'

const deleteDroplet = async (dropletId: string): Promise<UserDropletType> => {
  const token = getToken()
  return await POST('droplets-deleteDroplet', { dropletId }, token)
}

export const DropletsApi = {
  deleteDroplet,
}
