import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useHistory } from 'react-router-dom'
import { AnalyticsApi } from '~/api/analytics'
import { UserApi } from '~/api/firebase/user'
// Импортируйте Link
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { Course as CourseType, TaskType } from '~/types/course'
import CodePreviewModal from '~/widgets/code-preview-modal'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Paper, Grid, Container, styled, Typography, CircularProgress } from '@mui/material'

const getCourseTasks = (course?: CourseType) => {
  if (!course) {
    return []
  }

  const courseLessons = course?.chapters?.map(chapter => chapter.lessons).flat()
  const allMessagesGroups = courseLessons?.map(lesson => lesson?.messages).flat()

  const allMessages = allMessagesGroups?.map(messageGroup => messageGroup?.messages).flat()

  const taskMessages = allMessages?.filter(message => message?.type === 'TASK') as TaskType[]

  return taskMessages.map(task => task.taskId)
}

export const UsersSubmissions: React.FC = () => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [userSubmissions, setUserSubmissions] = useState<any[]>([])

  const history = useHistory()
  const location = useLocation()

  const { id } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)
  const allTask = useMemo(() => getCourseTasks(course) ?? [], [course])

  const { data: users, isLoading } = useQuery(
    'analytics-users',
    async () => {
      const tasks = await AnalyticsApi.getUserTaskProgressByTaskIds(allTask)
      // @ts-ignore
      setUserSubmissions(tasks)
      // @ts-ignore
      const userIds = tasks.map(task => task.userId)
      const users = await UserApi.getUsersByIds(userIds)

      return users.filter(Boolean)
    },
    {
      enabled: Boolean(allTask?.length > 0),
      retry: false,
      refetchOnWindowFocus: false,
    },
  )

  const userSubmission = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const userId = searchParams.get('userId')

    if (userId) {
      // @ts-ignore
      return userSubmissions.filter(submission => submission?.userId === userId)
    }

    return []
  }, [location.search, userSubmissions])

  const userStructures = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const taskId = searchParams.get('taskId')

    if (taskId) {
      // @ts-ignore
      return userSubmission.filter(submission => submission?.taskId === taskId)
    }

    return []
  }, [location.search, userSubmission])

  const handleUserClick = (userId: string) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('userId', userId) // Добавляем userId в параметры поиска
    history.push({ search: searchParams.toString() }) // Обновляем URL
  }

  const handleOpenPreviewModal = (taskId: string) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('taskId', taskId)
    history.push({ search: searchParams.toString() })
    setOpenPreviewModal(true)
  }

  const handleCloseModal = () => {
    setOpenPreviewModal(prev => !prev)
  }

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  return (
    <Box>
      <AnalyticsHeader variant="h5">Users submissions</AnalyticsHeader>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <Paper sx={{ p: 2 }}>
              {isLoading && <CircularProgress />}
              {users?.map(user => (
                <UserItem key={user?.id} onClick={() => handleUserClick(user?.id)}>
                  {user?.displayName ?? 'Anonymous'}
                </UserItem>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Paper sx={{ p: 2 }}>
              {userSubmission?.length === 0 && 'Please select a user'}
              {userSubmission?.map(submission => (
                <SubmissionItem key={submission?.id} onClick={() => handleOpenPreviewModal(submission.taskId)}>
                  <p>Task ID: {submission?.taskId}</p>
                  <SubmissionSubTitle>
                    Completed: {submission.isValidFinished ? <CheckIcon /> : <CloseIcon />}
                  </SubmissionSubTitle>
                </SubmissionItem>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <CodePreviewModal open={openPreviewModal} handleClose={handleCloseModal} code={userStructures} />
    </Box>
  )
}

const AnalyticsHeader = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
`

const UserItem = styled(Box)`
  padding: 8px 8px;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: #fafafa;
  }
`

const SubmissionItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 8px;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: #fafafa;
  }
`

const SubmissionSubTitle = styled(Box)`
  display: flex;
  gap: 4px;
  align-items: center;
`
