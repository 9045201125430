import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ButtonsGroup } from '~/components/buttons-group'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'
import { LessonType } from '~/types/course'

import { Box, Checkbox, Dialog, FormControlLabel, TextField } from '@mui/material'

interface LessonModalProps {
  onClose: () => void
  isOpen: boolean
  lesson?: LessonType
  onSave: (lesson: LessonType) => void
}

export const LessonModal: FunctionComponent<LessonModalProps> = React.memo(props => {
  const { onClose, isOpen, lesson, onSave } = props
  const id = lesson?.id ?? getUniqueId()
  const isEdit = Boolean(lesson)

  const [isOpenedAccess, setOpenedAccess] = useState(Boolean(lesson?.isOpenedAccess))

  const {
    value: name,
    handleSetValue: handleSetName,
    isValid: nameIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: lesson?.name })

  const {
    value: imageUrl,
    handleSetValue: handleSetImageUrl,
    isValid: imageUrlIsValid,
  } = useInput({ defaultValue: lesson?.imageUrl })

  const { value: duration, handleSetValue: handleSetDuration } = useInput({ defaultValue: lesson?.duration })

  const isSaveEnabled = useMemo(() => nameIsValid, [nameIsValid])

  const handleClose = useCallback(() => {
    handleSetName('')
    handleSetImageUrl('')
    setOpenedAccess(false)

    onClose()
  }, [handleSetName, handleSetImageUrl, onClose])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newLesson: LessonType = {
        ...lesson,
        id,
        name,
        imageUrl,
        isOpenedAccess,
        type: 'CONTENT',
        duration,
      }

      onSave(newLesson)

      return handleClose()
    },
    [isSaveEnabled, id, name, imageUrl, isOpenedAccess, duration, lesson, onSave, handleClose],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: handleClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Lesson',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [handleClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <CourseImage {...(imageUrl ? { as: 'img', src: imageUrl } : {})} />
          <Box py={2}>
            <TextField
              fullWidth
              label="Lesson image"
              value={imageUrl}
              onChange={handleSetImageUrl}
              error={!imageUrlIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Lesson title"
              required
              value={name}
              onChange={handleSetName}
              error={!nameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              label="Duration, minutes"
              value={duration}
              onChange={handleSetDuration}
              variant="outlined"
            />
          </Box>
          <Box pb={2}>
            <FormControlLabel
              control={<Checkbox checked={isOpenedAccess} onChange={() => setOpenedAccess(!isOpenedAccess)} />}
              label="Free"
            />
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})

const CourseImage = styled(Box)`
  border-radius: 24px;
  overflow: hidden;
  max-height: 375px;
  width: 100%;
`
