import { User } from '~/domain/user'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'

import { where } from '@firebase/firestore'

const USER_COLLECTION_KEY = 'users'

const getUsersCollection = (userId?: string) =>
  getCollectionRef(USER_COLLECTION_KEY, {
    id: userId,
    converter: {
      fromFirestore: (userSnapshot: any) => User.fromRaw(userSnapshot.data()),
      toFirestore: (user: User) => User.toRaw(user),
    },
  })

const getEntireUsersCollection = () => getQuerySnapshot(USER_COLLECTION_KEY)

const getUser = async (userId: string) => {
  const userSnapshot = await getUsersCollection(userId).get()

  return userSnapshot.exists() ? userSnapshot.data() : null
}

const checkIsExistingUser = async (userId: string) => {
  const userSnapshot = await getUsersCollection(userId).get()

  return userSnapshot.exists()
}

const createOrUpdateUser = async (user: User) => {
  const userDocument = getUsersCollection(user.id)
  await userDocument.set(user)

  return user
}

const getUserById = async (userId: string) => {
  const userSnapshot = await getUsersCollection(userId).get()

  return userSnapshot.data()
}

const getUsersByIds = async (userIds: string[]) => {
  const uniqueUserIds = Array.from(new Set(userIds))
  const userSnapshots = await Promise.all(uniqueUserIds.map(userId => getUsersCollection(userId).get()))
  return userSnapshots.map(snapshot => snapshot.data())
}

const fetchUserByEmail = async (email: string) => {
  const usersDoc = await getQuerySnapshot(
    USER_COLLECTION_KEY,
    where('email', '==', email),
    where('email', '>=', email),
    where('email', '<=', email),
  ).get()

  return usersDoc.docs.map(doc => User.fromRaw({ ...doc.data() }))
}

export const UserApi = {
  getUser,
  checkIsExistingUser,
  createOrUpdateUser,
  getUserById,
  getUsersByIds,
  fetchUserByEmail,
  getUsersCollection,
  getEntireUsersCollection,
}
