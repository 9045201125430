import { createContext, useContext } from 'react'
import { DialogStore } from '~/store/common/dialog-store'

export interface CommonStores {
  dialogStore: DialogStore
}

const dialogStore = new DialogStore()

const commonStores: CommonStores = {
  dialogStore,
}

export const CommonStoresCtx = createContext(commonStores)
CommonStoresCtx.displayName = 'CommonStoresContext'

/**
 * Хук для получения сторов из react-контекста
 */
export const useCommonStores = (): CommonStores => useContext(CommonStoresCtx)

/**
 * Функция получения сторов из module-state (для использования за пределами ui, например в других сторах)
 */
export const getCommonStores = (): CommonStores => commonStores
