import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { CourseUsersMetaType, UserMeta } from '~/domain/usersMeta'
import { UserMetaModal } from '~/modals/user-meta-modal'
import { courseUsersMetaStore } from '~/store'

import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type Column = {
  id: 'id' | 'firstName' | 'lastName' | 'role' | 'description' | 'avatar'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 170,
  },
  { id: 'firstName', label: 'firstName', minWidth: 100 },
  {
    id: 'lastName',
    label: 'lastName',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'role',
    label: 'role',
    minWidth: 170,
  },
  {
    id: 'description',
    label: 'description',
    minWidth: 170,
  },
  {
    id: 'avatar',
    label: 'avatar',
    minWidth: 170,
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '100vh',
    },
  }),
)

export const UsersMetaTable = observer(() => {
  const classes = useStyles()
  const [userMetaModalIsVisible, setUserMetaModalIsVisible] = useState(false)
  const [userMeta, setUserMeta] = useState<CourseUsersMetaType>()

  const courseUsersMeta = toJS(courseUsersMetaStore.courseUsersMeta)

  const handleUpdateUserMeta = useCallback((updatedUserMeta: CourseUsersMetaType) => {
    const userMeta = new UserMeta(updatedUserMeta)

    courseUsersMetaStore.updateUserMeta(userMeta)
  }, [])

  const handleCloseUserMetaModal = () => {
    setUserMetaModalIsVisible(false)
  }

  const handleEditMetaUserButtonClick = useCallback((userMeta: CourseUsersMetaType) => {
    setUserMetaModalIsVisible(true)
    setUserMeta(userMeta)
  }, [])

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={'edit'} style={{ minWidth: 50 }}>
                  Edit
                </TableCell>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {courseUsersMeta?.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key={row.id} style={{ minWidth: 100 }}>
                      <IconButton edge="end" onClick={() => handleEditMetaUserButtonClick(row)} size="large">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {userMetaModalIsVisible && (
        <UserMetaModal
          onSave={handleUpdateUserMeta}
          onClose={handleCloseUserMetaModal}
          isOpen={userMetaModalIsVisible}
          userMeta={userMeta}
        />
      )}
    </>
  )
})
