import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-quill/dist/quill.snow.css'
import { AuthorsProvider } from '~/contexts/authors'
import { CoursesProvider } from '~/contexts/courses'
import { initFirebaseApp } from '~/infra/firebase'
import { Auth } from '~/infra/firebase/auth'
import { userStore } from '~/store'

import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles'

import './index.css'
import { initProductionFirebaseApp } from './infra/firebase/firebase'
import reportWebVitals from './reportWebVitals'
import { Routes } from './routes'
import { GlobalStyle } from './theming/global'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

initFirebaseApp()
initProductionFirebaseApp()
const theme = createTheme()

Auth.onUserSignIn(authProviderUser => {
  const userProps = {
    id: authProviderUser.uid,
    email: authProviderUser.email,
    displayName: authProviderUser.displayName,
    photoURL: authProviderUser.photoURL,
  }

  userStore.signIn(userProps)
})

Auth.onUserSignOut(() => {
  userStore.signOut()
})

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthorsProvider>
            <CoursesProvider>
              <Routes />
            </CoursesProvider>
          </AuthorsProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
