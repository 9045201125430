import React, { useCallback, useMemo } from 'react'
import { ButtonsGroup } from '~/components/buttons-group'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'

import { Box, Dialog, TextField } from '@mui/material'

export interface Author {
  id: string
  firstName: string
  type: string
  name?: string
  lastName?: string
  description?: string
}

export interface AuthorModalProps {
  onSave: (payload: Author) => void
  onClose: () => void
  isOpen: boolean
}

export const AuthorModal = ({ onSave = () => {}, onClose = () => {}, isOpen = false }: AuthorModalProps) => {
  const {
    value: firstName,
    handleSetValue: handleSetFirstName,
    isValid: firstNameIsValid,
  } = useInput({ validators: containValueValidator })
  const {
    value: lastName,
    handleSetValue: handleSetLastName,
    isValid: lastNameIsValid,
  } = useInput({ validators: containValueValidator })
  const { value: description, handleSetValue: handleSetDescription } = useInput()

  const isCreateEnabled = useMemo(() => firstNameIsValid && lastNameIsValid, [firstNameIsValid, lastNameIsValid])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()

      if (!isCreateEnabled) return

      onSave({
        type: 'AUTHOR',
        id: getUniqueId(),
        firstName,
        lastName,
        description,
      })

      return onClose()
    },
    [firstName, lastName, description, isCreateEnabled, onSave, onClose],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: 'Add Author',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isCreateEnabled,
        onClick: handleSubmit,
      },
    ],
    [isCreateEnabled, onClose, handleSubmit],
  )

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box py={2}>
            <TextField
              fullWidth
              label="Имя"
              required
              value={firstName}
              onChange={handleSetFirstName}
              error={!firstNameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Фамилия"
              required
              value={lastName}
              onChange={handleSetLastName}
              error={!lastNameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              minRows={4}
              fullWidth
              maxRows={8}
              value={description}
              onChange={handleSetDescription}
              label="Short info about the author"
              multiline
              variant="outlined"
            />
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
}
