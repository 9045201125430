import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useCallback, useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import SkillPlaceholder from '~/assets/common/default-skill-placeholder.png'
import { ButtonsGroup } from '~/components/buttons-group'
import { SkillType, SKILL_NEST_TYPE } from '~/domain/skills'
import { Visible } from '~/domain/types'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'
import { skillStore } from '~/store'

import {
  Box,
  Dialog,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import { Multiselect } from '../components/multiselect'

interface SkillModalProps {
  onClose: () => void
  isOpen: boolean
  skill?: SkillType | null
  onSave: (skill: SkillType) => void
  skillType: SKILL_NEST_TYPE
}

export const SkillModal: FunctionComponent<SkillModalProps> = observer(props => {
  const { onClose, isOpen, skill, onSave, skillType } = props
  const isEdit = Boolean(skill)

  const skillTypesWithFetchedSkills = toJS(skillStore.skillTypesWithFetchedSkills)
  const knowlegeAreas = toJS(skillStore.knowlegeAreas)
  const skillGroups = toJS(skillStore.skillGroups)
  const skillsForOptions = skillType === SKILL_NEST_TYPE.SKILL ? skillGroups : knowlegeAreas
  const [isVisible, setVisible] = useState(true)

  const {
    value: name,
    handleSetValue: handleSetName,
    isValid: nameIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: skill?.name })

  const {
    value: imageUrl,
    handleSetValue: handleSetImageUrl,
    isValid: imageUrlIsValid,
  } = useInput({ defaultValue: skill?.imageUrl })

  const [selectedSkills, setSelectedSkills] = useState<SkillType[]>()

  const { value: description, handleSetValue: handleSetDescription } = useInput({
    defaultValue: skill?.description ?? undefined,
  })

  useEffect(() => {
    handleSetName(skill?.name ?? '')
    handleSetImageUrl(skill?.imageUrl ?? '')
    handleSetDescription(skill?.description ?? '')
    setSelectedSkills(getSelectedSkills(skillsForOptions, skill))
    setVisible(skill?.isVisible ?? true)
  }, [handleSetDescription, handleSetName, handleSetImageUrl, skill, skillsForOptions])

  const handleSkillsSelectOpen = useCallback(() => {
    if (skillType === SKILL_NEST_TYPE.AREA) return

    const skillTypeToFetch = skillType === SKILL_NEST_TYPE.SKILL ? SKILL_NEST_TYPE.GROUP : SKILL_NEST_TYPE.AREA
    if (skillTypesWithFetchedSkills.includes(skillTypeToFetch)) return
    skillStore.fetchAllSkillsByType(skillTypeToFetch)
  }, [skillType, skillTypesWithFetchedSkills])

  const isSaveEnabled = useMemo(() => {
    if (!nameIsValid) return false

    if (skillType === SKILL_NEST_TYPE.AREA) return true

    return selectedSkills !== undefined && selectedSkills?.length > 0
  }, [nameIsValid, selectedSkills, skillType])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const selectedSkillsIds = selectedSkills ? selectedSkills?.map(skill => skill.id ?? '') : []

      const newSkill: SkillType = {
        ...skill,
        imageUrl,
        name,
        parentIds: selectedSkillsIds,
        description,
        type: skillType,
        isVisible,
      }

      onSave(newSkill)

      return onClose()
    },
    [isSaveEnabled, selectedSkills, skill, imageUrl, name, description, skillType, isVisible, onSave, onClose],
  )

  const handleVisibleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    const visible = value === Visible.YES

    setVisible(visible)
  }

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Skill',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [onClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box py={2}>
            <SkillIcon as="img" src={imageUrl || SkillPlaceholder} />
          </Box>
          {skill?.id && (
            <Box py={2}>
              UUID:
              <Typography>{skill?.id}</Typography>
            </Box>
          )}
          <Box py={2}>
            <TextField
              fullWidth
              label="Skill image"
              required
              value={imageUrl}
              onChange={handleSetImageUrl}
              error={!imageUrlIsValid}
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Skill name"
              required
              value={name}
              onChange={handleSetName}
              error={!nameIsValid}
            />
          </Box>
          {(skillType === SKILL_NEST_TYPE.GROUP || skillType === SKILL_NEST_TYPE.SKILL) && (
            <Box py={1}>
              <Multiselect
                setValue={setSelectedSkills}
                options={skillsForOptions}
                label="parent skills"
                value={selectedSkills}
                required
                onOpen={handleSkillsSelectOpen}
              />
            </Box>
          )}
          <Box py={1}>
            <TextField fullWidth label="description" value={description} onChange={handleSetDescription} />
          </Box>
          <Box py={2}>
            <FormLabel id="visible-label">Is visible?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="visible-label"
              value={isVisible ? Visible.YES : Visible.NO}
              onChange={handleVisibleChange}
            >
              <FormControlLabel value={Visible.YES} control={<Radio />} label="Yes" />
              <FormControlLabel value={Visible.NO} control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})

/**
 * При редактирования скилла, мы возращаем те скиллы, которые были ранее выбраны
 * @param skillOptions all skills of options
 * @param userSelectedParentSkills user selected parent id
 * @returns user selected skills
 */
const getSelectedSkills = (skillOptions: SkillType[], userSelectedParentSkills: SkillType | null | undefined) =>
  skillOptions.filter(options => userSelectedParentSkills?.parentIds?.includes(options?.id ?? ''))

const SkillIcon = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 16px;
`
