import { Course, MESSAGES_TYPES } from '~/types/course'

export const getTaskIds = (course: Course) => {
  let tasksIds: Set<string> = new Set()

  course?.chapters?.forEach(chapter => {
    chapter?.lessons?.forEach(lesson => {
      lesson?.messages?.forEach(group => {
        group?.messages?.forEach(message => {
          if (message?.type === MESSAGES_TYPES.TASK) {
            tasksIds.add(message?.taskId)
          }
        })
      })
    })
  })

  return Array.from(tasksIds)
}
