/* eslint-disable no-template-curly-in-string */
export const userDataConstant =
  '#!/bin/bash\n' +
  'set -euo pipefail\n' +
  'USERNAME=test\n' +
  'COPY_AUTHORIZED_KEYS_FROM_ROOT=true\n' +
  'OTHER_PUBLIC_KEYS_TO_ADD=()\n' +
  'useradd --create-home --shell "/bin/bash" --groups sudo "${USERNAME}"\n' +
  'usermod -aG sudo "${USERNAME}"\n' +
  'encrypted_root_pw="12345678"\n' +
  'if [ "${encrypted_root_pw}" != "*" ]; then\n' +
  'echo "${USERNAME}:${encrypted_root_pw}" | chpasswd\n' +
  'passwd --lock root\n' +
  'else\n' +
  'passwd --delete "${USERNAME}"\n' +
  'fi\n' +
  'chage --lastday 0 "${USERNAME}"\n' +
  'home_directory="$(eval echo ~${USERNAME})"\n' +
  'mkdir --parents "${home_directory}/.ssh"\n' +
  'if [ "${COPY_AUTHORIZED_KEYS_FROM_ROOT}" = true ]; then\n' +
  'cp /root/.ssh/authorized_keys "${home_directory}/.ssh"\n' +
  'fi\n' +
  'for pub_key in "${OTHER_PUBLIC_KEYS_TO_ADD[@]}"; do\n' +
  'echo "${pub_key}" >> "${home_directory}/.ssh/authorized_keys"\n' +
  'done\n' +
  'chmod 0700 "${home_directory}/.ssh"\n' +
  'chmod 0600 "${home_directory}/.ssh/authorized_keys"\n' +
  'chown --recursive "${USERNAME}":"${USERNAME}" "${home_directory}/.ssh"\n'
