import { makeAutoObservable, flow, toJS } from 'mobx'
import { CourseDropletsApi } from '~/api/firebase/droplets'
import { CourseDropletType, CourseDroplet } from '~/domain/course-droplet'

class CourseDropletsStore {
  courseDroplets: CourseDropletType[] = []
  isFetchingDroplets = false
  isDropletsFetched = false
  isCreatingDroplet = false
  isUpdatingDroplet = false

  constructor() {
    makeAutoObservable(this)
  }

  get dropletsForOptions() {
    if (!this.isDropletsFetched) {
      return []
    }

    return this.courseDroplets.map(droplet => ({
      text: `${droplet.name}-${droplet.image}-${droplet.region}-${droplet.size}`,
      value: droplet.id,
    }))
  }

  fetchDroplets = flow(function* (this: CourseDropletsStore, courseId: string) {
    this.isFetchingDroplets = true
    try {
      this.courseDroplets = yield CourseDropletsApi.getDroplets(courseId)
      this.isDropletsFetched = true
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isFetchingDroplets = false
    }
  })

  createDroplet = flow(function* (this: CourseDropletsStore, dropletRawData: CourseDropletType) {
    this.isCreatingDroplet = true
    try {
      const droplet = CourseDroplet.toRaw(dropletRawData)
      yield CourseDropletsApi.createOrUpdateDroplet(droplet)
      this.courseDroplets = [...toJS(this.courseDroplets), { ...dropletRawData }]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isCreatingDroplet = false
    }
  })

  updateDroplet = flow(function* (this: CourseDropletsStore, updatedDropletRawData: CourseDropletType) {
    this.isUpdatingDroplet = true
    try {
      const updatedDroplet = CourseDroplet.toRaw(updatedDropletRawData)
      yield CourseDropletsApi.createOrUpdateDroplet(updatedDroplet)

      const dropletsWithoutUpdatedDroplet = toJS(this.courseDroplets).filter(
        droplet => droplet.id !== updatedDropletRawData.id,
      )
      this.courseDroplets = [...dropletsWithoutUpdatedDroplet, updatedDropletRawData]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdatingDroplet = false
    }
  })
}

export { CourseDropletsStore }
