import { getUniqueId } from '~/helpers/id'
import { getServerTimestamp, FieldValue, Timestamp } from '~/infra/firebase/firestore'

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
  BTC = 'BTC',
  RUB = 'RUB',
}

export type CurrencyType = keyof typeof Currencies

export type SpecializationType = {
  id?: string
  name: string
  salary: {
    amount: string
    currency: CurrencyType
  }
  isVisible: boolean
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp
}

export class Specialization {
  id: string
  name: string
  salary: {
    amount: string
    currency: CurrencyType
  }
  isVisible: boolean
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp

  constructor(specialization: SpecializationType) {
    const updatedAt = getServerTimestamp()

    this.id = specialization.id ?? getUniqueId()
    this.name = specialization.name ?? ''
    this.salary = specialization.salary ?? { amount: '0', currency: Currencies.RUB }
    this.isVisible = specialization.isVisible ?? true
    this.createdAt = specialization.createdAt ?? updatedAt
    this.updatedAt = updatedAt
  }

  static toRaw(specialization: Specialization) {
    return Object.assign({}, specialization)
  }

  static fromRaw(rawSpecialization: any) {
    return new Specialization(rawSpecialization)
  }
}
