import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DefaultLayout } from '~/layouts/default-layout'
import { courseDropletsStore } from '~/store'

import { Container, Typography } from '@mui/material'

import { AddNewDroplet } from './add-new-droplet'
import { CourseDropletsTable } from './course-droplets-table'

export const DropletsPage = () => {
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  useEffect(() => {
    courseDropletsStore.fetchDroplets(id ?? '')
  }, [id])

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`Droplet configs for the course "${course?.name}"`} />}
    >
      <Container maxWidth="lg">
        <Typography>
          A list of possible droplets for tasks in the course. One droplet can be used in different tasks, which means
          it is used as a continuation of the work in the droplet.
        </Typography>
        <AddNewDroplet course={course} />
        <CourseDropletsTable />
      </Container>
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
