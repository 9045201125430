import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { getUniqueId } from '~/helpers/id'
import { IMessage } from '~/types/course'
import { DragAndDropOption, SingleCard } from '~/types/course'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, FormControl, FormLabel, TextField, Select, SelectChangeEvent, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { CommentFields, CommentFieldsProps } from '../fields/comment-fields'
import { removeCardFromColumn, removeCardFromLeftAndRightColumns } from './helpers'

type DragnDropProps = {
  message?: IMessage
  dragnDropTitle: string
  handleDragnDropTitle: (str: string) => void
  setDragnDropTitle: (str: string | undefined) => void
  dragnDropOptions: DragAndDropOption
  setDragnDropOptions: React.Dispatch<React.SetStateAction<DragAndDropOption>>
} & CommentFieldsProps

export const DragAndDrop = ({
  message,
  wrongAnswer,
  successAnswer,
  dragnDropTitle,
  handleDragnDropTitle,
  setDragnDropTitle,
  dragnDropOptions,
  setDragnDropOptions,
}: DragnDropProps) => {
  useEffect(() => {
    if (!message) return
    if (message.type === 'DRAG_AND_DROP') {
      setDragnDropTitle(message.title)
      setDragnDropOptions(message.options)
      wrongAnswer?.setValue?.(message.wrongAnswer)
      successAnswer?.setValue?.(message.successAnswer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, setDragnDropTitle, setDragnDropOptions])

  const handleColumnTitle = useCallback(
    (value: string, column: string) => {
      const state = cloneDeep(dragnDropOptions)

      state.columns[column].title = value

      setDragnDropOptions(state)
    },
    [dragnDropOptions, setDragnDropOptions],
  )

  const handleChangeCardContent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      const state = cloneDeep(dragnDropOptions)
      const { value } = e.target

      state.cards[id].content = value

      setDragnDropOptions(state)
    },
    [dragnDropOptions, setDragnDropOptions],
  )

  const handleAddCardItem = useCallback(() => {
    const state = cloneDeep(dragnDropOptions)
    const id = getUniqueId()
    const card: SingleCard = { id, content: '' }

    state.cards[id] = card
    state.columns['choose-column'].cardIds = [...state.columns['choose-column'].cardIds, id]

    setDragnDropOptions(state)
  }, [dragnDropOptions, setDragnDropOptions])

  const handleChangeCardColumn = useCallback(
    (e: SelectChangeEvent<string>, id: string) => {
      const state = cloneDeep(dragnDropOptions)
      const direction = e.target.value as 'left' | 'right' | 'none'

      if (direction === 'none') setDragnDropOptions(removeCardFromLeftAndRightColumns(state, id))

      if (direction === 'left') {
        state.columns.left.correctCardIds = [...state.columns.left.correctCardIds, id]
        state.columns.right.correctCardIds = removeCardFromColumn(state, id, 'right')

        setDragnDropOptions(state)
      } else if (direction === 'right') {
        state.columns.left.correctCardIds = removeCardFromColumn(state, id, 'left')
        state.columns.right.correctCardIds = [...state.columns.right.correctCardIds, id]

        setDragnDropOptions(state)
      }
    },
    [dragnDropOptions, setDragnDropOptions],
  )

  const handleDeletingCard = useCallback(
    (optionId: string) => {
      const state = cloneDeep(dragnDropOptions)

      // remove card from 'choose-column'
      const chooseCards = removeCardFromColumn(state, optionId, 'choose-column')
      // remove card from left column, if there is
      const leftColumnCards = removeCardFromColumn(state, optionId, 'left')
      // remove card from right column, if there is
      const rightColumnCards = removeCardFromColumn(state, optionId, 'right')

      state.columns['choose-column'].cardIds = chooseCards
      state.columns.left.correctCardIds = leftColumnCards
      state.columns.right.correctCardIds = rightColumnCards

      // remove card from 'cards'
      delete state.cards[optionId]

      setDragnDropOptions(state)
    },
    [dragnDropOptions, setDragnDropOptions],
  )

  return (
    <Box>
      <Box py={2}>
        <TextField
          fullWidth
          label="Title"
          value={dragnDropTitle}
          onChange={e => handleDragnDropTitle(e.target.value)}
          variant="outlined"
          style={{ marginTop: 10 }}
        />

        <CommentFields wrongAnswer={wrongAnswer} successAnswer={successAnswer} />

        <Box>
          <TextField
            fullWidth
            label="Left column title"
            value={dragnDropOptions.columns.left.title}
            onChange={e => handleColumnTitle(e.target.value, 'left')}
            variant="outlined"
            style={{ marginTop: 10 }}
          />
          <TextField
            fullWidth
            label="Right column title"
            value={dragnDropOptions.columns.right.title}
            onChange={e => handleColumnTitle(e.target.value, 'right')}
            variant="outlined"
            style={{ marginTop: 10 }}
          />
        </Box>
        <FormControl fullWidth>
          <ColumnLabels>
            <FormLabel component="legend">Column:</FormLabel>
            <FormLabel component="legend">Name:</FormLabel>
          </ColumnLabels>

          {Object.values(dragnDropOptions?.cards).map(option => {
            const leftDirection = dragnDropOptions?.columns?.left?.correctCardIds?.includes(option?.id)
            const rightDirection = dragnDropOptions?.columns?.right?.correctCardIds?.includes(option?.id)

            let value = 'none'
            if (leftDirection) value = 'left'
            if (rightDirection) value = 'right'

            return (
              <OptionRow width="100%" key={option.id}>
                <Select value={value} key={option.id} onChange={e => handleChangeCardColumn(e, option.id)}>
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
                <TextField
                  size="medium"
                  value={option.content}
                  multiline
                  fullWidth
                  onChange={e => handleChangeCardContent(e, option.id)}
                />
                <Box flex="0 0 auto">
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeletingCard(option.id)} size="large">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </OptionRow>
            )
          })}
        </FormControl>

        <Button size="small" startIcon={<AddCircleOutlineIcon />} onClick={handleAddCardItem}>
          Add a Card
        </Button>
      </Box>
    </Box>
  )
}

const OptionRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 380px 1fr;
  grid-gap: 10px;
  margin: 0.5em 0;
`
const ColumnLabels = styled(OptionRow)`
  margin-bottom: 12px;
`
