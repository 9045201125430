import { CourseDroplet, CourseDropletType } from '~/domain/course-droplet'
import { UserDropletStatus } from '~/domain/user-droplet'
import { getCollectionRef, getQuerySnapshot, getQuerySnapshotWithLimit } from '~/infra/firebase/firestore'

import { where } from '@firebase/firestore'

const COLLECTION_KEY = 'user-droplets'

const getDropletsCollection = (id?: string) =>
  getCollectionRef(COLLECTION_KEY, {
    id,
    converter: {
      fromFirestore: (snapshot: any) => CourseDroplet.toRaw(snapshot.data()),
      toFirestore: (droplet: CourseDroplet) => CourseDroplet.fromRaw(droplet),
    },
  })

const fetchDroplet = async (id: string) => {
  const droplet = await getDropletsCollection(id).get()

  return droplet.exists() ? droplet.data() : null
}

const getDroplets = async (courseId: string, status?: UserDropletStatus) => {
  const dropletDoc = await getQuerySnapshot(
    COLLECTION_KEY,
    where('courseId', '==', courseId),
    ...(status ? [where('status', '==', status)] : []),
  ).get()

  return dropletDoc.docs.map(doc => CourseDroplet.fromRaw({ ...doc.data(), id: doc.id }))
}

const getDropletsWithLimit = async (limit: number, courseId: string, lastDroplet?: CourseDropletType) => {
  const snapshot = lastDroplet ? await getDropletsCollection(lastDroplet.id).get() : null

  const dropletDoc = await getQuerySnapshotWithLimit(
    COLLECTION_KEY,
    'id',
    limit,
    snapshot,
    where('courseId', '==', courseId),
  ).get()

  return dropletDoc.docs.map(doc => CourseDroplet.fromRaw({ ...doc.data(), id: doc.id }))
}

export const UserDropletsApi = {
  fetchDroplet,
  getDroplets,
  getDropletsWithLimit,
}
