import { History } from 'history'
import React, { useMemo, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CourseListItem } from '~/components/course/CourseListItem'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useImport } from '~/hooks/use-import'
import { DefaultLayout } from '~/layouts/default-layout'

const getButtons = (
  history: History,
  handleOpenFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleUpload: (inputRef: React.RefObject<HTMLInputElement> | null) => void,
  uploadJSONRef: React.RefObject<HTMLInputElement>,
) => [
  {
    text: 'Upload JSON',
    onPress: () => handleUpload(uploadJSONRef),
    type: 'upload',
    handleOpenFile,
    uploadJSONRef,
  },
  {
    text: 'Create quest',
    onPress: () => history.push('/create'),
    type: 'navigate',
  },
]

export const CourseList = () => {
  const history = useHistory()
  const { courses } = useContext(coursesContext)

  const uploadJSONRef = useRef<HTMLInputElement>(null)
  const { handleOpenFile, handleUpload } = useImport()

  const buttons = useMemo(
    () => getButtons(history, handleOpenFile, handleUpload, uploadJSONRef),
    [handleOpenFile, handleUpload, history],
  )

  return (
    <DefaultLayout headerComponent={<Header title="All quests" buttons={buttons} />}>
      {courses?.map(course => (
        <CourseListItem key={course.id} course={course} />
      ))}
    </DefaultLayout>
  )
}
