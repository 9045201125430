import { Course, MESSAGES_TYPES, LessonGoalType, GoalEventMessageType } from '~/types/course'

export const restructureGoals = (course: Course) => {
  const finalCourseChapters = course?.chapters?.map(chapter => {
    const parsedChapterLessons = chapter?.lessons?.map(lesson => {
      const goalsMessages = lesson?.messages
        ?.map(message => [...(message?.messages ?? [])])
        ?.flat()
        ?.filter(message => message?.type === MESSAGES_TYPES.GOAL_EVENT) as GoalEventMessageType[] | undefined

      const settedGoalsInMessages = goalsMessages
        ?.map(message => lesson?.goals?.find(goal => message.targetGoalId === goal.id))
        ?.filter(goal => goal !== undefined)

      const settedGoalsInMessagesIds = settedGoalsInMessages?.map(goal => goal?.id) as string[]
      const otherGoals = lesson?.goals?.filter(goal => !settedGoalsInMessagesIds?.includes(goal?.id))
      const restructuredGoals = [...(settedGoalsInMessages ?? []), ...(otherGoals ?? [])]

      return { ...lesson, goals: restructuredGoals as LessonGoalType[] }
    })

    return {
      ...chapter,
      lessons: parsedChapterLessons,
    }
  })

  return { ...course, chapters: finalCourseChapters }
}
