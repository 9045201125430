/**
 * allows you to move an element to a given position
 * @param arr array with elements
 * @param from index of the current element
 * @param to index where to move the element
 * @returns array with moved elements
 */
// TODO: need tests
export const insertAndShift = (arr: any[], from: number, to: number) => {
  const copy = [...arr]
  const cutOut = copy.splice(from, 1)[0] // cut the element at index 'from'
  copy.splice(to, 0, cutOut) // insert it at index 'to'

  return copy
}
