import React from 'react'
import { isDevelopment } from '~/constants/platform'

import { Box, BoxProps, Typography } from '@mui/material'

export const Logo = ({ ...boxProps }: BoxProps) => (
  <Box {...boxProps}>
    <Typography variant="body2">Duke</Typography>
    {isDevelopment && <Typography variant="body2">Development Mode</Typography>}
  </Box>
)
