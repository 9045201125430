import React, { createContext, FunctionComponent, useEffect } from 'react'
import { useCourses } from '~/hooks/use-courses'

type CoursesContextType = ReturnType<typeof useCourses>

const coursesContext = createContext({} as CoursesContextType)

const { Provider } = coursesContext

const CoursesProvider: FunctionComponent = ({ children }) => {
  const coursesValue = useCourses()

  const { getCourses } = coursesValue

  useEffect(() => {
    getCourses()
  }, [getCourses])

  return <Provider value={coursesValue}>{children}</Provider>
}

export { coursesContext, CoursesProvider }
