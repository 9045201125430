import React from 'react'
import Showdown from 'showdown'
import styled from 'styled-components'
import { IMessage } from '~/types/course'

type MarkdownProps = {
  setMarkdownState: React.Dispatch<React.SetStateAction<string>>
  markdownState: string
  message?: IMessage
}

Showdown.extension('remove-extra-paragraphs', function () {
  return [
    {
      type: 'output',
      filter: function (markdown) {
        return replaceSymbols(markdown)
      },
    },
  ]
})

const replaceSymbols = (markdown: string) => {
  let replaced = markdown

  if (markdown) {
    replaced = replaced.replaceAll('<li><p>', '<li>')
    replaced = replaced.replaceAll('</p></li>', '</li>')

    replaced = replaced.replaceAll('<p><div>', '<div>')
    replaced = replaced.replaceAll('</div></p>', '</div>')

    replaced = replaced.replaceAll('<p><details>', '<details>')
    replaced = replaced.replaceAll('</details></p>', '</details>')

    return replaced
  }

  return markdown
}

const NativeMdEditor = (props: MarkdownProps) => {
  const { message, setMarkdownState } = props

  const MarkdownConverter = React.useMemo(
    () =>
      new Showdown.Converter({
        extensions: ['remove-extra-paragraphs'],
        tables: true,
      }),
    [],
  )

  const [localValue, setLocalValue] = React.useState('')

  const handleChange = (e: any) => {
    const value = e.target.value
    const convertedMdToHtml = MarkdownConverter.makeHtml(value)
    setLocalValue(value)
    setMarkdownState(convertedMdToHtml)
  }

  React.useEffect(() => {
    if (!message) return

    if (message.type === 'MARKDOWN') {
      const convertedHtmlToMd = MarkdownConverter.makeMarkdown(message.content)
      setMarkdownState(message.content)
      setLocalValue(convertedHtmlToMd)
    }
  }, [message, setMarkdownState, MarkdownConverter])

  return <Textarea value={localValue} onChange={e => handleChange(e)} />
}

// TODO: Refactor: Component is duplicated
export const NativeMdEditorWithoutMessage = (props: MarkdownProps) => {
  const { markdownState, setMarkdownState } = props

  const MarkdownConverter = React.useMemo(
    () =>
      new Showdown.Converter({
        extensions: ['remove-extra-paragraphs'],
        tables: true,
      }),
    [],
  )

  const [localValue, setLocalValue] = React.useState(markdownState)
  const [isEditing, setEditing] = React.useState(false)

  const handleToggleEditMode = (e: any) => {
    e.preventDefault()

    if (!isEditing) {
      return setEditing(true)
    }

    const convertedMdToHtml = MarkdownConverter.makeHtml(localValue)
    setMarkdownState(convertedMdToHtml)
    setEditing(false)
  }

  const handleChange = (e: any) => {
    const value = e.target.value
    setLocalValue(value)
  }

  React.useEffect(() => {
    const convertedHtmlToMd = MarkdownConverter.makeMarkdown(markdownState)
    setLocalValue(convertedHtmlToMd)
  }, [markdownState, MarkdownConverter])

  return (
    <>
      <EditButtons>
        <button onClick={e => handleToggleEditMode(e)}>{isEditing ? 'Save' : 'Edit'}</button>
        {isEditing && <button onClick={() => setEditing(false)}>Cancel</button>}
      </EditButtons>
      <Textarea disabled={!isEditing} value={localValue} onChange={e => handleChange(e)} />
    </>
  )
}

export default NativeMdEditor

export const EditButtons = styled('div')`
  margin-bottom: 10px;

  button {
    margin-right: 10px;
  }
`

const Textarea = styled('textarea')`
  width: 100%;
  min-height: 300px;
`
