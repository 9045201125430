import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Box } from '@mui/material'

export const InnerLink: React.FC<any> = ({
  to,
  children,
  isActive,
  onClick,
  shouldMatchExactRoute = true,
  ...boxProps
}) => {
  const match = useRouteMatch({
    path: to,
    exact: shouldMatchExactRoute,
  })

  return (
    <Outer display="inline-block" active={isActive || match} {...boxProps}>
      <Container to={to} onClick={onClick}>
        {children}
      </Container>
    </Outer>
  )
}

const Container = styled(Link)`
  color: #333;
  text-decoration: none;
`

const Outer = styled(Box)<{ active?: boolean }>`
  position: relative;
  cursor: pointer;

  ${p =>
    p.active &&
    `
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      border-radius: 1px 1px 0px 0px;
      background-color: #333;
    }
  `}
`
