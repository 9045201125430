class CustomStorage {
  get(key: string) {
    return localStorage.getItem(key)
  }

  set(key: string, payload: any) {
    localStorage.setItem(key, JSON.stringify(payload))
  }

  remove(key: string) {
    localStorage.removeItem(key)
  }
}

export const Storage = new CustomStorage()
