import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { CustomButton } from '~/components/button'
import { GoalsItem } from '~/components/goals/goals-item'
import { GoalsModal } from '~/modals/goals-modal'
import { LessonGoalType, LessonType } from '~/types/course'

import { Paper, Typography } from '@mui/material'

interface GoalsProps {
  lesson: LessonType
  onSaveGoal: (lesson: LessonType) => void
}

export const Goals = ({ lesson, onSaveGoal }: GoalsProps) => {
  const [goals, setGoals] = useState<LessonGoalType[]>(lesson.goals || [])
  const [isOpenGoalsModal, setOpenGoalsModal] = useState(false)
  const goalIdCopiedAlertRef = useRef(null) as any

  useEffect(() => {
    setGoals(lesson.goals || [])
  }, [lesson])

  const [selectedGoal, setSelectedGoal] = useState<LessonGoalType | null>(null)

  const handleCloseGoalsModal = () => {
    setOpenGoalsModal(false)
    setSelectedGoal(null)
  }

  const handleEditGoal = useCallback((goal: LessonGoalType) => {
    setOpenGoalsModal(true)
    setSelectedGoal(goal)
  }, [])

  const handleDeleteGoal = (id: string) => {
    const newGoals = [...goals].filter(item => item.id !== id)
    onSaveGoal({ ...lesson, goals: newGoals })
  }

  const handleGoalIdClick = (itemId: string) => {
    navigator.clipboard.writeText(itemId)
    goalIdCopiedAlertRef.current.classList.add('active')
    setTimeout(() => goalIdCopiedAlertRef.current.classList.remove('active'), 2000)
  }

  const onSave = (updatedGoal: LessonGoalType) => {
    //Если у урока не было поля goals или оно было пустым массивом
    if (!lesson.goals || !lesson.goals.length) {
      onSaveGoal({ ...lesson, goals: [updatedGoal] })
      return
    }

    const isNewGoal = !lesson.goals.some(item => item.id === updatedGoal.id)

    if (isNewGoal) {
      const newLesson = {
        ...lesson,
        goals: [...lesson.goals, updatedGoal],
      }
      onSaveGoal(newLesson)
      return
    }

    const updatedLesson: LessonType = {
      ...lesson,
      goals: lesson.goals?.map(goalItem => (goalItem.id === updatedGoal.id ? updatedGoal : goalItem)),
    }
    onSaveGoal(updatedLesson)
  }

  return (
    <Paper elevation={4} style={{ padding: 20, width: '50%', maxWidth: '50%' }}>
      {isOpenGoalsModal && (
        <GoalsModal onClose={handleCloseGoalsModal} isOpen={isOpenGoalsModal} onSave={onSave} goal={selectedGoal} />
      )}
      {goals.map(item => (
        <GoalWrap key={item.id}>
          <GoalsItem goal={item} onEdit={handleEditGoal} onDelete={handleDeleteGoal} />
          <p title="click to copy" onClick={() => handleGoalIdClick(item.id)}>
            UUID: <span className="id">{item.id}</span>
          </p>
        </GoalWrap>
      ))}
      <GoalIdCopiedAlert ref={goalIdCopiedAlertRef}>ID was copied to the clipboard</GoalIdCopiedAlert>
      <CustomButton variant="contained" color="green" onClick={() => setOpenGoalsModal(true)}>
        <Typography>Add a goal</Typography>
      </CustomButton>
    </Paper>
  )
}

const GoalIdCopiedAlert = styled('div')`
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: 300ms all;
  z-index: -1;
  opacity: 0;
  border-radius: 10px;

  &.active {
    opacity: 1;
    z-index: 1;
  }
`

const GoalWrap = styled('div')`
  padding: 5px;
  border-radius: 4px;
  margin: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
  position: relative;

  p {
    margin-top: 10px;
    cursor: pointer;
  }

  .id {
    background: rgba(0, 0, 0, 0.46);
    color: white;
    padding: 2px;
    border-radius: 3px;
    line-height: 1;
  }
`
