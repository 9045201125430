import { PaymentLinks, PaymentLinksType } from '~/domain/payment-links'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'

import { where } from '@firebase/firestore'

const COLLECTION_KEY = 'payment-links'

const getCollection = (id?: string) =>
  getCollectionRef(COLLECTION_KEY, {
    id,
    converter: {
      fromFirestore: (snapshot: any) => PaymentLinks.toRaw(snapshot.data()),
      toFirestore: (data: PaymentLinks) => PaymentLinks.fromRaw(data),
    },
  })

const fetch = async (id: string): Promise<PaymentLinksType | null> => {
  const document = await getCollection(id).get()

  if (document.exists()) {
    return PaymentLinks.fromRaw(document.data() as PaymentLinks)
  }

  return null
}

const fetchLinks = async (courseId: string) => {
  const docs = await getQuerySnapshot(COLLECTION_KEY, where('courseId', '==', courseId)).get()

  return docs.docs.map(doc => PaymentLinks.fromRaw({ ...doc.data(), id: doc.id }))
}

const createOrUpdate = async (data: PaymentLinksType) => {
  const config = PaymentLinks.fromRaw(new PaymentLinks(data))
  const document = getCollection(config.id)
  await document.set(config)

  return config
}

export const PaymentLinksApi = {
  fetch,
  createOrUpdate,
  fetchLinks,
}
