import React, { useContext, useEffect, useState } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { UserDropletStatus } from '~/domain/user-droplet'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DefaultLayout } from '~/layouts/default-layout'
import { userDropletsStore } from '~/store'

import { Checkbox, Container, FormControlLabel, Typography } from '@mui/material'

import { UserDropletsTable } from './user-droplets-table'

export const UserDropletsPage = () => {
  const [activeChecked, setActiveChecked] = useState(true)
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  useEffect(() => {
    userDropletsStore.fetchDroplets(id ?? '', activeChecked ? UserDropletStatus.active : undefined)
  }, [id, activeChecked])
  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => setActiveChecked(e.target.checked)

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`Users droplets in the quest "${course?.name}"`} />}
    >
      <Container maxWidth="xl">
        <FormControlLabel
          control={<Checkbox checked={activeChecked} onChange={handleActiveChange} />}
          label="active only"
        />
        <Typography>Users created droplets</Typography>
        <UserDropletsTable />
      </Container>
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
