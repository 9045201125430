import { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { SkillApi } from '~/api/skills'
import { coursesContext } from '~/contexts/courses'
import { SkillType } from '~/domain/skills'
import { useCourseRoute } from '~/hooks/use-course-route'

export const useCourseSkills = (skillsId: (string | undefined)[] | undefined) => {
  const [skillsForOptions, setSkillsForOptions] = useState<SkillType[]>([])
  const [selectedSkills, setSelectedSkills] = useState<string[]>([])
  const [messageSkills, setMessageSkills] = useState<SkillType[]>([])

  const { id: courseId } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(courseId)
  }, [courseId, getCourse])

  const userSelectedSkillsId = useMemo(() => skillsId ?? [], [skillsId])

  useEffect(() => {
    const getSkillsByIds = async () => {
      if (!course?.skillset?.length) return
      const courseSkills = await SkillApi.getSkillsByIds(course?.skillset)

      const selectedSkills = courseSkills.filter(skill => userSelectedSkillsId.includes(skill?.id))
      const selectedSkillsId = selectedSkills.map(skill => skill?.id) as string[]

      setSkillsForOptions(courseSkills)
      setMessageSkills(selectedSkills)
      setSelectedSkills(selectedSkillsId)
    }
    getSkillsByIds()
  }, [course?.skillset, userSelectedSkillsId])

  const handleSkillsSelect = useCallback(selectedSkills => {
    const selectedSkillsId = selectedSkills.map((skill: SkillType) => skill?.id)

    setSelectedSkills(selectedSkillsId)
    setMessageSkills(selectedSkills)
  }, [])

  return { messageSkills, skillsForOptions, selectedSkills, handleSkillsSelect }
}
