import React, { useState } from 'react'
import { PaymentModal } from '~/modals/payment-modal'
import { Course } from '~/types/course'

import { Button } from '@mui/material'

export const AddNewPayment = ({ course }: { course: Course }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false)

  const handleOpenModal = () => {
    setModalIsVisible(true)
  }

  const handleCloseModal = () => {
    setModalIsVisible(false)
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Give access (payment)
      </Button>
      {modalIsVisible && <PaymentModal course={course} onClose={handleCloseModal} isOpen={modalIsVisible} />}
    </div>
  )
}
