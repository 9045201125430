import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { ButtonsGroup } from '~/components/buttons-group'
import { SpecializationType, Currencies, CurrencyType } from '~/domain/specializations'
import { Visible } from '~/domain/types'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'

import {
  Box,
  Dialog,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material'

interface SpecializationModalProps {
  onClose: () => void
  isOpen: boolean
  specialization?: SpecializationType
  onSave: (specialization: SpecializationType) => void
}

export const SpecializationModal: FunctionComponent<SpecializationModalProps> = React.memo(props => {
  const { onClose, isOpen, specialization, onSave } = props
  const [isVisible, setVisible] = useState(specialization?.isVisible ?? true)
  const [currency, setCurrency] = useState<CurrencyType>(specialization?.salary?.currency ?? Currencies.USD)
  const [amount, setAmount] = useState(specialization?.salary?.amount ?? '0')
  const isEdit = Boolean(specialization)

  const {
    value: name,
    handleSetValue: handleSetName,
    isValid: nameIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: specialization?.name })

  const isSaveEnabled = useMemo(() => nameIsValid, [nameIsValid])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newSpecialization: SpecializationType = {
        ...specialization,
        name,
        salary: {
          currency,
          amount,
        },
        isVisible,
      }

      onSave(newSpecialization)

      return onClose()
    },
    [isSaveEnabled, specialization, name, currency, amount, isVisible, onSave, onClose],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Specialization',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [onClose, isEdit, isSaveEnabled, handleSubmit],
  )

  const handleVisibleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    const visible = value === Visible.YES
    setVisible(visible)
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box py={2}>
            <TextField
              fullWidth
              label="Specialization"
              required
              value={name}
              onChange={handleSetName}
              error={!nameIsValid}
            />
          </Box>
          <Box py={1}>
            <Typography>Salary</Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                value={currency}
                onChange={e => setCurrency(e.target.value as CurrencyType)}
                label="Currency"
              >
                {Object.keys(Currencies).map(currency => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <TextField fullWidth label="Amount" required value={amount} onChange={e => setAmount(e.target.value)} />
            </FormControl>
          </Box>
          <Box py={2}>
            <FormLabel id="visible-label">Is visible?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="visible-label"
              value={isVisible ? Visible.YES : Visible.NO}
              onChange={handleVisibleChange}
            >
              <FormControlLabel value={Visible.YES} control={<Radio />} label="Yes" />
              <FormControlLabel value={Visible.NO} control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
