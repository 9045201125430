import { useCallback, useMemo, useState } from 'react'

type SwitchHelpers = Record<'on' | 'off' | 'toggle', () => void>

type UseSwitch = (defaultValue: boolean) => [boolean, SwitchHelpers]

export const useSwitch: UseSwitch = (defaultValue = false) => {
  const [isOn, setIsOn] = useState(defaultValue)

  const on = useCallback(() => setIsOn(true), [])
  const off = useCallback(() => setIsOn(false), [])
  const toggle = useCallback(() => setIsOn(v => !v), [])

  return useMemo(() => [isOn, { on, off, toggle }], [isOn, on, off, toggle])
}
