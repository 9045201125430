import { useContext, useMemo } from 'react'
import { authorsContext } from '~/contexts/authors'
import { useInput } from '~/hooks/use-input'

export const useAuthorMetaInput = (defaultValue?: string) => {
  const { authors } = useContext(authorsContext)

  const { value: authorValue, handleSetValue: handleSetAuthor } = useInput({ defaultValue })

  const fullAuthorObject = useMemo(
    () => authors.find(item => `${item.firstName} ${item.lastName}` === authorValue),
    [authors, authorValue],
  )

  return { fullAuthorObject, authorValue, handleSetAuthor }
}
