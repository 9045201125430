import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  getDocs,
  FieldValue as FireStoreFieldValue,
  Timestamp as FireStoreTimestamp,
  orderBy,
  limit,
  startAfter,
  DocumentSnapshot,
  QueryConstraint,
  serverTimestamp,
} from '@firebase/firestore'

import { db } from './firebase'

export type FieldValue = FireStoreFieldValue
export type Timestamp = FireStoreTimestamp
export const getServerTimestamp = () => serverTimestamp()

export const getCollectionRef = (collectionKey: string, { customDb, converter, id }: any) => {
  const ref = doc(customDb || db, collectionKey, id).withConverter(converter)

  return {
    get: async () => await getDoc(ref),
    set: async (data: any) => await setDoc(ref, data),
  }
}

export const getQuerySnapshot = (collectionKey: string, ...queryConstraints: QueryConstraint[]) => {
  const q = query(collection(db, collectionKey), ...queryConstraints)

  return {
    get: async () => await getDocs(q),
  }
}

export const getQuerySnapshotWithLimit = (
  collectionKey: string,
  fieldToOrderBy: string,
  objectsLimit: number,
  lastVisibleObject?: DocumentSnapshot | null,
  ...queryConstraints: QueryConstraint[]
) =>
  getQuerySnapshot(
    collectionKey,
    orderBy(fieldToOrderBy),
    limit(objectsLimit),
    startAfter(lastVisibleObject ?? null),
    ...queryConstraints,
  )
