import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { ButtonsGroup } from '~/components/buttons-group'
import { CourseUsersMetaType } from '~/domain/usersMeta'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useCourseRoute } from '~/hooks/use-course-route'
import { useInput } from '~/hooks/use-input'

import { Box, Dialog, TextField } from '@mui/material'

interface UserMetaModalProps {
  onClose: () => void
  isOpen: boolean
  userMeta?: CourseUsersMetaType
  onSave: (userMeta: CourseUsersMetaType) => void
}

export const UserMetaModal: FunctionComponent<UserMetaModalProps> = React.memo(props => {
  const { onClose, isOpen, userMeta, onSave } = props
  const id = userMeta?.id ?? getUniqueId()
  const isEdit = Boolean(userMeta)
  const { id: courseId } = useCourseRoute()

  const {
    value: firstName,
    handleSetValue: handleSetName,
    isValid: nameIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: userMeta?.firstName })

  const { value: lastName, handleSetValue: handleSetLastName } = useInput({ defaultValue: userMeta?.lastName })

  const { value: role, handleSetValue: handleSetRole } = useInput({ defaultValue: userMeta?.role })

  const { value: description, handleSetValue: handleSetDescription } = useInput({ defaultValue: userMeta?.description })

  const { value: avatar, handleSetValue: handleSetAvatar } = useInput({ defaultValue: userMeta?.avatar })

  const isSaveEnabled = useMemo(() => nameIsValid, [nameIsValid])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newUserMeta: CourseUsersMetaType = {
        id,
        courseId: courseId ?? '',
        firstName,
        lastName,
        role,
        description,
        avatar,
        createdAt: userMeta?.createdAt,
      }

      onSave(newUserMeta)

      return onClose()
    },
    [onSave, id, isSaveEnabled, firstName, lastName, role, description, avatar, onClose, userMeta?.createdAt, courseId],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Save' : 'Create User Meta',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [onClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box py={2}>
            <TextField
              fullWidth
              label="first Name"
              required
              value={firstName}
              onChange={handleSetName}
              error={!nameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={1}>
            <TextField fullWidth label="last Name" value={lastName} onChange={handleSetLastName} variant="outlined" />
          </Box>
          <Box py={1}>
            <TextField fullWidth label="role" value={role} onChange={handleSetRole} variant="outlined" />
          </Box>
          <Box py={1}>
            <TextField
              fullWidth
              label="description"
              value={description}
              onChange={handleSetDescription}
              variant="outlined"
            />
          </Box>
          <Box py={1}>
            <TextField fullWidth label="avatar" value={avatar} onChange={handleSetAvatar} variant="outlined" />
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
