import React from 'react'
import Loader from 'react-loader-spinner'
import { UserRole } from '~/domain/user'
import { Course as CourseType } from '~/types/course'

import { Button } from '@mui/material'

import { PrivateComponent } from '../private-component'
import { useUpdateCourse } from './hooks/use-update-course'

interface props {
  course: CourseType
}

export const SyncFireStoreBtn = (props: props) => {
  const { course } = props
  const { fetchedCourse, isLoading, handleCreateOrUpdateInDb } = useUpdateCourse({ course })

  const syncBtnTitle = !!fetchedCourse ? 'Update in Firestore' : 'Add to Firestore'

  return (
    <PrivateComponent roles={[UserRole.editor]}>
      <Button
        variant="outlined"
        size="small"
        disabled={isLoading}
        onClick={e => handleCreateOrUpdateInDb(e, course)}
        color={!!fetchedCourse ? 'success' : 'info'}
      >
        {!isLoading ? syncBtnTitle : <Loader type="Oval" color="blue" height={12} width={12} />}
      </Button>
    </PrivateComponent>
  )
}
