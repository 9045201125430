import React, { useContext, useEffect, useState } from 'react'
import { AnalyticsApi } from '~/api/analytics'
import { coursesContext } from '~/contexts/courses'
import { UserFeedbackType } from '~/domain/user-feedback'
import { UserHistoryType } from '~/domain/user-histories'
import { useCourseRoute } from '~/hooks/use-course-route'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Paper,
  Grid,
  Container,
  styled,
  Typography,
  IconButton,
  Collapse,
  Pagination,
  List,
  ListItem,
  Card,
  CardContent,
  Rating,
} from '@mui/material'

const UsersAnalytics: React.FC = () => {
  const { id } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)
  const [usersHistories, setUsersHistories] = useState<UserHistoryType[]>([])
  const [usersFeedback, setUsersFeedback] = useState<UserFeedbackType[]>([])
  const [showFeedback, setShowFeedback] = useState(true)
  const [feedbackPage, setFeedbackPage] = useState(1)
  const [githubAgeDistribution] = useState<{ years: number; count: number }[]>([])
  const feedbackPerPage = 5

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  useEffect(() => {
    if (course?.id) {
      AnalyticsApi.getCourseUsersHistories(course.id).then(res => {
        setUsersHistories(res.histories)
      })
      AnalyticsApi.getCourseUsersFeedback(course.id).then(res => {
        setUsersFeedback(res.feedback)
      })
    }
  }, [course?.id])

  const getFinishedUsersCount = () => {
    if (!course?.chapters?.length) return 0
    const lastChapter = course.chapters[course.chapters.length - 1]
    if (!lastChapter.lessons?.length) return 0
    const lastLesson = lastChapter.lessons[lastChapter.lessons.length - 1]
    return usersHistories.filter(history => {
      const userLastChapterHistory = history.histories.find(h => h.lessonId === lastLesson.id)
      return userLastChapterHistory?.lastMessageId === lastLesson.messages?.[lastLesson.messages.length - 1].id
    }).length
  }

  const getUsersByChapter = () => {
    if (!course?.chapters) return []
    return course.chapters.map(chapter => {
      const usersInChapter = usersHistories.filter(history =>
        history.histories.some(h => h.chapterId === chapter.id),
      ).length
      return {
        chapterName: chapter.name,
        usersCount: usersInChapter,
      }
    })
  }

  const paginatedFeedback = usersFeedback.slice((feedbackPage - 1) * feedbackPerPage, feedbackPage * feedbackPerPage)

  return (
    <Box>
      <AnalyticsHeader variant="h5">Users Analytics</AnalyticsHeader>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <React.Fragment>
                <Typography component="p" variant="h4">
                  {usersHistories.length}
                </Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  Users started
                </Typography>
              </React.Fragment>
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={3} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <React.Fragment>
                <Typography component="p" variant="h4">
                  {getFinishedUsersCount()}
                </Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  Users finished
                </Typography>
              </React.Fragment>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <React.Fragment>
                {getUsersByChapter().map((chapterStat, index) => (
                  <Typography component="p" variant="h4" key={index}>
                    {chapterStat.chapterName}: {chapterStat.usersCount}
                  </Typography>
                ))}
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                  Users progress by chapters
                </Typography>
              </React.Fragment>
            </Paper>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>

      <Box sx={{ mt: 3 }}>
        <AnalyticsHeader variant="h6">GitHub account age distribution: //todo: this need to be a chart</AnalyticsHeader>
        {githubAgeDistribution?.length > 0 && (
          <Box>
            {githubAgeDistribution?.map(({ years, count }) => (
              <AnalyticsText key={years}>
                {years} years: {count} users
              </AnalyticsText>
            ))}
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Box>
          Average user feedback mark:{' '}
          {(usersFeedback.reduce((acc, curr) => acc + curr.messages.feedbackMark, 0) / usersFeedback.length).toFixed(2)}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AnalyticsHeader variant="h6">Users feedback:</AnalyticsHeader>
          <IconButton onClick={() => setShowFeedback(!showFeedback)} size="small">
            {showFeedback ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={showFeedback}>
        {usersFeedback?.length > 0 ? (
          <>
            <List>
              {paginatedFeedback.map((feedback, index) => (
                <ListItem key={index} sx={{ px: 0 }}>
                  <Card sx={{ width: '100%' }}>
                    <CardContent sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                      <Rating value={feedback.messages.feedbackMark} readOnly size="small" />
                      <Typography>{feedback.messages.comment}</Typography>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={Math.ceil(usersFeedback.length / feedbackPerPage)}
                page={feedbackPage}
                onChange={(_, page) => setFeedbackPage(page)}
              />
            </Box>
          </>
        ) : (
          <AnalyticsText>No feedback available</AnalyticsText>
        )}
      </Collapse>
    </Box>
  )
}

export default UsersAnalytics

const AnalyticsHeader = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
`
const AnalyticsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
`
