import { MESSAGES_TYPES } from '~/types/course'

export const colors = {
  TEXT: '#BBDEFB',
  [MESSAGES_TYPES.DIALOG]: '#BBDEFB',
  [MESSAGES_TYPES.FACT]: '#BBC1FB',
  [MESSAGES_TYPES.THOUGHT]: '#FBE6BB',
  [MESSAGES_TYPES.QUOTE]: '#b6bdf9',
  [MESSAGES_TYPES.GALLERY]: '#aaf4d2',
  [MESSAGES_TYPES.MATCHING]: '#d443b7',
  [MESSAGES_TYPES.QUIZ]: '#9c7f7f',
  [MESSAGES_TYPES.BLANK]: '#fc8f8f', // deprecated
  [MESSAGES_TYPES.FILL_THE_GAP]: '#e6b8c7',
  QUESTION: '#B2EF9B',
  MARKDOWN: '#FFDDE2',
  CARD_INPUT: '#FAFAC6',
  [MESSAGES_TYPES.DRAG_AND_DROP]: '#c3ee9d',
  [MESSAGES_TYPES.SUMMARY]: '#eeee9d',
  [MESSAGES_TYPES.FINISH]: '#6d5555d5',
  [MESSAGES_TYPES.GOAL_EVENT]: '#debbd7',
  [MESSAGES_TYPES.TASK]: '#e3e3e3',
  [MESSAGES_TYPES.GOALS_SLIDER]: '#BBC1FB',
  [MESSAGES_TYPES.ASSESMENT]: '#BBC1FB',
  [MESSAGES_TYPES.ASSESMENT_RESULT]: '#c4fbbb',
  [MESSAGES_TYPES.START_DAY]: 'rgba(246,17,17,0.5)',
  [MESSAGES_TYPES.GITHUB_TASK]: '#FFDDE2',
  [MESSAGES_TYPES.GITHUB_LINK]: '#FFDDE2',
  [MESSAGES_TYPES.CONNECT_WALLET]: '#FFDDE2',
  [MESSAGES_TYPES.DEPLOY_CONTRACT]: '#FFDDE2',
}
