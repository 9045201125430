import React from 'react'
import { CustomButton } from '~/components/button'
import { Header } from '~/components/header'
import { InnerLink } from '~/components/link'
import { DefaultLayout } from '~/layouts/default-layout'

import { Box, Typography } from '@mui/material'

export const CourseNotFound = () => (
  <DefaultLayout mainBoxProps={{ display: 'flex' }} headerComponent={<Header title="Edit Quest" />}>
    <Box flex={1} display="flex" alignItems="center" flexDirection="column" justifyContent="center">
      <Typography variant="h5" component="h2" style={{ textAlign: 'center' }}>
        This quest does not exist, but you can
        <InnerLink to="/create">
          <CustomButton variant="contained" color="blue" style={{ marginLeft: 10 }}>
            create
          </CustomButton>
        </InnerLink>
      </Typography>
    </Box>
  </DefaultLayout>
)
