// src/components/CardComponent.js
import React from 'react'
import styled from 'styled-components'

import { Box } from '@mui/material'

const Card = styled(Box)`
  width: 480px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
`

const ImageContainer = styled(Box)`
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
`

const ImageWrapper = styled(Box)`
  position: absolute;
  inset: 8px;
  overflow: hidden;
  border-radius: 12px;
`

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;

  ${ImageWrapper}:hover & {
    transform: scale(1.05);
  }
`

const Overlay = styled(Box)`
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 20%, transparent 100%);
`

const Badge = styled(Box)`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to right, #f6b73c, #eb9e3a);
  color: white;
  padding: 6px 24px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
`

const BottomText = styled(Box)`
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 700;
  z-index: 10;
`

const CardFooter = styled(Box)`
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IdTag = styled(Box)`
  font-size: 14px;
  color: #666;
  font-weight: 500;
`

const Tag = styled(Box)`
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 50px;
  margin-left: 8px;
`

const GenesisTag = styled(Tag)`
  background: rgba(20, 184, 166, 0.1);
  color: rgb(17, 94, 89);
`

const ExclusiveTag = styled(Tag)`
  background: rgba(168, 85, 247, 0.1);
  color: rgb(107, 33, 168);
`

const TagGroup = styled(Box)`
  display: flex;
  align-items: center;
`

type AchievementProps = {
  title: string
  img: string
  alt: string
  description: string
  id: string
}

const idView = (id: string) => {
  return '#' + id.padStart(4, '0')
}

export const Achievement = ({ title, img, alt, description, id }: AchievementProps) => {
  return (
    <Card>
      <ImageContainer>
        <Badge>{title}</Badge>
        <ImageWrapper>
          <CardImage src={img} alt={alt} />
          <Overlay />
          <BottomText>{description}</BottomText>
        </ImageWrapper>
      </ImageContainer>
      <CardFooter>
        <TagGroup>
          <IdTag>{idView(id)}</IdTag>
          <GenesisTag>Genesis</GenesisTag>
        </TagGroup>
        <ExclusiveTag>Exclusive</ExclusiveTag>
      </CardFooter>
    </Card>
  )
}

export default Achievement
