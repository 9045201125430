import { LESSON_COLLECTION_KEY } from '~/constants/firebase-collections-keys'
import { Lesson } from '~/domain/lesson'
import { getCollectionRef } from '~/infra/firebase/firestore'

import { Firestore } from '@firebase/firestore'

const getLessonCollection = (id?: string, db?: Firestore) =>
  getCollectionRef(LESSON_COLLECTION_KEY, {
    id: id,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => Lesson.fromRaw(snapshot.data()),
      toFirestore: (course: Lesson) => Lesson.toRaw(course),
    },
  })

const getLesson = async (lessonId?: string) => {
  const course = await getLessonCollection(lessonId).get()

  return course.exists() ? course.data() : null
}

const createOrUpdateLesson = async (lesson: Lesson) => {
  const ref = getLessonCollection(lesson.id)
  await ref.set(lesson)
}

export const LessonApi = {
  createOrUpdateLesson,
  getLesson,
  getLessonCollection,
}
