import { Skill, SkillTree, SkillType, SKILL_NEST_TYPE } from '~/domain/skills'
import { dbProduction } from '~/infra/firebase/firebase'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'
import { getToken } from '~/utils/get-token'

import { Firestore, where } from '@firebase/firestore'

import { POST } from './rest'

const SKILLS_COLLECTION_KEY = 'skills'

const getSkillsCollection = (skillId?: string, db?: Firestore) =>
  getCollectionRef(SKILLS_COLLECTION_KEY, {
    id: skillId,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => Skill.fromRaw(snapshot.data()),
      toFirestore: (skill: Skill) => Skill.toRaw(skill),
    },
  })

const fetchSkill = async (skillId: string) => {
  const skill = await getSkillsCollection(skillId).get()

  return skill.exists() ? skill.data() : null
}

const createOrUpdateSkillHandler = async (skill: Skill, db?: Firestore) => {
  const skillDocument = getSkillsCollection(skill.id, db)
  await skillDocument.set(skill)

  return skill
}

const createOrUpdateSkill = async (skill: Skill) => await createOrUpdateSkillHandler(skill)

const createOrUpdateSkillInProductionDB = async (skill: Skill) => await createOrUpdateSkillHandler(skill, dbProduction)

const getAllSkills = async () => {
  const skillDocs = await getQuerySnapshot(SKILLS_COLLECTION_KEY).get()

  return skillDocs.docs.map(doc => Skill.fromRaw({ ...doc.data(), id: doc.id }))
}

const getSkillsByType = async (skillType: SKILL_NEST_TYPE) => {
  const childSkillsDocs = await getQuerySnapshot(SKILLS_COLLECTION_KEY, where('type', '==', skillType)).get()

  return childSkillsDocs.docs.map(doc => Skill.fromRaw({ ...doc.data(), id: doc.id }))
}

const getChildSkillsByParentId = async (childSkillType: SKILL_NEST_TYPE, parentId: string) => {
  const childSkillsDocs = await getQuerySnapshot(
    SKILLS_COLLECTION_KEY,
    where('type', '==', childSkillType),
    where('parentIds', 'array-contains', parentId),
  ).get()

  return childSkillsDocs.docs.map(doc => Skill.fromRaw({ ...doc.data(), id: doc.id }))
}

const getSkills = async (limit: number) => {
  const token = getToken()
  return await POST<SkillType[]>('skills-getSkills', { limit }, token)
}
const getSkillsByIds = async (skillIds: string[]) => {
  const token = getToken()
  return await POST<SkillType[]>('skills-getSkillsByIds', { skillIds }, token)
}

const getSkillTree = async (skillIds: string[]) => {
  const token = await getToken()
  return await POST<SkillTree>('skills-getSkillTree', { skillIds }, token)
}

const getNextPageSkills = async (lastSkill: SkillType, limit: number) => {
  const token = getToken()
  return await POST<SkillType[]>('skills-getNextPageSkills', { lastSkill, limit }, token)
}

export const SkillApi = {
  fetchSkill,
  createOrUpdateSkill,
  getSkills,
  getNextPageSkills,
  getAllSkills,
  getSkillsByType,
  getChildSkillsByParentId,
  getSkillsByIds,
  getSkillTree,
  /**
   * production
   */
  createOrUpdateSkillInProductionDB,
}
