import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { useInput } from '~/hooks/use-input'
import { DefaultLayout } from '~/layouts/default-layout'
import { paymentsStore, userStore } from '~/store'

import { Box, Container, TextField, Typography } from '@mui/material'

import { AddNewPayment } from './add-new-payment'
import { PaymentsTable } from './payments-table'

export const PaymentsPage = () => {
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  const { value, handleSetValue } = useInput()

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  useEffect(() => {
    !value && paymentsStore.fetch(id || '')
  }, [id, value])

  const handleSearchUserPayment = async () => {
    const user = await userStore.fetchUserByEmail(value)
    if (user?.[0]?.id) {
      paymentsStore.fetch(id || '', user[0].id)
    }
  }

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`List with "${course?.name}"`} />}
    >
      <Container maxWidth="lg">
        <Typography>Quest payments</Typography>
        <Box my={2} display="flex" justifyContent="space-betwe">
          <TextField
            fullWidth
            label="FInd payment by user's email"
            placeholder="Exact email address"
            value={value}
            onChange={handleSetValue}
            variant="outlined"
          />
          <button disabled={!value} onClick={handleSearchUserPayment}>
            Find
          </button>
        </Box>
        <AddNewPayment course={course} />
        <PaymentsTable />
      </Container>
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
