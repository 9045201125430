import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Payment, PaymentStatuses } from '~/domain/payment/payment'
import { paymentsStore, userStore } from '~/store'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type Column = {
  id: 'amount' | 'userId' | 'paymentProvider' | 'status' | 'type' | 'paymentId'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: 'amount', label: 'Price (USD)', minWidth: 100 },
  {
    id: 'userId',
    label: 'User',
  },
  {
    id: 'paymentProvider',
    label: 'Provider',
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'right',
  },
  {
    id: 'type',
    label: 'Payment type',
    align: 'right',
  },
  {
    id: 'paymentId',
    label: '',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '100vh',
    },
  }),
)

export const PaymentsTable = observer(() => {
  const classes = useStyles()
  const [isRequested, setIsRequested] = useState(false)
  const paymentsFromStore = toJS(paymentsStore.payments)
  const usersFromStore = toJS(userStore.users)
  const paymentsWithMeta = useMemo(() => {
    return paymentsFromStore.map(item => {
      const user = usersFromStore?.find(user => user?.id === item?.userId)

      return {
        ...item,
        ...(user ? { user } : {}),
      }
    })
  }, [usersFromStore, paymentsFromStore])

  useEffect(() => {
    const async = async () => {
      const userIds = paymentsWithMeta?.filter(item => !item.user).map(item => item?.userId) || []
      const uniqIds = Array.from(new Set(userIds))

      for await (const id of uniqIds) {
        userStore.fetchUserById(id)
      }

      setIsRequested(false)
    }

    !isRequested && paymentsWithMeta?.length && async()
  }, [paymentsWithMeta, isRequested])

  const handleUpdate = useCallback((data: Payment) => {
    if (data.status === PaymentStatuses.CONFIRMED) {
      paymentsStore.update(data?.orderId || data.id, PaymentStatuses.REJECTED)
    }

    if (data.status === PaymentStatuses.REJECTED) {
      paymentsStore.update(data?.orderId || data.id, PaymentStatuses.CONFIRMED)
    }
  }, [])

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsWithMeta?.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value = row[column.id]

                      if (column.id === 'amount') {
                        const amountInCents = row.amount
                        const amount = amountInCents / 100
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {amount}
                          </TableCell>
                        )
                      }

                      if (column.id === 'userId') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <p>
                              <b>{row?.user?.email}</b>
                            </p>
                            <p>{row?.user?.displayName}</p>
                            <p>{row.userId}</p>
                          </TableCell>
                        )
                      }

                      if (column.id === 'paymentId') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {[PaymentStatuses.CONFIRMED, PaymentStatuses.REJECTED].includes(row.status) && (
                              <button onClick={() => handleUpdate(row)}>
                                {row.status === PaymentStatuses.REJECTED && 'Confirm the payment'}
                                {row.status === PaymentStatuses.CONFIRMED && 'Reject the payment'}
                              </button>
                            )}
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
})
