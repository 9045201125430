import React, { Children } from 'react'
import styled from 'styled-components'
import { Header } from '~/components/auth-header/header'
import { InnerLink } from '~/components/link'
import { CustomDialog } from '~/containers/custom-dialog'

import { Box, BoxProps, Typography } from '@mui/material'

type Props = {
  // nav?: {
  //   text: string
  //   to?: string
  //   onPress?: () => void
  //   isActive?: boolean
  // }[]
  backProps?: {
    text: string
    url?: string
    onClick?: () => void
  }
  navChapters?: React.ReactElement
  navLessons?: React.ReactElement
  headerComponent?: React.ReactElement
  mainBoxProps?: BoxProps
}

export const DefaultLayout: React.FC<Props> = ({
  children,
  navChapters,
  navLessons,
  headerComponent,
  backProps,
  mainBoxProps,
}) => {
  const arrayChildren = Children.toArray(children)

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {headerComponent ? (
        <HeaderContainer component="header" width="100%">
          <Header />
          {headerComponent}
        </HeaderContainer>
      ) : (
        <Header />
      )}
      <Box flex={1} display="flex" flexDirection="row">
        {(navChapters || backProps) && (
          <Aside component="aside" maxWidth={280} flex={1} px={2}>
            {backProps && (
              <Box py={2}>
                <InnerLink to={backProps.url} onClick={backProps.onClick}>
                  <Typography>{backProps.text ?? '<- Go back'}</Typography>
                </InnerLink>
              </Box>
            )}
            {navChapters}
          </Aside>
        )}
        {navLessons && (
          <Aside component="aside" maxWidth={280} flex={1} px={2}>
            {backProps && (
              <Box py={2}>
                <Typography>Tasks</Typography>
              </Box>
            )}
            {navLessons}
          </Aside>
        )}
        <Box flex={1} width="100%" component="main" p={2} {...mainBoxProps}>
          {Children.map(arrayChildren, child => child)}
          <CustomDialog />
        </Box>
      </Box>
    </Box>
  )
}

const HeaderContainer = styled(Box)`
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
`

const Aside = styled(Box)`
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.15);
`
