import React from 'react'
import styled from 'styled-components'
import { LessonGoalType } from '~/types/course'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Card, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

interface Props {
  goal: LessonGoalType

  onEdit(goal: LessonGoalType): void

  onDelete(id: string): void
}

export const GoalsItem = React.memo(({ goal, onDelete, onEdit }: Props) => {
  const { id, text } = goal
  return (
    <CustomCard key={id} variant="outlined">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography gutterBottom variant="h5" component="div" maxWidth="80%" style={{ overflowWrap: 'break-word' }}>
          {text}
        </Typography>
        <Box>
          <IconButton edge="end" aria-label="edit" onClick={() => onEdit(goal)}>
            <EditIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </CustomCard>
  )
})

const CustomCard = styled(Card)`
  padding: 10px;
  margin-top: 10px;
`
