import React, { Children, useMemo } from 'react'
import { UserRole } from '~/domain/user'
import { userStore } from '~/store'

type PrivateRouteProps = {
  roles?: UserRole[]
}

export const PrivateComponent: React.FC<PrivateRouteProps> = ({ children, roles }) => {
  const arrayChildren = Children.toArray(children)
  const currentUser = userStore.currentUser

  const isSucceed = useMemo(() => {
    return currentUser?.roles
      ? currentUser?.roles.reduce((hasRole, role) => {
          return Number(hasRole) + Number(roles?.includes(role))
        }, 0)
      : 1
  }, [roles, currentUser])

  return isSucceed ? <>{Children.map(arrayChildren, child => child)}</> : null
}
