import { isNumber } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { GithubApi } from '~/api/github'
import { CustomButton } from '~/components/button'
import { Goals } from '~/components/goals'
import { MessageGroup } from '~/components/message-group'
import { Direction } from '~/domain/types'
import { getUniqueId } from '~/helpers/id'
import { insertAndShift } from '~/helpers/insert-and-shift'
import { mdParser } from '~/helpers/mdParser'
import { useCourseRoute } from '~/hooks/use-course-route'
import { useInput } from '~/hooks/use-input'
import { useSwitch } from '~/hooks/use-switch'
import { courseUsersMetaStore } from '~/store'
import { IMessageGroup as MessageGroupType, LessonType, IMessage, IMessageGroup, Chapter } from '~/types/course'

import { Box, Paper, Dialog, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'

import { EditUserHistoryModal } from './edit-user-history-modal'

interface ChapterEditProps {
  selectedLesson: LessonType
  selectedChapter: Chapter
  chapters: Chapter[] | null | undefined
  onUpdateLesson: (lesson: LessonType) => void
}

const ChapterEdit: FunctionComponent<ChapterEditProps> = observer(props => {
  const { chapters, selectedChapter, onUpdateLesson, selectedLesson } = props
  const [isModalIsOpen, setModalIsOpen] = useState(false)
  const [groupId, setGroupToEdit] = useState<string | undefined>()
  const [messages, setMessages] = useState<MessageGroupType[]>([])
  const { id: courseId } = useCourseRoute()
  const isUsersMetaFetched = toJS(courseUsersMetaStore.isUsersMetaFetched)
  const [isUserHistoryModalOpen, { toggle: toggleHistoryModal }] = useSwitch(false)

  useEffect(() => {
    if (isUsersMetaFetched) return
    courseUsersMetaStore.fetchUsersMeta(courseId ?? '')
  }, [courseId, isUsersMetaFetched])

  useEffect(() => {
    setMessages(selectedLesson?.messages ?? [])
  }, [selectedLesson?.messages])

  const handleCreateGroup = useCallback(
    (index?: number) => {
      const newGroup: MessageGroupType = {
        id: getUniqueId(),
        type: 'MESSAGES_GROUP',
        messages: [],
      }

      if (isNumber(index)) {
        messages?.splice(index, 0, newGroup)
        const updatedLesson: LessonType = { ...selectedLesson, messages }

        onUpdateLesson(updatedLesson)
        return
      }

      const updatedLesson: LessonType = { ...selectedLesson, messages: [...(selectedLesson.messages || []), newGroup] }
      onUpdateLesson(updatedLesson)
    },
    [messages, onUpdateLesson, selectedLesson],
  )

  const handleMoveGroup = useCallback(
    (groupId: string | number, direction: Direction) => {
      const index = messages?.findIndex(message => message.id === groupId)
      const totalMessages = messages.length - 1

      const isFirstMessage = direction === Direction.UP && index === 0
      const isLastMessage = direction === Direction.DOWN && index === totalMessages

      if (isFirstMessage || isLastMessage) return

      const indexOfPreviousElement = index - 1
      const indexOfNextElement = index + 1

      const result =
        direction === Direction.UP
          ? insertAndShift(messages, index, indexOfPreviousElement)
          : insertAndShift(messages, index, indexOfNextElement)

      const updatedLesson: LessonType = { ...selectedLesson, messages: result }

      onUpdateLesson(updatedLesson)
    },
    [messages, onUpdateLesson, selectedLesson],
  )

  const handleCreateGroupWithMessage = useCallback(
    (message: IMessage, groupId: number | string) => {
      const newGroup: MessageGroupType = {
        id: getUniqueId(),
        type: 'MESSAGES_GROUP',
        messages: [message],
      }

      const groupIndex = messages?.findIndex(message => message.id === groupId)

      if (groupIndex === undefined) return

      const updateMessages = messages?.splice(groupIndex + 1, 0, newGroup) ?? []
      const updatedLesson: LessonType = { ...selectedLesson, messages: updateMessages }

      onUpdateLesson(updatedLesson)
    },
    [messages, onUpdateLesson, selectedLesson],
  )

  const handleMoveMessageBetweenGroups = useCallback(
    (message: IMessage, groupId: string | number, direction: Direction) => {
      const groupIndex = messages?.findIndex(message => message.id === groupId)

      if (groupIndex === undefined) return

      const isFirstMessage = direction === Direction.UP && groupIndex === 0
      const isLastMessage = direction === Direction.DOWN && (messages?.length ?? 0) - 1 === groupIndex

      if (isFirstMessage || isLastMessage) return

      const indexOfPreviousElement = groupIndex - 1
      const indexOfNextElement = groupIndex + 1

      const indexToMove = direction === Direction.UP ? indexOfPreviousElement : indexOfNextElement

      const updatedLesson: LessonType = {
        ...selectedLesson,
        messages: messages.map((group, index) => {
          if (index === indexToMove) return { ...group, messages: [...group.messages, message] }
          if (group.id === groupId) return { ...group, messages: group.messages.filter(msg => msg.id !== message.id) }
          return group
        }),
      }

      onUpdateLesson(updatedLesson)

      return true
    },
    [messages, onUpdateLesson, selectedLesson],
  )
  const { value: linkToGithubLesson, handleSetValue: handleSetLinkToGithubLesson } = useInput({ defaultValue: '' })

  const handleToggleModal = () => setModalIsOpen(prev => !prev)

  const handleUpdateGroup = useCallback(
    updatedGroup => {
      const updatedLesson: LessonType = {
        ...selectedLesson,
        messages: messages.map(group => (group.id === updatedGroup.id ? updatedGroup : group)),
      }
      onUpdateLesson(updatedLesson)
    },
    [onUpdateLesson, messages, selectedLesson],
  )

  const handleUpdateGroupFromGithub = useCallback(
    (messages: MessageGroupType[]) => {
      const updatedLesson: LessonType = {
        ...selectedLesson,
        messages,
      }
      onUpdateLesson(updatedLesson)
    },
    [onUpdateLesson, selectedLesson],
  )

  const handleDeleteGroup = useCallback(
    (id: string) => {
      // eslint-disable-next-line no-restricted-globals
      const isConfirmed = confirm('Are you sure you want to delete message group?')
      if (!isConfirmed) return

      const updatedLesson: LessonType = { ...selectedLesson, messages: messages.filter(group => group.id !== id) }
      onUpdateLesson(updatedLesson)
    },
    [onUpdateLesson, messages, selectedLesson],
  )

  const handleSyncClick = async () => {
    let isConfirmed = true
    if (messages?.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      isConfirmed = confirm('Are you sure you want to sync? Some data may be lost.')
    }

    if (!isConfirmed) return
    let messagesFromGithub: IMessageGroup[] = []
    const file: any = (await GithubApi.getDataByLink(linkToGithubLesson, true)) ?? ''
    if (file && file?.content) {
      messagesFromGithub = mdParser(file.content)
    }

    setMessages(messagesFromGithub)
    handleUpdateGroupFromGithub(messagesFromGithub)

    handleToggleModal()
  }

  return (
    <>
      <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{selectedLesson.name}</Typography>
        <CustomButton variant="contained" onClick={handleToggleModal} color="blue" style={{ height: 'fit-content' }}>
          <Typography>Import lesson from github</Typography>
        </CustomButton>
      </Box>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Paper elevation={4} style={{ padding: 20, width: '100%', marginRight: 20 }}>
          {messages.map((messageGroup, ind) => (
            <Box key={messageGroup.id} mb={2}>
              <MessageGroup
                goals={selectedLesson.goals}
                group={messageGroup}
                groupNumber={ind}
                onDeleteGroup={() => handleDeleteGroup(messageGroup.id)}
                onUpdateGroup={handleUpdateGroup}
                onCreateGroupBetweenGroups={handleCreateGroup}
                onCreateGroupWithMessage={handleCreateGroupWithMessage}
                onMoveMessageBetweenGroups={handleMoveMessageBetweenGroups}
                onMoveGroup={handleMoveGroup}
                onToggleUserHistoryEditor={() => {
                  setGroupToEdit(messageGroup.id)
                  toggleHistoryModal()
                }}
              />
            </Box>
          ))}
          <CustomButton
            onClick={() => handleCreateGroup()}
            variant="contained"
            color="blue"
            style={{ marginBottom: 10 }}
          >
            <Typography>Create Group</Typography>
          </CustomButton>
        </Paper>
        <Goals lesson={selectedLesson} onSaveGoal={onUpdateLesson} />
      </Box>
      <Dialog onClose={handleToggleModal} aria-labelledby="simple-dialog-title" fullWidth open={isModalIsOpen}>
        <Box m={2}>
          <TextField
            value={linkToGithubLesson}
            onChange={handleSetLinkToGithubLesson}
            fullWidth
            label="Github Lesson link"
            variant="outlined"
          />
          <Box mt={1}>
            <CustomButton onClick={handleSyncClick} variant="contained" color="green">
              <Typography>Sync</Typography>
            </CustomButton>
          </Box>
        </Box>
      </Dialog>
      <EditUserHistoryModal
        courseId={courseId}
        chapterId={selectedChapter?.id}
        lessonId={selectedLesson.id}
        chapters={chapters}
        groupId={groupId}
        lessons={selectedChapter?.lessons}
        onToggleModal={() => {
          setGroupToEdit(undefined)
          toggleHistoryModal()
        }}
        isModalOpen={!!groupId ? isUserHistoryModalOpen : false}
      />
    </>
  )
})

export default ChapterEdit
