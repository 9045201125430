import React, { useCallback, useEffect, useState } from 'react'
import { AchievementsApi, AchievementSeriesType } from '~/api/achievements'
import { DefaultLayout } from '~/layouts/default-layout'

import { Button, Container, InputLabel, styled, TextField, Typography } from '@mui/material'

import TableAchievements from './list'

export const Achievements = () => {
  const [newAchievement, setNewAchievement] = useState('')
  const [achievementSeries, setAchievementSeries] = useState<AchievementSeriesType[]>([])

  const fetchAchievementSeries = useCallback(async () => {
    const achievementSeriesFetched = await AchievementsApi.getAchievementSeries()
    setAchievementSeries(achievementSeriesFetched)
  }, [])

  const addNewAchievement = async () => {
    await AchievementsApi.createAchievement(newAchievement)
    setNewAchievement('')
    fetchAchievementSeries()
  }

  useEffect(() => {
    fetchAchievementSeries()
  }, [fetchAchievementSeries])

  return (
    <DefaultLayout>
      <Container maxWidth="md">
        <Typography>Achievements</Typography>
        <TableAchievements data={achievementSeries} />
        <InputLabel>Add new achievement</InputLabel>
        <TextField value={newAchievement} onChange={e => setNewAchievement(e.target.value)} />
        <AddButton onClick={() => addNewAchievement()}>Add new achievement</AddButton>
      </Container>
    </DefaultLayout>
  )
}

const AddButton = styled(Button)({
  marginTop: 10,
  backgroundColor: '#e6e6fa',
  borderRadius: 8, // Adjust the value for more or less rounding
  padding: '10px 20px', // Optional: Adjust padding for a better rectangular shape
})
