import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { PrivateRoute } from '~/components/private-route'
import { UserRole } from '~/domain/user'
import { Achievements } from '~/views/achievements'
import { AuthPage } from '~/views/auth/auth'
import { CourseAnalyticsPage } from '~/views/courses/analytics/analytics'
import { CourseList } from '~/views/courses/course-list'
import { CoursePage } from '~/views/courses/course-page'
import { CreateCourse } from '~/views/courses/create/create'
import { DropletsPage } from '~/views/courses/droplet/droplets'
import { CourseEditPage } from '~/views/courses/edit/course-edit'
import { EditCourseOptions } from '~/views/courses/edit/course-options-edit'
import { PaymentLinksPage } from '~/views/courses/payment-links/payment-links'
import { PaymentsPage } from '~/views/courses/payments/payments'
import { UserDropletsPage } from '~/views/courses/user-droplets/droplets'
import { Skills } from '~/views/skills'
import { Specialization } from '~/views/specialization'
import { Tasks } from '~/views/task'
import { CourseUsersMetaPage } from '~/views/usersMeta/course-users-meta-page'

const ScrollToTop = withRouter(({ history }: any) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return null
})

const adminRole = UserRole.admin
const editorRole = UserRole.editor
const studentRole = UserRole.student

export const Routes = () => (
  <Router basename={process.env.REACT_APP_PUBLIC_URL_PATHNAME}>
    <ScrollToTop />
    <Switch>
      <PrivateRoute path="/" roles={[adminRole, editorRole, studentRole]} exact component={CourseList} />
      <Route path="/login" exact component={AuthPage} />
      <PrivateRoute path="/create" roles={[adminRole, editorRole, studentRole]} component={CreateCourse} />
      <PrivateRoute path="/edit/:id" roles={[adminRole, editorRole, studentRole]} component={EditCourseOptions} />
      <PrivateRoute
        path="/courses/:id/course"
        exact
        roles={[adminRole, editorRole, studentRole]}
        component={CoursePage}
      />
      <PrivateRoute
        path="/courses/:id/course/edit"
        exact
        roles={[adminRole, editorRole, studentRole]}
        component={CourseEditPage}
      />
      <PrivateRoute path="/courses/:id/droplets" exact roles={[adminRole]} component={DropletsPage} />
      <PrivateRoute path="/courses/:id/user-droplets" exact roles={[adminRole]} component={UserDropletsPage} />
      <PrivateRoute
        path="/courses/:id/analytics"
        exact
        roles={[adminRole, editorRole, studentRole]}
        component={CourseAnalyticsPage}
      />
      <PrivateRoute
        path="/courses/:id/users-meta"
        exact
        roles={[adminRole, editorRole, studentRole]}
        component={CourseUsersMetaPage}
      />
      <PrivateRoute path="/courses/:id/links" exact roles={[adminRole]} component={PaymentLinksPage} />
      <PrivateRoute path="/courses/:id/payments" exact roles={[adminRole]} component={PaymentsPage} />
      <PrivateRoute path="/skills" roles={[adminRole]} exact component={Skills} />
      <PrivateRoute path="/specialization" roles={[adminRole]} exact component={Specialization} />
      <PrivateRoute path="/code-tasks" roles={[adminRole, editorRole, studentRole]} exact component={Tasks} />
      <PrivateRoute path="/achievements" roles={[adminRole]} exact component={Achievements} />
      <Redirect to="/" />
    </Switch>
  </Router>
)
