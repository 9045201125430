import { makeAutoObservable, flow, toJS } from 'mobx'
import { UsersMetaApi } from '~/api/firebase/usersMeta'
import { UserMeta, CourseUsersMetaType } from '~/domain/usersMeta'

class CourseUsersMetaStore {
  courseUsersMeta: CourseUsersMetaType[] = []
  isFetchingUsersMeta = false
  isUsersMetaFetched = false
  isCreatingUserMeta = false
  isUpdatingUserMeta = false

  constructor() {
    makeAutoObservable(this)
  }

  get usersMetaForOptions() {
    if (!this.isUsersMetaFetched) {
      return []
    }

    return this.courseUsersMeta.map(userMeta => ({
      text: userMeta.firstName + ' ' + userMeta.lastName,
      value: userMeta.id,
    }))
  }

  fetchUsersMeta = flow(function* (this: CourseUsersMetaStore, courseId: string) {
    this.isFetchingUsersMeta = true
    try {
      this.courseUsersMeta = yield UsersMetaApi.getUsersMeta(courseId)
      this.isUsersMetaFetched = true
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isFetchingUsersMeta = false
    }
  })

  createUserMeta = flow(function* (this: CourseUsersMetaStore, userMetaRawData: CourseUsersMetaType) {
    this.isCreatingUserMeta = true
    try {
      const userMeta = UserMeta.toRaw(userMetaRawData)
      yield UsersMetaApi.createOrUpdateUserMeta(userMeta)
      this.courseUsersMeta = [...toJS(this.courseUsersMeta), { ...userMetaRawData }]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isCreatingUserMeta = false
    }
  })

  updateUserMeta = flow(function* (this: CourseUsersMetaStore, updatedUserMetaRawData: CourseUsersMetaType) {
    this.isUpdatingUserMeta = true
    try {
      const updatedUserMeta = UserMeta.toRaw(updatedUserMetaRawData)
      yield UsersMetaApi.createOrUpdateUserMeta(updatedUserMeta)

      const usersMetaWithoutUpdatedUser = toJS(this.courseUsersMeta).filter(
        userMeta => userMeta.id !== updatedUserMetaRawData.id,
      )
      this.courseUsersMeta = [...usersMetaWithoutUpdatedUser, updatedUserMetaRawData]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdatingUserMeta = false
    }
  })
}

export { CourseUsersMetaStore }
