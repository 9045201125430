import { CourseDroplet, CourseDropletType } from '~/domain/course-droplet'
import { dbProduction } from '~/infra/firebase/firebase'
import { getCollectionRef, getQuerySnapshot, getQuerySnapshotWithLimit } from '~/infra/firebase/firestore'

import { Firestore, where } from '@firebase/firestore'

const COLLECTION_KEY = 'course-droplets'

const getDropletsCollection = (id?: string, db?: Firestore) =>
  getCollectionRef(COLLECTION_KEY, {
    id,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => CourseDroplet.toRaw(snapshot.data()),
      toFirestore: (droplet: CourseDroplet) => CourseDroplet.fromRaw(droplet),
    },
  })

const fetchDroplet = async (id: string) => {
  const droplet = await getDropletsCollection(id).get()

  return droplet.exists() ? droplet.data() : null
}

const createOrUpdateDropletHandler = async (droplet: CourseDroplet, db?: Firestore) => {
  const dropletDoc = getDropletsCollection(droplet.id, db)
  await dropletDoc.set(droplet)

  return droplet
}

const createOrUpdateDroplet = async (droplet: CourseDroplet) => await createOrUpdateDropletHandler(droplet)

const createOrUpdateDropletInProductionDB = async (droplet: CourseDroplet) =>
  await createOrUpdateDropletHandler(droplet, dbProduction)

const getDroplets = async (courseId: string) => {
  const dropletDoc = await getQuerySnapshot(COLLECTION_KEY, where('courseId', '==', courseId)).get()

  return dropletDoc.docs.map(doc => CourseDroplet.fromRaw({ ...doc.data(), id: doc.id }))
}

const getDropletsWithLimit = async (limit: number, courseId: string, lastDroplet?: CourseDropletType) => {
  const snapshot = lastDroplet ? await getDropletsCollection(lastDroplet.id).get() : null

  const dropletDoc = await getQuerySnapshotWithLimit(
    COLLECTION_KEY,
    'id',
    limit,
    snapshot,
    where('courseId', '==', courseId),
  ).get()

  return dropletDoc.docs.map(doc => CourseDroplet.fromRaw({ ...doc.data(), id: doc.id }))
}

export const CourseDropletsApi = {
  fetchDroplet,
  createOrUpdateDroplet,
  getDroplets,
  getDropletsWithLimit,
  /**
   * production
   */
  createOrUpdateDropletInProductionDB,
}
