import { getUniqueId } from '~/helpers/id'
import { PriceType } from '~/types/course'

export type PartialPaymentConfigType = {
  id?: string
  courseId: string
  paymentCount: number
  pricePerPayment: PriceType
  modulesPerPayment: number
  createdAt?: number
  updatedAt?: number
}
export class PartialPaymentConfig {
  id: string
  courseId: string
  paymentCount: number
  pricePerPayment: PriceType
  modulesPerPayment: number
  createdAt?: number
  updatedAt?: number

  constructor(chat: PartialPaymentConfigType) {
    const createdAt = Date.now()

    this.id = chat.id ?? getUniqueId()
    this.courseId = chat.courseId
    this.paymentCount = chat.paymentCount
    this.pricePerPayment = chat.pricePerPayment
    this.modulesPerPayment = chat.modulesPerPayment
    this.createdAt = chat.createdAt ?? createdAt
    this.updatedAt = createdAt
  }

  static toRaw(item: PartialPaymentConfigType) {
    return new PartialPaymentConfig(item)
  }

  static fromRaw(item: PartialPaymentConfig): PartialPaymentConfigType {
    return Object.assign({}, item)
  }
}
