import { PartialPaymentConfig, PartialPaymentConfigType } from '~/domain/partial-payment-config'
import { getCollectionRef } from '~/infra/firebase/firestore'

const COLLECTION_KEY = 'partial-payment-config'

const getCollection = (id?: string) =>
  getCollectionRef(COLLECTION_KEY, {
    id,
    converter: {
      fromFirestore: (snapshot: any) => PartialPaymentConfig.toRaw(snapshot.data()),
      toFirestore: (data: PartialPaymentConfig) => PartialPaymentConfig.fromRaw(data),
    },
  })

const fetchPaymentConfig = async (id: string): Promise<PartialPaymentConfigType | null> => {
  const document = await getCollection(id).get()

  if (document.exists()) {
    return PartialPaymentConfig.fromRaw(document.data() as PartialPaymentConfig)
  }

  return null
}

const createConfig = async (data: PartialPaymentConfigType) => {
  const config = PartialPaymentConfig.fromRaw(new PartialPaymentConfig(data))
  const document = getCollection(config.id)
  await document.set(config)

  return config
}

export const PartialPaymentConfigApi = {
  fetchPaymentConfig,
  createConfig,
}
