import { useQuery } from 'react-query'
import { CourseApi } from '~/api/firebase/course'
import { Course } from '~/domain/course'

export const useCourseQuery = ({
  courseId,
  method,
  key = '',
}: {
  courseId: string
  method: (courseId?: string) => Promise<Course | null>
  key?: string
}) => {
  const {
    data: fetchedCourse,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useQuery(`course-id-${courseId}${key}`, async () => await method(courseId || ''), {
    enabled: !!courseId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    fetchedCourse,
    isLoading,
    isFetching,
    refetch,
    remove,
  }
}

type CoursesQueryProps = {
  onSuccess?: (data: Course[]) => void
  enabled?: boolean
}

export const useCoursesQuery = ({ onSuccess, enabled }: CoursesQueryProps) => {
  const {
    data: fetchedCourses,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useQuery('courses', async () => await CourseApi.getAllCoursesWithContent(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled,
    onSuccess,
  })

  return {
    fetchedCourses,
    isLoading,
    isFetching,
    refetch,
    remove,
  }
}
