import { UserHistoryItemType } from '~/domain/user-histories'
import { Chapter } from '~/types/course'

type Props = {
  chapterId?: string
  lessonId?: string
  groupId?: string
  chapters?: Chapter[] | null | undefined
}

export const computeHistories = ({
  lessonId,
  chapterId,
  chapters,
  groupId,
}: Props): UserHistoryItemType[] | undefined => {
  const items = chapters?.map(chapter => {
    return chapter?.lessons?.map((lesson, index) => {
      const lastMessageGroup = (lesson?.messages?.length && lesson?.messages?.[lesson?.messages?.length - 1]) || {
        id: '',
      }
      const updatedAt = Date.now()

      return {
        chapterId: chapter.id,
        lessonId: lesson.id,
        lastMessageId: chapterId === chapter.id && lessonId === lesson.id ? groupId : lastMessageGroup?.id,
        createdAt: updatedAt + index,
        updatedAt: updatedAt + index + 1,
      }
    })
  })

  if (!items) return undefined

  const filteredItems = items?.flat()?.filter(item => !!item) as UserHistoryItemType[]

  const currentHistoryIndex = filteredItems?.findIndex(item => item?.lessonId === lessonId)

  const histories = filteredItems?.filter((_, index) => currentHistoryIndex >= index)

  return histories
}
