import { useCallback } from 'react'
import { Course } from '~/domain/course'

export const getBumpedCourseVersion = (fetchedCourseVersion?: string) => {
  const splitedVersion = fetchedCourseVersion?.split('.')

  if (splitedVersion?.length) {
    const minorVersion = splitedVersion.pop()
    const newMinorVersion = minorVersion && !isNaN(+minorVersion) ? +minorVersion + 1 : 1
    splitedVersion.push(newMinorVersion?.toString())

    const newFullVersion = splitedVersion.join('.')

    return newFullVersion
  }

  return undefined
}

export const useCourseVersion = (course?: Course | null) => {
  const courseVersion = course?.version

  const getBumpedCourseVersionHandler = useCallback(() => getBumpedCourseVersion(courseVersion), [courseVersion])

  return {
    getBumpedCourseVersionHandler,
    courseVersion,
  }
}
