import { makeAutoObservable, flow, toJS } from 'mobx'
import { DropletsApi } from '~/api/droplet'
import { UserDropletsApi } from '~/api/firebase/user-droplets'
import { UserDropletStatus, UserDropletType } from '~/domain/user-droplet'

class UserDropletsStore {
  userDroplets: UserDropletType[] = []
  isFetchingDroplets = false
  isDropletsFetched = false
  isCreatingDroplet = false
  isUpdatingDroplet = false

  constructor() {
    makeAutoObservable(this)
  }

  fetchDroplets = flow(function* (this: UserDropletsStore, courseId: string, status?: UserDropletStatus) {
    this.isFetchingDroplets = true
    try {
      this.userDroplets = yield UserDropletsApi.getDroplets(courseId, status)
      this.isDropletsFetched = true
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isFetchingDroplets = false
    }
  })

  deleteDroplet = flow(function* (this: UserDropletsStore, id: string) {
    this.isUpdatingDroplet = true
    try {
      const updatedDroplets = toJS(this.userDroplets).map(droplet => {
        if (droplet.id === id) {
          return {
            ...droplet,
            status: UserDropletStatus.deleted,
          }
        }

        return droplet
      })

      this.userDroplets = [...updatedDroplets]
      const { dropletId } = updatedDroplets?.find(droplet => droplet.id === id) ?? {}

      if (dropletId) {
        yield DropletsApi.deleteDroplet(dropletId)
      }
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdatingDroplet = false
    }
  })
}

export { UserDropletsStore }
