import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { Task, TaskType } from '~/domain/task'
import { TaskModal } from '~/modals/task-modal'
import { taskStore } from '~/store'

import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type Column = {
  id: 'id' | 'title' | 'description' | 'difficulty' | 'language'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
  },
  { id: 'title', label: 'Title', minWidth: 100 },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'difficulty',
    label: 'Difficulty',
  },
  {
    id: 'language',
    label: 'Language',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '80vh',
    },
  }),
)

export const TableTasks = observer(() => {
  const classes = useStyles()
  const [taskModalIsVisible, setTaskModalIsVisible] = useState(false)
  const [task, setTask] = useState<TaskType>()

  const tasks = toJS(taskStore.tasks)
  const handleUpdateTask = useCallback((updatedTask: TaskType) => {
    const task = new Task(updatedTask)

    taskStore.updateTask(task)
  }, [])

  const handleCloseTaskModal = () => {
    setTaskModalIsVisible(false)
  }

  const handleEditTaskButtonClick = useCallback((task: TaskType) => {
    setTaskModalIsVisible(true)
    setTask(task)
  }, [])

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={'edit'} style={{ minWidth: 50 }}>
                  Edit
                </TableCell>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks?.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key={row.id} style={{ minWidth: 100 }}>
                      <IconButton edge="end" onClick={() => handleEditTaskButtonClick(row)} size="large">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    {columns.map(column => {
                      const value = row[column.id]
                      const isArray = Array.isArray(value)
                      // @ts-ignore
                      const parsedValue = isArray ? (value as string[])?.map(item => item).join(', ') : value

                      if (typeof parsedValue === 'boolean') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {parsedValue ? 'Yes' : 'No'}
                          </TableCell>
                        )
                      }

                      if (column.format && typeof value === 'number') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format(value)}
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {parsedValue}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {taskModalIsVisible && (
        <TaskModal onSave={handleUpdateTask} onClose={handleCloseTaskModal} isOpen={taskModalIsVisible} task={task} />
      )}
    </>
  )
})
