/**
 * Function to count special blank characters in text
 * @param markdownText markdown text with special blank symbols
 * @returns total number of special blank characters
 */
export function countBlanks(markdownText: string | null | undefined) {
  if (markdownText === null || markdownText === undefined) return 0

  return markdownText.match(/∅/gi)?.length ?? 0
}
