export const safetyParseJson = (jsonScheme?: string | null) => {
  try {
    const json = jsonScheme && JSON.parse(jsonScheme || '')

    return json
  } catch (error) {
    console.error(error)

    return undefined
  }
}
