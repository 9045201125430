import { GITHUB_TOKEN } from '~/config/env'
import { convertGithubUrlToApiUrl } from '~/helpers/convert-github-url-api-url'

const githubConfig = {
  authToken: GITHUB_TOKEN,
}

const getFile = async (url: string) => {
  const parsedUrl = convertGithubUrlToApiUrl(url)

  const result = await fetch(parsedUrl).then(data => data.json())

  if (typeof result?.content !== 'string') return

  // преобразует строку из base64 в обычную. Плюс, поддерживает кириллицу
  const decodeContentFromBase64 = decodeURIComponent(escape(atob(result?.content)))

  return decodeContentFromBase64
}

/**
 * Функция для получения содержимого всех файлов (или файла) по url
 * Можно передать в функцию ссылку на приватный репозиторий, директорию в приватном репозитории, или на файл
 * @param url
 * @return массив элементов (или один элемент) вида: {name: 'path-to-file', content: 'контент файла'}
 */
const getDataByLink = async (url: string, onlyFilePath?: boolean) => {
  const splitUrl = url.split('/')

  // TODO: добавить нормальную проверку на валидность URL
  if (splitUrl.length <= 1) return

  const githubName = splitUrl[3]
  const repoName = splitUrl[4]
  if (url.includes('blob')) {
    // если попали сюда, значит была вставлена ссылка на файл
    // начинаем вытаскивать путь до файла из урла
    // разбиваем по /blob/, нас интересует второй кусок ссылки после /blob/
    const pathToFileWithBranchName = url.split('/blob/')[1] // main/README.md
    // далее нужно удалить название ветки (файлы тянем с мейн ветки), т.е. все что до первого слеша
    // находим индекс первого слеша и берем все до конца
    const pathToFile = pathToFileWithBranchName.slice(
      pathToFileWithBranchName.indexOf('/'),
      pathToFileWithBranchName.length,
    )
    const content = await getContentByUrl(githubName, repoName, pathToFile)
    return {
      name: pathToFile,
      content,
    }
  } else {
    // если нам нельзя тянуть файлы рекурсивно, то ничего делать не будем
    if (onlyFilePath) {
      return undefined
    }
    // если попали сюда, значит ссылка на репозиторий/директорию
    let directoryPath = ''
    if (url.includes('tree')) {
      directoryPath = url.split('main/')[1]
    }

    const values: any[] = []
    await getRecoursiveTrees({ githubName, repoName, directoryPath, store: values, relativePath: directoryPath })
    return values
  }
}

type RecursiveTreeType = {
  githubName: string
  repoName: string
  directoryPath: string
  store: any[]
  relativePath?: string
}

/**
 * Получить рекурсивно все файлы, которые лежат в выбранной директории
 * @param githubName
 * @param repoName
 * @param directoryPath
 * @param store
 */
const getRecoursiveTrees = async ({ githubName, repoName, directoryPath, store, relativePath }: RecursiveTreeType) => {
  const fetchedData = await fetch(`https://api.github.com/repos/${githubName}/${repoName}/contents/${directoryPath}`, {
    headers: {
      Authorization: `token ${githubConfig.authToken}`,
      Accept: 'application/vnd.github.v3+json',
    },
  })

  async function processArray(array: any) {
    for (const item of array) {
      if (item.type === 'dir') {
        const { path } = item
        await getRecoursiveTrees({ githubName, repoName, directoryPath: path, store, relativePath })
      } else {
        const result = await getContentByUrl(githubName, repoName, item.path)

        const path = removeRelativePath(decodeURIComponent(item.path || ''), decodeURIComponent(relativePath || ''))
        store.push({ name: path, content: result })
      }
    }
  }

  const fetchedItems = await fetchedData.json()

  await processArray(fetchedItems)
}

const removeRelativePath = (path: string, relativePath?: string) => {
  const relativePathLength = relativePath?.length ? relativePath.length + 1 : 0

  return path.slice(relativePathLength)
}

/**
 * Получить файл по пути относительно корня репозитория
 * @param githubName - имя аккаунта на гитхабе
 * @param repoName - название репозитория
 * @param pathToFile - путь для файла
 */
const getContentByUrl = async (githubName: string, repoName: string, pathToFile: string) => {
  const dataByLink = await fetch(`https://api.github.com/repos/${githubName}/${repoName}/contents${pathToFile}`, {
    headers: {
      Authorization: `token ${githubConfig.authToken}`,
      Accept: 'application/vnd.github.v3+json',
    },
  }).then(r => r.json())
  const decodeContentFromBase64 = decodeURIComponent(escape(atob(dataByLink?.content)))
  return decodeContentFromBase64
}

export const GithubApi = {
  getDataByLink,
  getFile,
}
