import { cleanEmptyProps } from '../helpers'
import { pointers } from '../pointers'
import { LessonVariablesType } from '../types'

export const getLessonVariables = (text: string) => {
  const start = text.indexOf(pointers.variables.start)
  const end = text.indexOf(pointers.variables.end)

  if (start === -1 || end === -1) return {}

  const keyAndValueStringsArr = text
    .slice(start + pointers.variables.start.length, end)
    .split(pointers.variables.divider)

  const variables: LessonVariablesType = {}

  keyAndValueStringsArr.forEach(item => {
    const [key, value] = item.split(pointers.variables.equalSign)

    const cleanKey = key.trim()

    variables[cleanKey] = value
  })

  return cleanEmptyProps(variables)
}
