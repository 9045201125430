import { useCallback, useState } from 'react'
import { CourseApi } from '~/api/firebase/course'
import { COURSES_KEY } from '~/constants/storage-keys'
import { UserRole } from '~/domain/user'
import { safetyParseJson } from '~/helpers/json'
import { Storage } from '~/services/storage'
import { userStore } from '~/store'
import { Course } from '~/types/course'

import { useCoursesQuery } from './use-course-query'

export const useCourses = () => {
  const [courses, setCourses] = useState<Course[]>([])
  const [course, setCourse] = useState<Course | undefined>(undefined)
  const { currentUser } = userStore

  const getCourses = useCallback(() => {
    const coursesInJSON = Storage.get(COURSES_KEY)
    const courses = safetyParseJson(coursesInJSON)

    setCourses(courses || [])
    return (courses || []) as Course[]
  }, [])

  const getCourse = useCallback(id => {
    const courses = Storage.get(COURSES_KEY)
    const res = safetyParseJson(courses)
    const course = res.find((course: any) => course.id === id)

    setCourse(course)

    return course
  }, [])

  const handleCreateCourse = useCallback(
    (payload: Course) => {
      const list = [...courses, payload]

      Storage.set(COURSES_KEY, list)

      setCourses(list)
    },
    [courses],
  )

  const handleCreateCourses = useCallback(
    (payload: Course[]) => {
      const courses = getCourses()

      const list = [...courses, ...payload]

      Storage.set(COURSES_KEY, list)

      setCourses(list)
    },
    [getCourses],
  )

  // TODO: Необходимо избавиться от копий всех курсов, переделать структуру на хранение в
  // TODO: localStorage только тех, что нет в БД или которые правились локально
  /**
   * Вытаскиваем сразу запросом внутри useQuery курсы из бд и делаем их копии
   */
  const { refetch } = useCoursesQuery({
    enabled: true,
    onSuccess: data => {
      if (!data?.length) return

      // Filter courses based on user permissions
      const filteredData = data.filter(course => {
        const isAdmin = currentUser?.roles?.includes(UserRole.admin)
        const isCreator = course.creatorId === currentUser?.id
        return isAdmin || isCreator
      })

      const courses = getCourses()
      const newCourses: Course[] = []

      for (let course of filteredData) {
        const isDbCourseInStorage = !!courses?.find(localCourse => localCourse.id === course.id)

        if (!isDbCourseInStorage) {
          newCourses.push(course)
        }
      }

      if (newCourses?.length) {
        handleCreateCourses(newCourses)
      }
    },
  })

  const handleUpdateCourse = useCallback(
    async (payload: Course) => {
      const courseToUpdate = courses.find(({ id }) => payload.id === id)

      if (!courseToUpdate) {
        console.error('There is no quest with given id')

        return
      }

      if (courseToUpdate.id === course?.id) {
        setCourse(payload)
      }
      const totalPoints = await CourseApi.calculatePointsForCourse(payload)
      payload.totalPoints = totalPoints
      console.log('payload', payload)

      const list = courses.map(course => (course.id === payload.id ? { ...course, ...payload } : course))

      /**
       * Чтобы подгрузить актуальный курс, делаем refetch запрос и обновляем список курсов
       */
      refetch()

      Storage.set(COURSES_KEY, list)

      setCourses(list)
    },
    [course?.id, courses, refetch],
  )

  const handleRemoveCourse = useCallback(
    id => {
      // eslint-disable-next-line no-restricted-globals
      const isConfirmed = confirm(
        'Are you sure you want to delete the quest? It could be restored only with JSON or Firebase',
      )
      if (!isConfirmed) return

      setCourses(state => {
        const courses = state.filter(course => course.id !== id)

        Storage.set(COURSES_KEY, courses)

        return courses
      })

      if (id === course?.id) {
        setCourse(undefined)
      }

      refetch()
    },
    [course?.id, refetch],
  )

  return {
    courses,
    course,
    getCourses,
    getCourse,
    handleUpdateCourse,
    handleCreateCourse,
    handleRemoveCourse,
  }
}
