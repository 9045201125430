import { UserHistory } from '~/domain/user-histories'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'

import { where } from '@firebase/firestore'

const COLLECTION_KEY = 'user-histories'

const getCollection = (id?: string) =>
  getCollectionRef(COLLECTION_KEY, {
    id,
    converter: {
      fromFirestore: (userSnapshot: any) => UserHistory.toRaw(userSnapshot.data()),
      toFirestore: (item: UserHistory) => UserHistory.fromRaw(item),
    },
  })

const createOrUpdateUserHistory = async (progress: UserHistory): Promise<UserHistory> => {
  const { id } = progress
  const UserHistoryRef = await getCollection(id)
  const newObj = { ...progress, updatedAt: Date.now() }
  await UserHistoryRef.set(newObj)

  return newObj
}

const fetchUserHistory = async (userId: string, courseId: string) => {
  const doc = await getQuerySnapshot(
    COLLECTION_KEY,
    where('userId', '==', userId),
    where('courseId', '==', courseId),
  ).get()

  const item = doc?.docs?.[doc?.docs?.length - 1]?.data()
  return item ? UserHistory.fromRaw(item as UserHistory) : undefined
}

export const UserHistoryApi = {
  fetchUserHistory,
  createOrUpdateUserHistory,
}
