import dayjs from 'dayjs'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { UserDropletStatus } from '~/domain/user-droplet'
import { userDropletsStore } from '~/store'

import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type Column = {
  id:
    | 'id'
    | 'dropletId'
    | 'userId'
    | 'courseDropletId'
    | 'credentials'
    | 'status'
    | 'createdAt'
    | 'lastTouchedTimeStamp'
  label: string
  minWidth?: number
  maxWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 80,
  },
  { id: 'dropletId', label: 'Droplet Id', maxWidth: 80 },
  {
    id: 'userId',
    label: 'User Id',
    maxWidth: 80,
  },
  {
    id: 'courseDropletId',
    label: 'Course Droplet Id',
    maxWidth: 80,
  },
  {
    id: 'credentials',
    label: 'Credentials',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'createdAt',
    label: 'Created',
  },
  {
    id: 'lastTouchedTimeStamp',
    label: 'lastTouched',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '100vh',
    },
  }),
)

export const UserDropletsTable = observer(() => {
  const classes = useStyles()

  const userDroplets = toJS(userDropletsStore.userDroplets)

  const handleDeactive = (id: string) => {
    userDropletsStore.deleteDroplet(id)
  }

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key="deactive" style={{ minWidth: 50 }}>
                  Deactive
                </TableCell>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userDroplets?.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key={row.id}>
                      {row.status !== UserDropletStatus.deleted && (
                        <IconButton edge="end" onClick={() => row.id && handleDeactive(row.id)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    {columns.map(column => {
                      const value = row[column.id]

                      if (column.format && typeof value === 'number') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                          >
                            {column.format(value)}
                          </TableCell>
                        )
                      }

                      if (column.id === 'credentials') {
                        const { username, password } = row?.credentials ?? {}

                        const v4 = row?.networks?.v4
                        const ip = v4?.find(item => item.type === 'public')?.ipAddress

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                          >
                            {username}@{ip}, password: {password}
                          </TableCell>
                        )
                      }

                      if (column.id === 'createdAt') {
                        const createdAt = row?.createdAt

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                          >
                            {dayjs(createdAt).format('DD-MM-YYYY HH:mm:ss')}
                          </TableCell>
                        )
                      }

                      if (column.id === 'lastTouchedTimeStamp') {
                        const lastTouchedTimeStamp = row?.lastTouchedTimeStamp

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                          >
                            {dayjs(lastTouchedTimeStamp).format('DD-MM-YYYY HH:mm:ss')}
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
})
