export interface ITribeQuestsProps {
  tribeId: string
  quests: string[]
}

class TribeQuests {
  tribeId: string
  quests: string[]

  constructor(tribeQuestsProps: ITribeQuestsProps) {
    this.tribeId = tribeQuestsProps.tribeId
    this.quests = tribeQuestsProps.quests
  }

  static fromRaw(rawTribeQuests: any) {
    return new TribeQuests(rawTribeQuests)
  }

  static toRaw(tribeQuests: TribeQuests) {
    return Object.assign({}, tribeQuests)
  }
}

export { TribeQuests }
