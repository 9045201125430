import { useCallback, useState } from 'react'
import { COURSES_KEY } from '~/constants/storage-keys'
import { safetyParseJson } from '~/helpers/json'
import { Storage } from '~/services/storage'
import { Course } from '~/types/course'

export const useExport = () => {
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string>('')

  const getDownloadLink = useCallback((id: string) => {
    const courses = Storage.get(COURSES_KEY)
    const res = safetyParseJson(courses)
    const course = res.find((course: Course) => course.id === id)

    const output = JSON.stringify(course, null, 4)
    const blob = new Blob([output])
    const fileDownloadUrl = URL.createObjectURL(blob)

    setFileDownloadUrl(fileDownloadUrl)

    return fileDownloadUrl
  }, [])

  const resetDownloadLink = useCallback(() => {
    setFileDownloadUrl('')
  }, [])

  return {
    fileDownloadUrl,
    getDownloadLink,
    resetDownloadLink,
  }
}
