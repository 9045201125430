// src/components/CardComponent.js
import React from 'react'
import styled from 'styled-components'

import { Box } from '@mui/material'

const Card = styled(Box)`
  width: 100px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
`

const ImageContainer = styled(Box)`
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
`

const ImageWrapper = styled(Box)`
  position: absolute;
  inset: 8px;
  overflow: hidden;
  border-radius: 12px;
`

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;

  ${ImageWrapper}:hover & {
    transform: scale(1.05);
  }
`

const Overlay = styled(Box)`
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 20%, transparent 100%);
`

const Badge = styled(Box)`
  background: linear-gradient(to right, #f6b73c, #eb9e3a);
  color: white;
  border-radius: 50px;
  font-size: 10px;
  padding: 2px 8px;
  font-weight: 600;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
`

const CardFooter = styled(Box)`
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

type ProfileAchievementProps = {
  title: string
  img: string
  alt: string
}

export const ProfileAchievement = ({ title, img, alt }: ProfileAchievementProps) => {
  return (
    <Card>
      <ImageContainer>
        <ImageWrapper>
          <CardImage src={img} alt={alt} />
          <Overlay />
        </ImageWrapper>
      </ImageContainer>
      <CardFooter>
        <Badge>{title}</Badge>
      </CardFooter>
    </Card>
  )
}

export default ProfileAchievement
