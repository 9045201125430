import React, { useCallback, useState } from 'react'
import { CourseUsersMetaType, UserMeta } from '~/domain/usersMeta'
import { useCourseRoute } from '~/hooks/use-course-route'
import { UserMetaModal } from '~/modals/user-meta-modal'
import { courseUsersMetaStore } from '~/store'

import { Button } from '@mui/material'

export const AddNewUserMeta = () => {
  const { id: courseId } = useCourseRoute()

  const [userMetaModalIsVisible, setUserMetaModalIsVisible] = useState(false)

  const handleUpdateUserMeta = useCallback(
    (updatedUserMeta: CourseUsersMetaType) => {
      const userMeta = new UserMeta({ ...updatedUserMeta, courseId: courseId ?? '' })

      courseUsersMetaStore.createUserMeta(userMeta)
    },
    [courseId],
  )

  const handleOpenUserMetaModal = () => {
    setUserMetaModalIsVisible(true)
  }

  const handleCloseUserMetaModal = () => {
    setUserMetaModalIsVisible(false)
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenUserMetaModal}>
        Add user meta
      </Button>
      {userMetaModalIsVisible && (
        <UserMetaModal
          onSave={handleUpdateUserMeta}
          onClose={handleCloseUserMetaModal}
          isOpen={userMetaModalIsVisible}
        />
      )}
    </div>
  )
}
