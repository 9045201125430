import React, { useCallback, useEffect, useState } from 'react'
import { SpecializationApi } from '~/api/firebase/specializations'
import { Specialization, SpecializationType } from '~/domain/specializations'
import { SpecializationModal } from '~/modals/specialization-modal'

import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { parseSalary } from './helpers'

type Column = {
  id: 'id' | 'name' | 'salary' | 'isVisible'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
  },
  { id: 'name', label: 'Name', minWidth: 100 },
  {
    id: 'salary',
    label: 'Salary',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'isVisible',
    label: 'Visible',
    align: 'right',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '100vh',
    },
  }),
)

export const TableSpecializations = () => {
  const classes = useStyles()
  const [specialization, setSpecialization] = useState<SpecializationType | undefined>()
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const [specializations, setSpecializations] = useState<SpecializationType[]>([])

  useEffect(() => {
    const getAllSpecializations = async () => {
      const data = await SpecializationApi.getAllSpecializations()
      setSpecializations(data)
    }
    getAllSpecializations()
  }, [modalIsVisible])

  const handleEditSkillButtonClick = useCallback((specialization?: SpecializationType) => {
    setModalIsVisible(true)
    setSpecialization(specialization)
  }, [])

  const handleCloseSpecializationModal = () => {
    setModalIsVisible(false)
    setSpecialization(undefined)
  }

  const handleUpdateSpecialization = useCallback((updatedSpecialization: SpecializationType) => {
    const specialization = new Specialization(updatedSpecialization)
    SpecializationApi.createOrUpdateSpecialization(specialization)
  }, [])

  return (
    <>
      <Box py={2}>
        <Button variant="contained" onClick={() => handleEditSkillButtonClick()}>
          Create specialization
        </Button>
      </Box>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={'edit'} style={{ minWidth: 50 }}>
                  Edit
                </TableCell>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {specializations?.map(row => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell style={{ minWidth: 100 }}>
                    <IconButton edge="end" onClick={() => handleEditSkillButtonClick(row)} size="large">
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{parseSalary({ amount: row.salary.amount, currency: row.salary.currency })}</TableCell>
                  <TableCell align="center">{row.isVisible ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {modalIsVisible && (
        <SpecializationModal
          onSave={handleUpdateSpecialization}
          onClose={handleCloseSpecializationModal}
          isOpen={modalIsVisible}
          specialization={specialization}
        />
      )}
    </>
  )
}
