import { IMessage } from '~/types/course'

export const getMessagePropsToFields = (message?: IMessage) =>
  Object.entries(message || {})
    .filter(([key]) => !['type', 'content', 'id', 'nextButtonText'].includes(key))
    .map(([key, value]) => ({ key, value: setValueAndAddComma(value), canRemove: true, editableKey: true }))

export const getValueAndCheckComma = (value: string) => {
  if (typeof value !== 'string') {
    return value
  }

  if (value?.indexOf(',') > 0) {
    return value?.split(',')?.map(val => val?.trim())
  }

  return value?.trim()
}

export const setValueAndAddComma = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value?.join(',')?.trim()
  }

  return value && typeof value === 'string' ? value?.trim() : value
}
