import { ITribeQuestsProps, TribeQuests } from '~/domain/tribe-quests'
import { getCollectionRef } from '~/infra/firebase/firestore'

import { Firestore } from '@firebase/firestore'

const TRIBE_QUESTS_COLLECTION_KEY = 'tribe-quests'

const getTribeQuestsCollection = (tribeId?: string, db?: Firestore) =>
  getCollectionRef(TRIBE_QUESTS_COLLECTION_KEY, {
    id: tribeId,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => TribeQuests.fromRaw(snapshot.data()),
      toFirestore: (tribeQuests: TribeQuests) => TribeQuests.toRaw(tribeQuests),
    },
  })

const getTribeQuests = async (tribeId: string) => {
  const tribeQuests = await getTribeQuestsCollection(tribeId).get()
  return tribeQuests.exists() ? TribeQuests.fromRaw(tribeQuests.data() as ITribeQuestsProps) : null
}

const addTribeQuest = async (tribeId: string, questId: string) => {
  const tribeQuests = await getTribeQuests(tribeId)
  if (!tribeQuests) {
    await getTribeQuestsCollection(tribeId).set({ tribeId, quests: [questId] })
    return
  }
  await getTribeQuestsCollection(tribeId).set({ tribeId, quests: [...tribeQuests.quests, questId] })
}

const removeTribeQuest = async (tribeId: string, questId: string) => {
  const tribeQuests = await getTribeQuests(tribeId)
  if (!tribeQuests) return
  await getTribeQuestsCollection(tribeId).set({ tribeId, quests: tribeQuests.quests.filter(id => id !== questId) })
}

export const TribeApi = {
  getTribeQuests,
  addTribeQuest,
  removeTribeQuest,
}
