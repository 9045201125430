import { makeAutoObservable, flow, toJS } from 'mobx'
import { TaskApi } from '~/api/firebase/task'
import { TaskType, Task } from '~/domain/task'

class TaskStore {
  tasks: TaskType[] = []
  isFetchingDroplets = false
  isDropletsFetched = false
  isCreatingDroplet = false
  isUpdatingDroplet = false

  constructor() {
    makeAutoObservable(this)
  }

  fetchTasks = flow(function* (this: TaskStore) {
    this.isFetchingDroplets = true

    try {
      this.tasks = yield TaskApi.getAllAvailableTasks()
      this.isDropletsFetched = true
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isFetchingDroplets = false
    }
  })
  // TODO: Need to rename variables, droplets is wrong name!!!
  createTask = flow(function* (this: TaskStore, dropletRawData: TaskType) {
    this.isCreatingDroplet = true
    try {
      const droplet = Task.toRaw(dropletRawData)

      yield TaskApi.createOrUpdateTask(droplet)

      this.tasks = [...toJS(this.tasks), { ...dropletRawData }]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isCreatingDroplet = false
    }
  })

  updateTask = flow(function* (this: TaskStore, updatedDropletRawData: TaskType) {
    this.isUpdatingDroplet = true
    try {
      const updatedDroplet = Task.toRaw(updatedDropletRawData)

      yield TaskApi.createOrUpdateTask(updatedDroplet)

      const dropletsWithoutUpdatedDroplet = toJS(this.tasks).filter(droplet => droplet.id !== updatedDropletRawData.id)
      this.tasks = [...dropletsWithoutUpdatedDroplet, updatedDropletRawData]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdatingDroplet = false
    }
  })
}

export { TaskStore }
