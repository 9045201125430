import { getUniqueId } from '~/helpers/id'
import { getServerTimestamp, FieldValue, Timestamp } from '~/infra/firebase/firestore'

export enum DROPLET_IMAGES {
  'ubuntu-20-04-x64' = 'ubuntu-20-04-x64',
  'ubuntu-18-04-x64' = 'ubuntu-18-04-x64',
  'ubuntu-21-10-x64' = 'ubuntu-21-10-x64',
}

export enum DROPLET_SIZE {
  's-1vcpu-1gb' = 's-1vcpu-1gb',
  's-1vcpu-2gb' = 's-1vcpu-2gb',
  's-2vcpu-2gb' = 's-2vcpu-2gb',
  's-2vcpu-4gb' = 's-2vcpu-4gb',
  's-4vcpu-8gb' = 's-4vcpu-8gb',
  's-8vcpu-16gb' = 's-8vcpu-16gb',
}

export enum DROPLET_REGION {
  'nyc1' = 'nyc1', // New York
  'nyc3' = 'nyc3', // New York
  'sfo3' = 'sfo3', // San Francisco
  'ams3' = 'ams3', // Amsterdam
  'sgp1' = 'sgp1', // Singapore
  'lon1' = 'lon1', // London
  'fra1' = 'fra1', // Frankfurt
  'tor1' = 'tor1', // Toronto
  'blr1' = 'blr1', // Bangalore
}

type Credentials = {
  username: string
  password: string
}

export const dropletSizes = Object.values(DROPLET_SIZE).map(value => ({
  label: value,
  value,
}))

export const dropletImages = Object.values(DROPLET_IMAGES).map(value => ({
  label: value,
  value,
}))

export const dropletRegions = Object.values(DROPLET_REGION).map(value => ({
  label: value,
  value,
}))

export type CourseDropletType = {
  id?: string
  name: string
  region: DROPLET_REGION
  size: DROPLET_SIZE
  image: DROPLET_IMAGES
  tags?: string[] | null
  description?: string | null
  backups?: boolean
  ipv6?: boolean
  monitoring?: boolean
  credentials?: Credentials | null
  userData?: string
  vpcUuid?: string
  courseId: string
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp
}

export class CourseDroplet {
  id: string
  name: string
  region: DROPLET_REGION
  size: DROPLET_SIZE
  image: DROPLET_IMAGES
  tags?: string[] | null
  description?: string | null
  backups?: boolean
  ipv6?: boolean
  monitoring?: boolean
  credentials?: Credentials | null
  userData?: string
  vpcUuid?: string
  courseId: string
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp

  constructor(droplet: CourseDropletType) {
    const updatedAt = getServerTimestamp()

    this.id = droplet?.id ?? getUniqueId()
    this.name = droplet.name
    this.region = droplet.region
    this.size = droplet.size
    this.image = droplet.image
    this.tags = droplet?.tags ?? null
    this.description = droplet?.description ?? null
    this.credentials = droplet?.credentials ?? null
    this.backups = droplet.backups ?? false
    this.ipv6 = droplet.ipv6 ?? false
    this.monitoring = droplet.monitoring ?? false
    this.userData = droplet.userData ?? ''
    this.vpcUuid = droplet?.vpcUuid ?? ''
    this.courseId = droplet.courseId
    this.createdAt = droplet.createdAt ?? updatedAt
    this.updatedAt = updatedAt
  }

  static fromRaw(raw: any) {
    return Object.assign({}, raw)
  }

  static toRaw(droplet: CourseDropletType) {
    return new CourseDroplet(droplet)
  }
}
