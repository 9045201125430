import { observer } from 'mobx-react-lite'
import React from 'react'
import FirebaseAuth from 'react-firebaseui/FirebaseAuth'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { BackgroundContainer } from '~/common/background-container'
import { LoadingSpinner } from '~/common/loading-spinner'
import { getFirebaseAuth, SignInProviders } from '~/infra/firebase/auth'
import { userStore } from '~/store'

import { Box, Typography } from '@mui/material'

import facebookIconUrl from './facebook-signin-provider-icon.svg'

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: SignInProviders.GoogleAuthProvider,
      fullLabel: 'Sign In with Google',
    },
    {
      provider: SignInProviders.FacebookAuthProvider,
      fullLabel: 'Sign In with Facebook',
      iconUrl: facebookIconUrl,
    },
    {
      provider: SignInProviders.EmailAuthProvider,
      fullLabel: 'Sign In with email and password',
    },
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
}

const HOME_PAGE = '/'

interface LocationStateRedirectProps {
  from?: Location
}

type LocationState = LocationStateRedirectProps | undefined

export const AuthPage = observer(() => {
  const location = useLocation<LocationState>()

  if (userStore.isAuthenticated) {
    return <Redirect to={location?.state?.from?.pathname || HOME_PAGE} />
  }

  const firebaseAuth = getFirebaseAuth()

  return (
    <Background width="100%" display="flex" flexDirection="column" alignSelf="center" justifyContent="center">
      <Box px={3} flex={1} display="flex" flexDirection="column" justifyContent="center">
        <BoxContainer display="flex" flexDirection="column" alignSelf="center" maxWidth={752} my={4}>
          <Heading>Sign in to account</Heading>
          {userStore.isAuthenticationInitialized ? (
            <FirebaseAuthForm uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
          ) : (
            <AuthPageLoadingIndicator />
          )}
        </BoxContainer>
      </Box>
    </Background>
  )
})

const Background = styled(BackgroundContainer)`
  height: 100vh;
  width: 100%;
`

const BoxContainer = styled(Box)`
  max-width: 480px;
  width: 100%;
  min-height: 300px;
  padding: 48px 36px 36px;
  margin: auto 0;

  background-color: '#fff';
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.3);
  border-radius: 6px;
`

const Heading = styled(Typography)`
  margin: 0 auto 48px !important;
  text-align: left;
  color: #171725;
  font-size: 22px;
  font-weight: bold;
`

const FirebaseAuthForm = styled(FirebaseAuth)`
  & .firebaseui-container,
  & .firebaseui-card-content,
  & .firebaseui-idp-list {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  & .firebaseui-list-item {
    width: 100%;
  }

  & .firebaseui-idp-google,
  & .firebaseui-idp-facebook,
  & .firebaseui-idp-password {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;

    padding: 12px;
    margin-bottom: 12px;

    background-color: #fff !important;
    box-shadow: none;

    border-radius: 10px;
    border: 2px solid #f1f1f5;
  }

  & .firebaseui-idp-text {
    color: #696974;
  }

  & .mdl-shadow--2dp {
    box-shadow: none;
  }

  & .firebaseui-card-header {
    display: none;
  }
`

const AuthPageLoadingIndicator = styled(LoadingSpinner)`
  margin: 32px auto 0;
`
