import React from 'react'
import { cleanEmptyProps } from '~/helpers/mdParser/helpers'
import { CommonObjectType } from '~/helpers/mdParser/types'
import { DefaultInputFieldProps } from '~/hooks/use-input'
import { IMessage, MESSAGES_TYPES } from '~/types/course'

import { splitRawOptions } from './split-raw-options'

type FillTheFieldOnUlpadProps = {
  message?: IMessage
  setTitle: (str: string) => void
  setDescription: React.Dispatch<React.SetStateAction<string>>
  setContent: React.Dispatch<React.SetStateAction<string>>
  setRawFillGapOptions: React.Dispatch<React.SetStateAction<string>>
  wrongAnswer: DefaultInputFieldProps
  successAnswer: DefaultInputFieldProps
}

export const makeRawOptionsFromObject = (obj: CommonObjectType) => {
  let rawResult = ''

  for (const [key, value] of Object.entries(obj)) {
    rawResult += `${key}=${value};\n`
  }

  return rawResult
}

export const makeFillGapOptionsFromRaw = (rawOptions: string, setOptionsError?: any) => {
  const extractedOptions: CommonObjectType = {}

  const rawOptionsSplitted = cleanEmptyProps(splitRawOptions(rawOptions))

  if (!rawOptionsSplitted) {
    setOptionsError && setOptionsError(true)
  } else {
    setOptionsError && setOptionsError(false)
  }

  try {
    rawOptionsSplitted.forEach((item: CommonObjectType) => {
      // remove \n from options, which we added for ui purposes
      // when fetched options from server
      const itemOmitNewLine = item.replaceAll('\n', '')

      if (!!itemOmitNewLine) {
        const [key, value] = itemOmitNewLine.split('=')
        const trimmedKey = key?.trim()
        const trimmedValue = value?.trim()

        extractedOptions[trimmedKey] = trimmedValue
      }
      setOptionsError && setOptionsError(false)
    })
  } catch (e) {
    console.info(e)
    setOptionsError && setOptionsError(true)
  }

  return extractedOptions
}

export const fillFieldsOnUpload = ({
  message,
  setTitle,
  setDescription,
  setRawFillGapOptions,
  setContent,
  wrongAnswer,
  successAnswer,
}: FillTheFieldOnUlpadProps) => {
  if (!message) return

  if (message.type === MESSAGES_TYPES.FILL_THE_GAP) {
    if (message.title) {
      setTitle(message.title)
    }

    if (message.description) {
      setDescription(message.description)
    }

    if (message.options) {
      try {
        const makedRawOptions = makeRawOptionsFromObject(message.options)
        setRawFillGapOptions(makedRawOptions)
      } catch (e) {
        console.info(e)
      }
    }

    if (message.content) {
      setContent(message.content)
    }

    wrongAnswer?.setValue?.(message.wrongAnswer)
    successAnswer?.setValue?.(message.successAnswer)
  }
}
