import React from 'react'
import { Link } from 'react-router-dom'
import { PrivateComponent } from '~/components/private-component'
import { UserRole } from '~/domain/user'

import { Box, List, ListItem, ListItemText } from '@mui/material'

interface Props {
  id?: string
  buttons: { text: string; path: string; roles: UserRole[] }[]
  pathIdentifier: string
}

export const CourseNav = ({ id, buttons, pathIdentifier }: Props) => (
  <Box my={2} display="flex" flexDirection="column">
    <List component="nav" aria-label={buttons.map(btn => btn.text).join(' ')}>
      {buttons.map(btn => (
        <PrivateComponent roles={btn?.roles} key={btn.path}>
          <ListItem
            button
            component={Link}
            to={`/courses/${id}/${btn.path}`}
            selected={pathIdentifier === `/${btn.path}`}
          >
            <ListItemText primary={btn.text} />
          </ListItem>
        </PrivateComponent>
      ))}
    </List>
  </Box>
)
