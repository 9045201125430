import { useCallback, useContext, useState } from 'react'
import { CourseApi } from '~/api/firebase/course'
import { TribeApi } from '~/api/firebase/tribe'
import { FIREBASE_PROJECT_ID } from '~/config/env'
import { coursesContext } from '~/contexts/courses'
import { useCourseQuery } from '~/hooks/use-course-query'
import { getBumpedCourseVersion } from '~/hooks/use-course-version'
import { MouseEventType } from '~/types/common'
import { Course as CourseType } from '~/types/course'

import { restructureGoals } from '../helpers/restructure-goals'

type Props = {
  course: CourseType
}

export const useUpdateCourse = ({ course }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const { fetchedCourse, refetch } = useCourseQuery({
    courseId: course.id,
    method: CourseApi.getSingleCourse,
    key: FIREBASE_PROJECT_ID,
  })
  const { handleUpdateCourse } = useContext(coursesContext)

  const handleCreateOrUpdateInDb = useCallback(
    async (e: MouseEventType, course: CourseType) => {
      e.preventDefault()

      try {
        const { data: dbCourse } = await refetch()

        if (!window.confirm('This action will completely update the course on the platform, are you sure?')) {
          return
        }

        if (dbCourse?.version && dbCourse?.version !== course?.version) {
          alert(
            `The operation was aborted because your version does not match the version on the platform:\nYour version: ${course?.version}\nDatabase version: ${dbCourse?.version}\nTo download the course, remove your local version using the Delete button in the course list.`,
          )
          return
        }

        const version = getBumpedCourseVersion(dbCourse?.version ?? '0.0.0')

        if (!version) {
          alert('An error occurred while updating the course version value')

          return
        }

        setLoading(true)
        const parsedCourse = restructureGoals({ ...course, version })
        if (dbCourse?.tribeId && course.tribeId && dbCourse.tribeId !== course.tribeId) {
          await TribeApi.removeTribeQuest(dbCourse.tribeId, dbCourse.id)
          await TribeApi.addTribeQuest(course.tribeId, course.id)
        }
        if (!dbCourse?.tribeId && course.tribeId) {
          await TribeApi.addTribeQuest(course.tribeId, course.id)
        }
        if (dbCourse?.tribeId && !course.tribeId) {
          await TribeApi.removeTribeQuest(dbCourse.tribeId, dbCourse.id)
        }
        await CourseApi.createOrUpdateCourse(parsedCourse)
        await refetch()
        handleUpdateCourse({ ...course, version })

        return { ...course, version }
      } catch (ex) {
        console.info(`Something error ${(ex as any)?.message}`)
        alert(`Something went wrong: ${(ex as any)?.message}`)

        return undefined
      } finally {
        setLoading(false)
      }
    },
    [handleUpdateCourse, refetch],
  )

  return { isLoading, fetchedCourse, handleCreateOrUpdateInDb }
}
