import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import showdown from 'showdown'
import { compileContract, CompileContractResponse } from '~/api/web3'
import { AddMessageOrGroup } from '~/components/add-message-or-group'
import { ButtonsGroup } from '~/components/buttons-group'
import { InputSelect } from '~/components/input-select'
import { Difficulty } from '~/domain/types'
import { getUniqueId } from '~/helpers/id'
import { CommonObjectType } from '~/helpers/mdParser/types'
import { containValueValidator } from '~/helpers/validation'
import { useCourseRoute } from '~/hooks/use-course-route'
import { useCourseSkills } from '~/hooks/use-course-skills'
import { useInput } from '~/hooks/use-input'
import { Assesment } from '~/modals/message-modal/message-modal-types/assesment'
import { Blanks } from '~/modals/message-modal/message-modal-types/blanks'
import { DragAndDrop } from '~/modals/message-modal/message-modal-types/drag-and-drop'
import { CommentFields } from '~/modals/message-modal/message-modal-types/fields/comment-fields'
import { GallerySlide } from '~/modals/message-modal/message-modal-types/gallery-slide'
import { Matching } from '~/modals/message-modal/message-modal-types/matching'
import { Quiz } from '~/modals/message-modal/message-modal-types/quiz'
import TextInput from '~/modals/message-modal/message-modal-types/text-input'
import { courseUsersMetaStore, skillStore } from '~/store'
import {
  AssesmentsMessageType,
  BlankOption as MessageBlankOption,
  BlankType,
  DefaultMessageType,
  DialogMessageType,
  DragAndDropOption,
  FinishMessageType,
  GalleryType,
  GithubTaskType,
  GoalEventMessageType,
  IDE_TYPES,
  IMessage,
  InteractiveTypes,
  LessonGoalType,
  MatchingOptionType,
  MatchingType,
  MediaSlideOptionType,
  MESSAGES_TYPES,
  MessagesWithAuthor,
  MessageTextInput,
  MessageType,
  QuizOption as MessageQuizType,
  QuizType,
  QuoteType,
  StartDayMessageType,
  TaskType as CourseTaskType,
  GithubLinkMessageType,
  ConnectWalletType,
  DeployContractMessageType,
} from '~/types/course'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'

import { Multiselect } from '../../components/multiselect'
import { AuthorIdSelect } from './message-modal-types/author-id-select'
import { getMessagePropsToFields, getValueAndCheckComma } from './message-modal-types/custom-message/helpers'
import { DifficultySelect } from './message-modal-types/difficulty-select'
import { FillTheGap } from './message-modal-types/fill-the-gap'
import NativeMdEditor from './message-modal-types/native-md'
import { Task } from './message-modal-types/task'

interface MessageProps {
  onClose: () => void
  isOpen: boolean
  onSave: (message: IMessage) => void
  message?: IMessage
  goals?: LessonGoalType[]
}

export type MediaOption = MediaSlideOptionType & { isEditing?: boolean }
export type MatchingOption = MatchingOptionType & { isEditing?: boolean }
export type QuizOption = MessageQuizType & { isEditing?: boolean }
export type BlankOption = MessageBlankOption & { isEditing?: boolean }
type CustomProperties = {
  key: string
  value: string
  placeholder?: string
  canRemove?: boolean
  editableKey?: boolean
}

export const MessageModal: FunctionComponent<MessageProps> = observer(props => {
  const { onClose, isOpen, onSave, message, goals } = props
  const id = message?.id ?? getUniqueId()
  const isEdit = Boolean(message)
  const { id: courseId } = useCourseRoute()
  const { messageSkills, skillsForOptions, selectedSkills, handleSkillsSelect } = useCourseSkills(message?.skills)
  const isSkillsFetched = toJS(skillStore.isSkillsFetched)

  const courseUsersMeta = toJS(courseUsersMetaStore.courseUsersMeta)
  const usersMetaForOptions = toJS(courseUsersMetaStore.usersMetaForOptions)
  const isUsersMetaFetched = toJS(courseUsersMetaStore.isUsersMetaFetched)

  const handleAuthorIdSelectOpen = () => {
    if (isUsersMetaFetched) return
    courseUsersMetaStore.fetchUsersMeta(courseId ?? '')
  }
  const handleSkillsSelectOpen = () => {
    if (isSkillsFetched) return
    skillStore.fetchAllSkills()
  }

  const { value: difficulty, setValue: setDifficulty } = useInput({ defaultValue: Difficulty.EASY })

  const {
    value: content,
    handleSetValue: handleSetContent,
    isValid: contentIsValid,
    // @ts-ignore
  } = useInput({ validators: containValueValidator, defaultValue: message?.content })

  const { value: nextButtonText, handleSetValue: handleSetNextButtonText } = useInput({
    defaultValue: message?.nextButtonText,
  })

  const { value: type, handleSetValue: handleSetType } = useInput({
    defaultValue: Object.values(MESSAGES_TYPES).includes(message?.type ?? MESSAGES_TYPES.DIALOG)
      ? message?.type ?? MESSAGES_TYPES.DIALOG
      : 'CUSTOM_PROP_MESSAGE',
  })

  const showDescription =
    type !== MESSAGES_TYPES.MARKDOWN && type !== MESSAGES_TYPES.GALLERY && type !== MESSAGES_TYPES.FILL_THE_GAP

  const {
    value: authorId,
    handleSetValue: handleSetAuthorId,
    setValue: setAuthorId,
  } = useInput({
    defaultValue: 'none',
  })

  // Состояния, которые используются для работы с типом Start Day
  const [startDayHeader, setStartDayHeader] = useState<string>((message as StartDayMessageType)?.header ?? '')
  const [startDaySmallHeader, setStartDaySmallHeader] = useState<string>(
    (message as StartDayMessageType)?.smallHeader ?? '',
  )
  // Состояния, которые используются для работы с типом Github Link
  const [githubLinkHeader, setGithubLinkHeader] = useState<string>((message as GithubLinkMessageType)?.header ?? '')
  const [githubLinkText, setGithubLinkText] = useState<string>((message as GithubLinkMessageType)?.awardedText ?? '')

  // Состояния, которые используются для работы с типом Deploy Contract
  const [deployContractCode, setDeployContractCode] = useState<string>(
    (message as DeployContractMessageType)?.contractCode ?? '',
  )
  const [deployContractProvider, setDeployContractProvider] = useState<string>(
    (message as DeployContractMessageType)?.provider ?? '',
  )
  const [deployContractInitialBalance, setDeployContractInitialBalance] = useState<number>(
    (message as DeployContractMessageType)?.initialBalance ?? 0,
  )
  const [deployContractAbi, setDeployContractAbi] = useState<any>((message as DeployContractMessageType)?.abi ?? [])
  const [deployContractBytecode, setDeployContractBytecode] = useState<string>(
    (message as DeployContractMessageType)?.bytecode ?? '',
  )
  const providerOptions: Array<{ value: string; text: string }> = [
    { value: 'https://sepolia.mode.network', text: 'Mode Testnet' },
    // { value: MESSAGES_TYPES.FACT, text: 'Факт' }, // deprecated
    { value: 'https://rpc.sepolia.org/', text: 'Ethereum Testnet' },
    { value: 'https://sepolia.optimism.io/', text: 'Optimism Testnet' },
    { value: 'https://rpc-amoy.polygon.technology', text: 'Polygon Amoy' },
    { value: 'https://sepolia-rpc.scroll.io', text: 'Scroll Testnet' },
  ]
  const availableCompilerVersions: Array<{ value: string; text: string }> = [
    { value: '0.8.28+commit.7893614a', text: '0.8.28' },
    { value: '0.8.0+commit.c7dfd78e', text: '0.8.0' },
    { value: '0.7.6+commit.7338295f', text: '0.7.6' },
    { value: '0.7.0+commit.9e61f92b', text: '0.7.0' },
    { value: '0.6.12+commit.27d51765', text: '0.6.12' },
    { value: '0.6.0+commit.26b70077', text: '0.6.0' },
  ]
  const [deployContractCompilerVersion, setDeployContractCompilerVersion] = useState<string>(
    (message as DeployContractMessageType)?.compilerVersion ?? '',
  )
  const [isCompiling, setIsCompiling] = useState(false)
  const [isCompiled, setIsCompiled] = useState(false)
  const [compilationError, setCompilationError] = useState('')
  const handleCompileContract = async () => {
    setIsCompiling(true)
    const response: CompileContractResponse = await compileContract(deployContractCode, deployContractCompilerVersion)
    if (response.error) {
      setCompilationError(response.error)
    }
    setDeployContractAbi(response.abi)
    setDeployContractBytecode(response.bytecode)
    setIsCompiling(false)
    setIsCompiled(true)
  }

  // Состояния, которые используются для работы с типом Wallet Transfer
  const [walletTransferTitle, setWalletTransferTitle] = useState<string>((message as ConnectWalletType)?.title ?? '')
  const [walletTransferIsInteractiveBlockPromotion, setWalletTransferIsInteractiveBlockPromotion] = useState<boolean>(
    (message as ConnectWalletType)?.isInteractiveBlockPromotion ?? false,
  )
  const [walletTransferNetId, setWalletTransferNetId] = useState<string>((message as ConnectWalletType)?.netId ?? '')
  const NameIdMapping = {
    'Eth Sepolia': 11155111,
    'Optimism Sepolia': 11155420,
    'Polygon Amoy': 80002,
    'Mode Sepolia': 919,
    'Reactive Kopli': 5318008,
  }

  // Состояния, которые используются для работы с типом Assesment
  const [questions, setQuestions] = useState<AssesmentsMessageType[]>([])
  const {
    value: title,
    handleSetValue: handleSetTitle,
    isValid: titleIsValid,
    // @ts-ignore
  } = useInput({ validators: containValueValidator, defaultValue: message?.title })
  const [totalSeconds, setTotalSeconds] = useState((message as any)?.totalSeconds || 0)

  // Состояния, которые используются для работы с типом AssesmentResult
  const {
    value: assesmentId,
    handleSetValue: handleSetAssesmentId,
    isValid: assesmentIdIsValid,
    // @ts-ignore
  } = useInput({ validators: containValueValidator, defaultValue: message?.assesmentId })

  // Состояния, которые используются для работы с типом QUOTE
  const { value: avatarSrc, setValue: setAvatarSrc } = useInput({
    defaultValue: '',
  })

  const handleSetAuthorIdAndAvatar = useCallback(
    e => {
      handleSetAuthorId(e)
      const selectedUserMeta = courseUsersMeta.find(userMeta => userMeta.id === e.target.value)
      setAvatarSrc(selectedUserMeta?.avatar)
    },
    [courseUsersMeta, handleSetAuthorId, setAvatarSrc],
  )

  const { value: authorText, handleSetValue: handleSetAuthorText } = useInput({
    defaultValue: message?.type === 'QUOTE' ? message.authorText : '',
  })

  // Состояния, которые используются для работы с типом QUIZ
  const { value: quizTitle, handleSetValue: handleQuizTitle, setValue: setQuizTitle } = useInput({ defaultValue: '' })
  const [quizOptions, setQuizOptions] = useState<QuizOption[]>([])
  const {
    value: quizOptionType,
    handleSetValue: handleOptionType,
    setValue: setOptionType,
  } = useInput({ defaultValue: '' })

  // Состояния, которые используются для работы с типом GALLERY
  const [gallerySlideOptions, setGallerySlideOptions] = useState<MediaOption[]>([])

  // Состояния, которые используются для работы с типом MATHING
  const [matchingOptions, setMatchingOptions] = useState<MatchingOption[]>([])
  const {
    value: matchingTitle,
    handleSetValue: handleMatchingTitle,
    setValue: setMatchingTitle,
  } = useInput({ defaultValue: '' })

  const [fillTheGapContent, setFillTheGapContent] = useState<string>('')
  const [fillGapsOptions, setFillGapsOptions] = useState<CommonObjectType>({})
  const [fillGapDescription, setFillGapDescription] = useState<string>('')

  const { value: fillTheGapTitle, setValue: setFillTheGapTitle } = useInput({ defaultValue: '' })

  // Состояния, которые используются для работы с типом CARD_INPUT
  const {
    value: textInputDescription,
    handleSetValue: handleTextInputDescription,
    setValue: setTextInputDescription,
  } = useInput({ defaultValue: '' })
  const {
    value: textInputSuccessAnswer,
    handleSetValue: handleTextInputSuccessAnswer,
    setValue: setTextInputSuccessAnswer,
  } = useInput({ defaultValue: '' })

  const {
    value: textInputWrongAnswer,
    handleSetValue: handleTextInputWrongAnswer,
    setValue: setTextInputWrongAnswer,
  } = useInput({ defaultValue: '' })

  const {
    value: textInputTitle,
    handleSetValue: handleTextInputTitle,
    setValue: setTextInputTitle,
  } = useInput({ defaultValue: '' })
  const [textInputCorrectAnswers, setTextInputCorrectAnswers] = useState<string>('')

  const {
    value: pointsInput,
    handleSetValue: handlePointsInput,
    setValue: setPointsInput,
    // TODO: Сейчас тип поинтс есть только у ТАСК сообщения
  } = useInput({ defaultValue: message?.type === 'TASK' ? message?.points || '' : '' })

  // Состояния, которые используются для работы с типом MARKDOWN
  const [markdownState, setMarkdownState] = useState<string>('')

  // Состояния, которые используются для работы с типом TASK
  const [selectedTaskId, setSelectedTaskId] = useState<string>('')
  const [previouslyTaskId, setPreviouslyTaskId] = useState<string>('')
  const [additionalCheckMessage, setAdditionalCheckMessage] = useState('')
  const [additionalCheckCode, setAdditionalCheckCode] = useState('')
  const [ideType, setIdeType] = useState<IDE_TYPES>(IDE_TYPES.LITTLE)

  // Состояния, которые используются для работы с типом BLANK
  const {
    value: blankTitle,
    handleSetValue: handleBlankTitle,
    setValue: setBlankTitle,
  } = useInput({ defaultValue: '' })
  const [blankState, setBlankState] = useState<string>('')
  const [blankOptions, setBlankOptions] = useState<BlankOption[]>([])

  // Состояния, которые используются для работы с типом DRAG_AND_DROP
  const {
    value: dragnDropTitle,
    handleSetValue: handleDragnDropTitle,
    setValue: setDragnDropTitle,
  } = useInput({ defaultValue: '' })

  const fields = useMemo(
    () => ({
      title: {
        value: textInputTitle,
        handleSetValue: handleTextInputTitle,
        setValue: setTextInputTitle,
      },
      hint: {
        value: textInputDescription,
        handleSetValue: handleTextInputDescription,
        setValue: setTextInputDescription,
      },
      successAnswer: {
        value: textInputSuccessAnswer,
        handleSetValue: handleTextInputSuccessAnswer,
        setValue: setTextInputSuccessAnswer,
      },
      wrongAnswer: {
        value: textInputWrongAnswer,
        handleSetValue: handleTextInputWrongAnswer,
        setValue: setTextInputWrongAnswer,
      },
      points: {
        value: pointsInput,
        handleSetValue: handlePointsInput,
        setValue: setPointsInput,
      },
    }),
    [
      handleTextInputDescription,
      handleTextInputSuccessAnswer,
      handleTextInputTitle,
      handleTextInputWrongAnswer,
      setTextInputDescription,
      setTextInputSuccessAnswer,
      setTextInputTitle,
      setTextInputWrongAnswer,
      handlePointsInput,
      setPointsInput,
      textInputDescription,
      textInputSuccessAnswer,
      textInputTitle,
      textInputWrongAnswer,
      pointsInput,
    ],
  )

  // Состояния, которые используются для работы с типом GITHUB_TASK
  const {
    value: githubReference,
    handleSetValue: handleGithubRefernce,
    isValid: isGithubReferenceError,
  } = useInput({
    defaultValue: (message as unknown as GithubTaskType)?.githubReference,
  })

  // Состояния, которые используются для работы с типом GITHUB_TASK
  const {
    value: actionNames,
    handleSetValue: handleSetActionNames,
    isValid: isActionNamesError,
  } = useInput({
    defaultValue: (message as GithubTaskType)?.actionNames?.join(';') as string,
  })

  useEffect(() => {
    if (!message) return
    if (message.type === MESSAGES_TYPES.GITHUB_TASK) {
      fields.wrongAnswer.setValue?.(message.wrongAnswer)
      fields.successAnswer?.setValue?.(message.successAnswer)
      setDifficulty(message?.difficulty)
    }
  }, [message, fields.successAnswer, fields.wrongAnswer, setDifficulty])

  // Состояния, которые используются для работы с типом GOAL_EVENT
  const [targetGoalId, setTargetGoalId] = useState(
    message?.type === MESSAGES_TYPES.GOAL_EVENT ? message?.targetGoalId : '',
  )

  const goalItems = goals
    ? goals.map(item => (
        <MenuItem key={item.id} value={item.id}>
          {item.text}
        </MenuItem>
      ))
    : ''

  const errorGoalMessage =
    goals && goals.length ? (
      ''
    ) : (
      <Typography color="error.main" mt={1} fontSize={12}>
        No goals, add a goal
      </Typography>
    )

  const defaultOption = {
    cards: {},
    columns: {
      'choose-column': {
        id: 'choose-column',
        title: 'Choose from',
        cardIds: [],
        correctCardIds: [''],
      },
      left: {
        id: 'left',
        title: '',
        cardIds: [],
        correctCardIds: [],
      },
      right: {
        id: 'right',
        title: '',
        cardIds: [],
        correctCardIds: [],
      },
    },
    columnOrder: ['choose-column', 'left', 'right'],
  }
  const [dragnDropOptions, setDragnDropOptions] = useState<DragAndDropOption>(defaultOption)

  // START OF CUSOM PROPS
  const [customProperies, setCustomProperties] = useState<CustomProperties[] | undefined>([
    { key: 'type', value: message?.type || '', canRemove: false, editableKey: false },
    ...(message?.type && !Object.values(MESSAGES_TYPES).includes(message?.type)
      ? getMessagePropsToFields(message)
      : []),
  ])

  const handleChangeField = useCallback((index: number, newValue: string, fieldProp: 'key' | 'value') => {
    setCustomProperties(fields => {
      const newFields = fields?.map(({ key, value, ...props }, fieldIndex) => {
        if (fieldIndex === index) {
          switch (fieldProp) {
            case 'key':
              return { key: newValue, value, ...props }
            case 'value':
              return { key, value: newValue, ...props }
            default:
              return { key, value, ...props }
          }
        }

        return { key, value, ...props }
      })

      return newFields
    })
  }, [])

  const handleCreateField = useCallback(
    () =>
      setCustomProperties(fields => [...(fields ?? []), { key: '', value: '', canRemove: true, editableKey: true }]),
    [],
  )

  const handleChangeFieldKey = useCallback(
    (index: number, e) => {
      handleChangeField(index, e.target.value, 'key')
    },
    [handleChangeField],
  )

  const handleChangeFieldValue = useCallback(
    (index: number, e) => {
      handleChangeField(index, e.target.value, 'value')
    },
    [handleChangeField],
  )

  const handleDeleteField = useCallback(
    (index: number) => () =>
      setCustomProperties(fields => fields?.filter((_, fieldIndex: number) => fieldIndex !== index)),
    [],
  )
  // END OF CUSOM PROPS

  const isSaveEnabled = useMemo(() => {
    if (type === MESSAGES_TYPES.DIALOG) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.FACT) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.GOALS_SLIDER) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.THOUGHT) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.QUOTE) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.MARKDOWN) {
      return !!markdownState
    }
    if (type === MESSAGES_TYPES.CARD_INPUT) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.GALLERY) {
      return gallerySlideOptions?.length > 0
    }
    if (type === MESSAGES_TYPES.QUIZ) {
      return quizOptions?.length !== 0
    }
    if (type === MESSAGES_TYPES.MATCHING) {
      const isCardEven = matchingOptions.length % 2 === 0 // checking for card parity

      return matchingOptions?.length > 0 && isCardEven
    }

    if (type === MESSAGES_TYPES.FILL_THE_GAP) {
      return true
    }

    if (type === MESSAGES_TYPES.BLANK) {
      return blankState?.length > 0 && blankOptions?.length > 0
    }
    if (type === MESSAGES_TYPES.DRAG_AND_DROP) {
      return dragnDropTitle.length > 0 && Object.keys(dragnDropOptions?.cards)?.length > 0
    }
    if (type === MESSAGES_TYPES.GITHUB_TASK) {
      return contentIsValid && githubReference?.length > 0
    }
    if (type === MESSAGES_TYPES.SUMMARY) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.TASK) {
      if (previouslyTaskId) {
        return previouslyTaskId && selectedTaskId && selectedTaskId !== previouslyTaskId
      }

      return selectedTaskId
    }
    if (type === MESSAGES_TYPES.FINISH) {
      return contentIsValid && nextButtonText.length > 0
    }
    if (type === MESSAGES_TYPES.GOAL_EVENT) {
      return !!targetGoalId && contentIsValid
    }
    if (type === MESSAGES_TYPES.START_DAY) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.GITHUB_LINK) {
      return contentIsValid
    }
    if (type === MESSAGES_TYPES.DEPLOY_CONTRACT) {
      return isCompiled
    }
    if (type === MESSAGES_TYPES.CONNECT_WALLET) {
      return contentIsValid
    }
    if (type === 'CUSTOM_PROP_MESSAGE') {
      return true
    }
    if (type === MESSAGES_TYPES.ASSESMENT) {
      return contentIsValid && titleIsValid
    }
    if (type === MESSAGES_TYPES.ASSESMENT_RESULT) {
      return contentIsValid && assesmentIdIsValid
    }
    return false
  }, [
    githubReference,
    type,
    assesmentIdIsValid,
    titleIsValid,
    contentIsValid,
    markdownState,
    gallerySlideOptions?.length,
    quizOptions?.length,
    matchingOptions.length,
    blankState?.length,
    blankOptions?.length,
    dragnDropTitle.length,
    dragnDropOptions?.cards,
    selectedTaskId,
    previouslyTaskId,
    nextButtonText.length,
    targetGoalId,
    isCompiled,
  ])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()

      // TODO: add 'skills' property to not interactive message types
      // (look https://linear.app/duke/issue/DUK-263/ubrat-selekt-skillov-u-prostyh-tipov-soobshenij-v-adminke-ostavit)

      if (!isSaveEnabled) return

      const safetyAuthorId = !authorId ? null : authorId
      const authorID = safetyAuthorId === 'none' ? null : safetyAuthorId

      if (type === MESSAGES_TYPES.DIALOG) {
        // parsing text in html
        const converter = new showdown.Converter({ tables: true })
        const html = converter.makeHtml(content)

        const message: DialogMessageType = {
          id,
          type,
          content: html,
          authorId: authorID || '0',
          nextButtonText,
        }
        onSave(message)
      }

      if (type === MESSAGES_TYPES.QUOTE) {
        const message: QuoteType = {
          id,
          type,
          content,
          authorId: authorID,
          avatarSrc,
          authorText,
          nextButtonText,
        }
        onSave(message)
      }

      if (type === MESSAGES_TYPES.FACT || type === MESSAGES_TYPES.THOUGHT || type === MESSAGES_TYPES.GOALS_SLIDER) {
        const message: DialogMessageType = {
          id,
          type,
          content,
          nextButtonText,
        }
        onSave(message)
      }

      if (type === MESSAGES_TYPES.MARKDOWN) {
        const message: DefaultMessageType = {
          type,
          id,
          content: markdownState,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.CARD_INPUT) {
        const message: MessageTextInput = {
          type,
          id,
          content,
          title: textInputTitle,
          answers: textInputCorrectAnswers.split(',').map(answer => answer.trim()),
          hint: textInputDescription,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
          skills: selectedSkills,
          difficulty,
          authorId: authorID,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.GALLERY) {
        const slides: MediaSlideOptionType[] = gallerySlideOptions.map(opt => {
          delete opt.isEditing
          return opt
        })

        const message: GalleryType = {
          type,
          id,
          slides,
          authorId: authorID,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.MATCHING) {
        const options: MatchingOptionType[] = matchingOptions.map(opt => {
          delete opt.isEditing
          return opt
        })

        const message: MatchingType = {
          id,
          type,
          title: matchingTitle,
          content,
          options,
          skills: selectedSkills,
          difficulty,
          authorId: authorID,
          nextButtonText,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.QUIZ) {
        const options: MessageQuizType[] = quizOptions.map(opt => {
          delete opt.isEditing
          return opt
        })

        const message: QuizType = {
          type,
          id,
          title: quizTitle,
          content,
          optionType: quizOptionType,
          options,
          skills: selectedSkills,
          difficulty,
          authorId: authorID,
          nextButtonText,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.BLANK) {
        const options: MessageBlankOption[] = blankOptions.map(opt => {
          delete opt.isEditing
          return opt
        })

        const message: BlankType = {
          type,
          id,
          title: blankTitle,
          content,
          sourceCode: blankState,
          options,
          skills: selectedSkills,
          difficulty,
          authorId: authorID,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.FILL_THE_GAP) {
        const message: any = {
          type,
          id,
          title: fillTheGapTitle,
          options: fillGapsOptions,
          content: fillTheGapContent,
          description: fillGapDescription,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.DRAG_AND_DROP) {
        const message = {
          type,
          id,
          title: dragnDropTitle,
          content,
          options: dragnDropOptions,
          skills: selectedSkills,
          difficulty,
          authorId: authorID,
          nextButtonText,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.GITHUB_TASK) {
        const message = {
          type,
          id,
          content,
          githubReference,
          actionNames: (actionNames || '')
            ?.replace(/[,.;]gi/, ';')
            ?.split(';')
            ?.map((value?: string) => value?.trim())
            ?.filter((value?: string) => !!value),
          skills: selectedSkills,
          difficulty,
          authorId: authorID,
          nextButtonText,
          wrongAnswer: textInputWrongAnswer,
          successAnswer: textInputSuccessAnswer,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.SUMMARY) {
        const message = {
          type,
          id,
          content,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.ASSESMENT) {
        const message = {
          type,
          id,
          content,
          title,
          messages: questions,
          nextButtonText,
          totalSeconds,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.ASSESMENT_RESULT) {
        const message = {
          type,
          id,
          nextButtonText,
          assesmentId,
          content,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.FINISH) {
        const message: FinishMessageType = {
          type,
          id,
          content,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.GOAL_EVENT) {
        const message: GoalEventMessageType = {
          type,
          id,
          content,
          targetGoalId,
          nextButtonText,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.TASK) {
        const message: CourseTaskType = {
          type,
          id,
          content,
          taskId: selectedTaskId,
          progressFromTaskId: previouslyTaskId,
          additionalCheckMessage,
          additionalCheckCode,
          ideType,
          points: pointsInput ? Number(pointsInput) : 0,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.START_DAY) {
        const message: StartDayMessageType = {
          type,
          id,
          content,
          header: startDayHeader,
          smallHeader: startDaySmallHeader,
        }

        onSave(message)
      }

      if (type === MESSAGES_TYPES.GITHUB_LINK) {
        const message: GithubLinkMessageType = {
          type,
          id,
          content,
          header: githubLinkHeader,
          awardedText: githubLinkText,
          nextButtonText,
          difficulty,
          skills: selectedSkills,
        }

        onSave(message)
      }
      if (type === MESSAGES_TYPES.DEPLOY_CONTRACT) {
        const message: DeployContractMessageType = {
          type,
          id,
          content,
          contractCode: deployContractCode,
          compilerVersion: deployContractCompilerVersion,
          initialBalance: deployContractInitialBalance,
          provider: deployContractProvider,
          abi: deployContractAbi,
          bytecode: deployContractBytecode,
          nextButtonText,
          difficulty,
          skills: selectedSkills,
        }

        onSave(message)
      }
      if (type === MESSAGES_TYPES.CONNECT_WALLET) {
        const message: ConnectWalletType = {
          type,
          id,
          content,
          title: walletTransferTitle,
          netId: walletTransferNetId,
          isInteractiveBlockPromotion: walletTransferIsInteractiveBlockPromotion,
        }

        onSave(message)
      }

      if (type === 'CUSTOM_PROP_MESSAGE') {
        const fields = customProperies
          ?.filter(field => field.key && field.value)
          .reduce(
            (fields, field) => ({
              ...fields,
              [field.key]: getValueAndCheckComma(field.value),
            }),
            {},
          )
        const message: any = {
          id,
          type,
          content,
          nextButtonText,
          ...fields,
        }
        onSave(message)
      }

      return onClose()
    },
    [
      actionNames,
      githubReference,
      startDayHeader,
      startDaySmallHeader,
      assesmentId,
      questions,
      title,
      totalSeconds,
      isSaveEnabled,
      authorId,
      type,
      onClose,
      content,
      id,
      nextButtonText,
      onSave,
      avatarSrc,
      authorText,
      markdownState,
      textInputTitle,
      textInputCorrectAnswers,
      textInputDescription,
      textInputSuccessAnswer,
      textInputWrongAnswer,
      selectedSkills,
      difficulty,
      gallerySlideOptions,
      matchingOptions,
      matchingTitle,
      quizOptions,
      quizTitle,
      quizOptionType,
      blankOptions,
      blankTitle,
      blankState,
      dragnDropTitle,
      dragnDropOptions,
      targetGoalId,
      selectedTaskId,
      previouslyTaskId,
      additionalCheckMessage,
      additionalCheckCode,
      ideType,
      customProperies,
      fillGapDescription,
      fillGapsOptions,
      fillTheGapContent,
      fillTheGapTitle,
      pointsInput,
      githubLinkHeader,
      githubLinkText,
      walletTransferTitle,
      walletTransferIsInteractiveBlockPromotion,
      walletTransferNetId,
      deployContractCode,
      deployContractAbi,
      deployContractBytecode,
      deployContractProvider,
      deployContractInitialBalance,
      deployContractCompilerVersion,
    ],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Message',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [handleSubmit, isEdit, isSaveEnabled, onClose],
  )

  const messageOptions: Array<{ value: MessageType | string; text: string }> = [
    { value: MESSAGES_TYPES.DIALOG, text: 'Dialogue' },
    // { value: MESSAGES_TYPES.FACT, text: 'Факт' }, // deprecated
    { value: MESSAGES_TYPES.START_DAY, text: 'Start of the day' },
    { value: MESSAGES_TYPES.GOALS_SLIDER, text: 'Goals Slider' },
    { value: MESSAGES_TYPES.FILL_THE_GAP, text: 'Fill the gap' },
    // { value: MESSAGES_TYPES.THOUGHT, text: 'Мысль' }, // deprecated
    // { value: MESSAGES_TYPES.QUOTE, text: 'Цитата' }, // deprecated
    { value: MESSAGES_TYPES.CARD_INPUT, text: 'Card Input' },
    { value: MESSAGES_TYPES.MARKDOWN, text: 'Markdown text' },
    { value: MESSAGES_TYPES.GALLERY, text: 'Media' },
    { value: MESSAGES_TYPES.MATCHING, text: 'Card matching' },
    { value: MESSAGES_TYPES.QUIZ, text: 'Question with answers variants' },
    { value: MESSAGES_TYPES.BLANK, text: 'Blanks' },
    { value: MESSAGES_TYPES.DRAG_AND_DROP, text: 'Drag and drop' },
    // { value: MESSAGES_TYPES.SUMMARY, text: 'Summary' }, // deprecated
    { value: MESSAGES_TYPES.FINISH, text: 'Finish course screen' },
    { value: MESSAGES_TYPES.GOAL_EVENT, text: 'Goal' },
    { value: MESSAGES_TYPES.TASK, text: 'Code Task' },
    { value: MESSAGES_TYPES.ASSESMENT, text: 'Assessment' },
    { value: MESSAGES_TYPES.ASSESMENT_RESULT, text: 'Assessment Result' },
    { value: MESSAGES_TYPES.GITHUB_TASK, text: 'Github Task' },
    { value: MESSAGES_TYPES.GITHUB_LINK, text: 'Github Link' },
    { value: MESSAGES_TYPES.CONNECT_WALLET, text: 'Wallet Connect' },
    { value: MESSAGES_TYPES.DEPLOY_CONTRACT, text: 'Contract Deploy' },
    { value: 'CUSTOM_PROP_MESSAGE', text: 'Custom message' },
  ]

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={() => {}}>
          <Box pt={2} display="flex" alignItems="center">
            <Typography noWrap>Message type</Typography>
            <Box ml={2} width="280px">
              <InputSelect value={type} onChange={handleSetType} options={messageOptions} />
            </Box>
          </Box>
          <Box pt={1} pb={2} display="flex" alignItems="center">
            <Typography noWrap>Property type: {type}</Typography>
          </Box>
          {showDescription && (
            <Box py={2}>
              <Typography style={{ fontSize: 14, marginBottom: '15px' }}>Description</Typography>
              <TextField
                fullWidth
                label="Text"
                required
                value={content}
                onChange={handleSetContent}
                error={!contentIsValid}
                variant="outlined"
                multiline
              />
            </Box>
          )}
          {type === MESSAGES_TYPES.GOAL_EVENT && (
            <FormControl sx={{ width: '100%', marginBottom: 2 }}>
              <InputLabel id="select-label">Goal</InputLabel>
              <Select
                value={targetGoalId}
                label="goal"
                onChange={({ target }) => {
                  setTargetGoalId(target.value)
                }}
                required
                variant="outlined"
                error={!targetGoalId}
              >
                {goalItems}
              </Select>
              {errorGoalMessage}
            </FormControl>
          )}

          {type === 'QUOTE' && (
            <Box pt={2}>
              <TextField fullWidth label="author text" value={authorText} onChange={handleSetAuthorText} multiline />
            </Box>
          )}

          {type === 'CARD_INPUT' && (
            <TextInput
              message={message}
              setTextInputCorrectAnswers={setTextInputCorrectAnswers}
              textInputCorrectAnswers={textInputCorrectAnswers}
              hint={fields.hint}
              wrongAnswer={fields.wrongAnswer}
              successAnswer={fields.successAnswer}
              title={fields.title}
            />
          )}

          {type === 'MARKDOWN' && (
            <NativeMdEditor markdownState={markdownState} setMarkdownState={setMarkdownState} message={message} />
          )}

          {type === MESSAGES_TYPES.GALLERY && (
            <GallerySlide
              message={message}
              gallerySlideOptions={gallerySlideOptions}
              setGallerySlideOptions={setGallerySlideOptions}
            />
          )}

          {type === MESSAGES_TYPES.FILL_THE_GAP && (
            <FillTheGap
              message={message}
              wrongAnswer={fields.wrongAnswer}
              successAnswer={fields.successAnswer}
              title={fillTheGapTitle}
              setTitle={setFillTheGapTitle}
              description={fillGapDescription}
              setDescription={setFillGapDescription}
              content={fillTheGapContent}
              setContent={setFillTheGapContent}
              setOptions={setFillGapsOptions}
            />
          )}

          {type === MESSAGES_TYPES.MATCHING && (
            <Matching
              message={message}
              matchingTitle={matchingTitle}
              handleMatchingTitle={handleMatchingTitle}
              setMatchingTitle={setMatchingTitle}
              matchingOptions={matchingOptions}
              setMatchingOptions={setMatchingOptions}
              wrongAnswer={fields.wrongAnswer}
              successAnswer={fields.successAnswer}
            />
          )}

          {type === 'QUIZ' && (
            <Quiz
              message={message}
              quizTitle={quizTitle}
              handleQuizTitle={handleQuizTitle}
              setQuizTitle={setQuizTitle}
              quizOptions={quizOptions}
              setQuizOptions={setQuizOptions}
              quizOptionType={quizOptionType}
              handleOptionType={handleOptionType}
              setOptionType={setOptionType}
              wrongAnswer={fields.wrongAnswer}
              successAnswer={fields.successAnswer}
            />
          )}

          {type === 'BLANK' && (
            <Blanks
              message={message}
              blankTitle={blankTitle}
              handleBlankTitle={handleBlankTitle}
              setBlankTitle={setBlankTitle}
              blankState={blankState}
              setBlankState={setBlankState}
              blankOptions={blankOptions}
              setBlankOptions={setBlankOptions}
              wrongAnswer={fields.wrongAnswer}
              successAnswer={fields.successAnswer}
            />
          )}

          {type === 'DRAG_AND_DROP' && (
            <DragAndDrop
              message={message}
              dragnDropTitle={dragnDropTitle}
              handleDragnDropTitle={handleDragnDropTitle}
              setDragnDropTitle={setDragnDropTitle}
              dragnDropOptions={dragnDropOptions}
              setDragnDropOptions={setDragnDropOptions}
              wrongAnswer={fields.wrongAnswer}
              successAnswer={fields.successAnswer}
            />
          )}

          {type === 'TASK' && (
            <Task
              message={message}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              previouslyTaskId={previouslyTaskId}
              setPreviouslyTaskId={setPreviouslyTaskId}
              additionalCheckMessage={additionalCheckMessage}
              setAdditionalCheckMessage={setAdditionalCheckMessage}
              additionalCheckCode={additionalCheckCode}
              setAdditionalCheckCode={setAdditionalCheckCode}
              ideType={ideType}
              setIdeType={setIdeType}
              points={fields.points}
            />
          )}

          {MessagesWithAuthor?.includes(type) && (
            <AuthorIdSelect
              type={type}
              message={message}
              setAuthorId={setAuthorId}
              value={authorId}
              onChange={handleSetAuthorIdAndAvatar}
              onOpen={handleAuthorIdSelectOpen}
              options={usersMetaForOptions}
              label="author"
            />
          )}

          <Box>
            {type === 'CUSTOM_PROP_MESSAGE'
              ? customProperies?.map((field, index) => (
                  <Box display="flex" py={1} key={`field-${index}`}>
                    <Box pr={2}>
                      <TextField
                        label="key"
                        value={field.key}
                        disabled={!field.editableKey}
                        onChange={e => handleChangeFieldKey(index, e)}
                        variant="outlined"
                      />
                    </Box>
                    <TextField
                      fullWidth
                      label="value"
                      value={field.value}
                      onChange={e => handleChangeFieldValue(index, e)}
                      variant="outlined"
                    />
                    {field.canRemove && (
                      <Button color="error" onClick={handleDeleteField(index)}>
                        <DeleteIcon />
                      </Button>
                    )}
                  </Box>
                ))
              : null}
            {type === 'CUSTOM_PROP_MESSAGE' ? (
              <Box position="relative" my={1} pb={2}>
                <AddMessageOrGroup isDisplay onClick={handleCreateField} />
              </Box>
            ) : null}

            {type === 'GITHUB_TASK' ? (
              <Box position="relative" my={1} pb={2}>
                <TextField
                  fullWidth
                  label="Repo link"
                  required
                  value={githubReference}
                  onChange={handleGithubRefernce}
                  error={!isGithubReferenceError}
                  variant="outlined"
                  multiline
                />
                <Box py={2} my={1}>
                  <TextField
                    fullWidth
                    label="Jobs to be checked (separate with ;)"
                    required
                    value={actionNames}
                    onChange={handleSetActionNames}
                    error={!isActionNamesError}
                    variant="outlined"
                    multiline
                  />
                </Box>
                <CommentFields wrongAnswer={fields.wrongAnswer} successAnswer={fields.successAnswer} />
              </Box>
            ) : null}

            {type === 'ASSESMENT_RESULT' && (
              <>
                <Box pb={2}>
                  <TextField
                    fullWidth
                    label="Assesment Id"
                    required
                    value={assesmentId}
                    onChange={handleSetAssesmentId}
                    error={!assesmentIdIsValid}
                    variant="outlined"
                    multiline
                  />
                </Box>
              </>
            )}

            {type === 'START_DAY' && (
              <>
                <Box py={1}>
                  <TextField
                    fullWidth
                    label="Small Header"
                    required
                    value={startDaySmallHeader}
                    onChange={(e: any) => setStartDaySmallHeader(e.target.value)}
                    variant="outlined"
                    multiline
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    fullWidth
                    label="Large Header"
                    required
                    value={startDayHeader}
                    onChange={(e: any) => setStartDayHeader(e.target.value)}
                    variant="outlined"
                    multiline
                  />
                </Box>
              </>
            )}
            {type === 'GITHUB_LINK' && (
              <>
                <Box py={2}>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Title"
                      required
                      value={githubLinkHeader}
                      onChange={(e: any) => setGithubLinkHeader(e.target.value)}
                      variant="outlined"
                      multiline
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Awarded text"
                      required
                      value={githubLinkText}
                      onChange={(e: any) => setGithubLinkText(e.target.value)}
                      variant="outlined"
                      multiline
                    />
                  </Box>
                </Box>
              </>
            )}
            {type === 'DEPLOY_CONTRACT' && (
              <>
                <Box py={2}>
                  <Box mb={2}>
                    <Box mb={4}>
                      <TextField
                        fullWidth
                        label="Contract code"
                        required
                        value={deployContractCode}
                        onChange={(e: any) => setDeployContractCode(e.target.value)}
                        variant="outlined"
                        multiline
                      />
                      <Box mt={1}>
                        {!isCompiled ? (
                          <Button onClick={handleCompileContract} sx={{ backgroundColor: 'black', color: 'white' }}>
                            {isCompiling ? 'Compiling...' : 'Compile'}
                          </Button>
                        ) : (
                          <Button sx={{ backgroundColor: !compilationError ? 'green' : 'red', color: 'white' }}>
                            {!compilationError ? 'Compiled' : 'Error'}
                          </Button>
                        )}
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Initial balance (max 0.001 ETH)"
                        required
                        type="number"
                        inputProps={{
                          min: 0,
                          max: 0.001,
                          step: 0.000001,
                        }}
                        value={deployContractInitialBalance}
                        onChange={e => {
                          const value = parseFloat(e.target.value)
                          if (value <= 0.001) {
                            setDeployContractInitialBalance(value)
                          }
                        }}
                        error={deployContractInitialBalance > 0.001}
                        helperText={deployContractInitialBalance > 0.001 ? 'Maximum balance is 0.001 ETH' : ''}
                        variant="outlined"
                      />
                    </Box>
                    {/* Compiler Versions Dropdown */}
                    <Box mb={2}>
                      <InputSelect
                        label="Compiler Version"
                        value={deployContractCompilerVersion}
                        onChange={(e: any) => setDeployContractCompilerVersion(e.target.value)}
                        options={availableCompilerVersions}
                      />
                    </Box>
                    {compilationError && (
                      <Typography style={{ fontSize: 14, marginBottom: '15px', color: 'red' }}>
                        {compilationError}
                      </Typography>
                    )}
                    <InputSelect
                      label="Provider"
                      value={deployContractProvider}
                      onChange={(e: any) => setDeployContractProvider(e.target.value)}
                      options={providerOptions}
                    />
                  </Box>
                </Box>
              </>
            )}

            {type === 'WALLET_TRANSFER' && (
              <>
                <Box py={1}>
                  <TextField
                    fullWidth
                    label="Title"
                    required
                    value={walletTransferTitle}
                    onChange={(e: any) => setWalletTransferTitle(e.target.value)}
                    variant="outlined"
                    multiline
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    fullWidth
                    label="Block Promotion"
                    required
                    value={walletTransferIsInteractiveBlockPromotion}
                    onChange={(e: any) => setWalletTransferIsInteractiveBlockPromotion(e.target.value)}
                    variant="outlined"
                    multiline
                  />
                </Box>
              </>
            )}
            {type === 'CONNECT_WALLET' && (
              <>
                <Box py={1}>
                  <Select
                    fullWidth
                    label="NetId"
                    required
                    value={walletTransferNetId}
                    onChange={(e: any) => setWalletTransferNetId(e.target.value)}
                    variant="outlined"
                  >
                    {Object.entries(NameIdMapping).map(([name, id]) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            {type === 'ASSESMENT' && (
              <>
                <Box py={1}>
                  <TextField
                    fullWidth
                    label="Title"
                    required
                    value={title}
                    onChange={handleSetTitle}
                    error={!titleIsValid}
                    variant="outlined"
                    multiline
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    fullWidth
                    label="Completion time (ms)"
                    required
                    value={totalSeconds}
                    onChange={(e: any) => setTotalSeconds(e.target.value)}
                    variant="outlined"
                    multiline
                  />
                </Box>
                <Assesment questions={questions} setQuestions={setQuestions} message={message} />
              </>
            )}
          </Box>
          {InteractiveTypes?.includes(type) && (
            <>
              {
                // TODO: move Multiselect from condition to add it to all types of messages
                // (look https://linear.app/duke/issue/DUK-263/ubrat-selekt-skillov-u-prostyh-tipov-soobshenij-v-adminke-ostavit)
              }
              <Box py={2} my={1}>
                <Multiselect
                  setValue={handleSkillsSelect}
                  onOpen={handleSkillsSelectOpen}
                  options={skillsForOptions}
                  label="skills"
                  value={messageSkills}
                />
              </Box>
              <DifficultySelect message={message} difficulty={difficulty} setDifficulty={setDifficulty} />
            </>
          )}

          <Box pb={2} mb={1}>
            <TextField
              fullWidth
              label="nextButtonText"
              value={nextButtonText}
              required={type === 'FINISH'}
              onChange={handleSetNextButtonText}
              variant="outlined"
            />
          </Box>

          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
