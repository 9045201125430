import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { Editor } from '~/components/editor/editor'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'

export const CourseEditPage = () => {
  const { id } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  return course ? <Editor course={course} /> : <CourseNotFound />
}
