import React from 'react'
import { COURSES_KEY } from '~/constants/storage-keys'
import { safetyParseJson } from '~/helpers/json'
import { Storage } from '~/services/storage'

export const useImport = () => {
  const handleOpenFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const coursesStr = Storage.get(COURSES_KEY)
    const courses = safetyParseJson(coursesStr) ?? []

    if (!e.target) return

    const file = e.target.files && e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      const target = event.target
      if (!target) return
      const result = target.result
      if (!result) return
      const fileContents = JSON.parse(result.toString())

      Storage.set(COURSES_KEY, [...(courses ?? []), fileContents])
    }

    file && reader.readAsText(file)
    reader.onloadend = () => window.location.reload()
  }

  const handleUpload = (inputRef: React.RefObject<HTMLInputElement> | null) => {
    if (!inputRef) return

    const fileInput = inputRef.current
    if (fileInput) fileInput.click()
  }

  return {
    handleOpenFile,
    handleUpload,
  }
}
