import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { getUniqueId } from '~/helpers/id'
import { MediaOption } from '~/modals/message-modal/message-modal'
import { IMessage } from '~/types/course'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  FormControl,
  TextField,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { Media } from './previews/slider'

type GallerySlideProps = {
  message?: IMessage
  gallerySlideOptions: MediaOption[]
  setGallerySlideOptions: React.Dispatch<React.SetStateAction<MediaOption[]>>
}
export const GallerySlide = ({ message, gallerySlideOptions, setGallerySlideOptions }: GallerySlideProps) => {
  useEffect(() => {
    if (!message) return
    if (message.type === 'GALLERY') {
      setGallerySlideOptions(message.slides)
    }
  }, [message, setGallerySlideOptions])

  const handleAddingOption = useCallback(() => {
    setGallerySlideOptions(prevState => [
      ...prevState,
      {
        elementId: getUniqueId(),
        source: '',
        description: '',
        contentType: 'IMAGE',
        isEditing: true,
      },
    ])
  }, [setGallerySlideOptions])

  const handleDeletingOption = useCallback(
    (optionId: number | string) => {
      setGallerySlideOptions(prevState => prevState.filter(opt => opt.elementId !== optionId))
    },
    [setGallerySlideOptions],
  )

  const handleEditingOption = useCallback(
    (optionId: number | string) => {
      setGallerySlideOptions(prevState => prevState.map(opt => ({ ...opt, isEditing: opt.elementId === optionId })))
    },
    [setGallerySlideOptions],
  )

  const handleAcceptingChanges = useCallback(
    (optionId: number | string) => {
      setGallerySlideOptions(prevState =>
        prevState.map(opt => {
          if (opt.elementId !== optionId) return opt
          return { ...opt, isEditing: false }
        }),
      )
    },
    [setGallerySlideOptions],
  )

  const handleChangeOptionSource = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number | string) => {
      setGallerySlideOptions(prevState =>
        prevState.map(opt => {
          if (opt.elementId !== id) return opt
          return { ...opt, source: e.target.value }
        }),
      )
    },
    [setGallerySlideOptions],
  )
  const handleChangeOptionDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number | string) => {
      setGallerySlideOptions(prevState =>
        prevState.map(opt => {
          if (opt.elementId !== id) return opt
          return { ...opt, description: e.target.value }
        }),
      )
    },
    [setGallerySlideOptions],
  )
  const handleChangeOptionShortDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number | string) => {
      setGallerySlideOptions(prevState =>
        prevState.map(opt => {
          if (opt.elementId !== id) return opt
          return { ...opt, shortDescription: e.target.value }
        }),
      )
    },
    [setGallerySlideOptions],
  )
  const handleChangeContentType = useCallback(
    (e: SelectChangeEvent, id: number | string) => {
      setGallerySlideOptions(prevState =>
        prevState.map(opt => {
          if (opt.elementId !== id) return opt
          const value = e.target.value as string
          return { ...opt, contentType: value }
        }),
      )
    },
    [setGallerySlideOptions],
  )

  return (
    <>
      <Box>
        <Box>
          <Media slides={gallerySlideOptions} />
        </Box>
        <Box mt={2} py={2}>
          <Typography>Slides list</Typography>
          {gallerySlideOptions?.length > 0 && (
            <Box py={2} display="flex" alignItems="center">
              <FormControl fullWidth>
                {gallerySlideOptions.map(option => (
                  <Option mb={2} py={2} width="100%" key={option.elementId}>
                    <Box my={2}>
                      <TextField
                        value={option.source}
                        onChange={e => handleChangeOptionSource(e, option.elementId)}
                        autoFocus={option.isEditing}
                        label="Slide's URL"
                        disabled={!option.isEditing}
                        multiline={option.isEditing}
                        fullWidth
                      />
                    </Box>
                    <Box my={2}>
                      <TextField
                        size="medium"
                        label="Slide description"
                        value={option.description}
                        disabled={!option.isEditing}
                        onChange={e => handleChangeOptionDescription(e, option.elementId)}
                        multiline
                        fullWidth
                      />
                    </Box>
                    <Box my={2}>
                      <TextField
                        size="medium"
                        label="Short slide description"
                        value={option.shortDescription}
                        disabled={!option.isEditing}
                        onChange={e => handleChangeOptionShortDescription(e, option.elementId)}
                        multiline
                        fullWidth
                      />
                    </Box>
                    <Box my={2}>
                      <FormControl sx={{ minWidth: 150 }}>
                        <InputLabel id="slide-select-label">Slides type</InputLabel>
                        <Select
                          labelId="slide-select-label"
                          value={option.contentType}
                          disabled={!option.isEditing}
                          autoWidth
                          onChange={e => handleChangeContentType(e, option.elementId)}
                        >
                          <MenuItem value="IMAGE">Image</MenuItem>
                          <MenuItem value="VIDEO">Video</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box flex="0 0 auto">
                      {option.isEditing ? (
                        <IconButton
                          edge="end"
                          aria-label="accept"
                          onClick={() => handleAcceptingChanges(option.elementId)}
                          size="large"
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEditingOption(option.elementId)}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      )}

                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeletingOption(option.elementId)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Option>
                ))}
              </FormControl>
            </Box>
          )}
          <Button size="small" startIcon={<AddCircleOutlineIcon />} onClick={handleAddingOption}>
            Add slide
          </Button>
        </Box>
      </Box>
    </>
  )
}

const Option = styled(Box)`
  border: 1px solid silver;
  border-radius: 5px;
  padding: 10px;
`
