export const {
  // STAGE
  REACT_APP_PUBLIC_FIREBASE_API_KEY: FIREBASE_API_KEY,
  REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN,
  REACT_APP_PUBLIC_FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID,
  REACT_APP_PUBLIC_FIREBASE_STORAGE_BITBUCKET: FIREBASE_STORAGE_BITBUCKET,
  REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_PUBLIC_FIREBASE_APP_ID: FIREBASE_APP_ID,
  // PRODUCTION
  REACT_APP_PUBLIC_FIREBASE_API_KEY_PRODUCTION: FIREBASE_API_KEY_PRODUCTION,
  REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN_PRODUCTION: FIREBASE_AUTH_DOMAIN_PRODUCTION,
  REACT_APP_PUBLIC_FIREBASE_PROJECT_ID_PRODUCTION: FIREBASE_PROJECT_ID_PRODUCTION,
  REACT_APP_PUBLIC_FIREBASE_STORAGE_BITBUCKET_PRODUCTION: FIREBASE_STORAGE_BITBUCKET_PRODUCTION,
  REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID_PRODUCTION: FIREBASE_MESSAGING_SENDER_ID_PRODUCTION,
  REACT_APP_PUBLIC_FIREBASE_APP_ID_PRODUCTION: FIREBASE_APP_ID_PRODUCTION,
  // OTHER
  REACT_APP_PUBLIC_NODE_ENV: NODE_ENV,
  REACT_APP_PUBLIC_FIREBASE_CLOUD_FUNCTIONS: CLOUD_FUNCTIONS_TYPE = 'remote',
  REACT_APP_PUBLIC_GITHUB_TOKEN: GITHUB_TOKEN,
  SKIP_PREFLIGHT_CHECK,
} = process.env
