import React from 'react'
import styled from 'styled-components'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'

export type AddMessageOrGroupProps = {
  isDisplay: boolean
  onClick: () => void
}

export const AddMessageOrGroup = ({ isDisplay, onClick }: AddMessageOrGroupProps) => (
  <AddMessage isDisplay={isDisplay}>
    <IconButton edge="end" size="small" onClick={onClick}>
      <AddCircleIcon />
    </IconButton>
  </AddMessage>
)

const AddMessage = styled(Box)<{ isDisplay: boolean }>`
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s;
  opacity: ${({ isDisplay }) => (isDisplay ? 1 : 0)};
`
