import React from 'react'

import { Autocomplete, FormControl, TextField } from '@mui/material'

type Props<T> = {
  label?: string
  options: T[]
  onOpen?: (value: any) => void
  setValue: (value: T[]) => void
  value?: T[]
  required?: boolean
  freeSolo?: boolean
}

export function Multiselect<T>({ freeSolo = false, label, options, onOpen, setValue, value, required }: Props<T>) {
  const handleChange = (_event: React.SyntheticEvent, newValue: T | any) => {
    const values = newValue?.map((value: any) => {
      if (typeof value === 'string') {
        return {
          label: value,
          value,
        }
      }

      return value
    })

    setValue(values)
  }
  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        disableCloseOnSelect
        onChange={handleChange}
        onOpen={onOpen}
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Add "xxx" option created dynamically
          if (option?.inputValue) {
            return option?.inputValue
          }
          // Regular option
          return option?.title ?? option?.label ?? option?.text ?? option?.name ?? ''
        }}
        value={value}
        freeSolo={freeSolo}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            required={required && !value?.length}
            error={required && !value?.length}
          />
        )}
      />
    </FormControl>
  )
}
