import { Payment, PaymentStatuses } from '~/domain/payment/payment'
import { User } from '~/domain/user'
import { getUniqueId } from '~/helpers/id'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'

import { where } from '@firebase/firestore'

const COLLECTION_KEY = 'payments'

const getCollection = (id?: string) =>
  getCollectionRef(COLLECTION_KEY, {
    id,
    converter: {
      fromFirestore: (snapshot: any) => Payment.toRaw(snapshot.data()),
      toFirestore: (data: Payment) => Payment.fromRaw(data),
    },
  })

export type newPaymentParams = {
  user: User
  courseId: string
  coursePriceAmount: number
  courseName: string
  paymentUrl: string
  status: PaymentStatuses
  paymentProvider: string
  currency: string
  id: string
  invoiceTime: number | string
  expirationTime?: number | string
  type: PaymentType
}

export enum PaymentType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  LINK = 'LINK',
}

const createNewPayment = async ({
  user,
  courseId,
  coursePriceAmount,
  courseName,
  paymentUrl,
  status,
  id,
  currency,
  paymentProvider,
  invoiceTime,
  expirationTime,
  type,
}: newPaymentParams): Promise<Payment> => {
  const thirdPartyPayment = {
    id: getUniqueId(),
    orderId: id,
    userId: user.id,
    userName: user.displayName,
    userEmail: user.email,
    paymentCentsAmount: coursePriceAmount,
    productDescription: courseName,
    paymentUrl,
    status,
    currency,
  }

  const payment = new Payment({
    id: thirdPartyPayment.id,
    orderId: thirdPartyPayment.orderId,
    paymentId: thirdPartyPayment.orderId,
    paymentUrl: thirdPartyPayment.paymentUrl,
    status: thirdPartyPayment.status,
    amount: thirdPartyPayment.paymentCentsAmount,
    currency,
    userId: user.id,
    courseId,
    paymentProvider,
    invoiceTime,
    expirationTime,
    type,
  })
  const payload = Payment.fromRaw(payment)

  const paymentRef = await getCollection(thirdPartyPayment.orderId)
  await paymentRef.set(payload)

  return payload
}

const fetch = async (courseId: string, userId?: string) => {
  const params = [where('courseId', '==', courseId)]
  if (userId) {
    params.push(where('userId', '==', userId))
  }

  const docs = await getQuerySnapshot(COLLECTION_KEY, ...params).get()

  return docs.docs.map(doc => Payment.fromRaw({ ...doc.data(), id: doc.id }))
}

const update = async (paymentId: string, status: PaymentStatuses = PaymentStatuses.REJECTED) => {
  const ref = getCollection(paymentId)
  const data = await ref.get()

  if (!data.exists()) return null

  const payload = { ...data.data(), status }
  await ref.set(payload)

  return payload
}

const PaymentsApi = {
  createNewPayment,
  fetch,
  update,
}

export { PaymentsApi }
