import classNames from 'classnames'
import React, { FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import { ChapterModal } from '~/modals/chapter-modal'
import { Chapter, Course } from '~/types/course'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, List, ListItemAvatar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(to right, rgba(161, 196, 253, 0.3), rgba(194, 233, 251, 0.3))',
  },
})

interface ChaptersListProps {
  course: Course
  onSelectChapter: (chapter: string) => void
  onDeleteChapter: (id: string) => void
  onUpdateChapter: (chapter: Chapter) => void
  selectedChapterId?: string
}

export const ChaptersList: FunctionComponent<ChaptersListProps> = React.memo(
  ({ course, onSelectChapter, selectedChapterId, onDeleteChapter, onUpdateChapter }) => {
    const classes = useStyles()
    const { chapters = [] } = course

    const [activeChapter, setActiveChapter] = useState<Chapter>()
    const [chapterModalIsVisible, setChapterModalIsVisible] = useState(false)

    const handleCloseChapterModal = useCallback(() => {
      setChapterModalIsVisible(false)
    }, [])

    const handleEditChapterButtonClick = useCallback((chapter: Chapter) => {
      setChapterModalIsVisible(true)
      setActiveChapter(chapter)
    }, [])

    return (
      <>
        {chapterModalIsVisible && (
          <ChapterModal
            onSave={onUpdateChapter}
            chapter={activeChapter}
            onClose={handleCloseChapterModal}
            isOpen={chapterModalIsVisible}
          />
        )}
        <List>
          {chapters?.map(chapter => (
            <ListItem
              button
              component="a"
              href="#"
              key={chapter.id}
              onClick={() => onSelectChapter(chapter.id)}
              className={classNames({ [classes.root]: selectedChapterId === chapter.id })}
            >
              <ListItemAvatar>
                <Image as="img" src={chapter?.imageUrl ?? ''} />
              </ListItemAvatar>
              <ListItemText primary={chapter.shortName} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleEditChapterButtonClick(chapter)} size="large">
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => onDeleteChapter(chapter.id)} size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </>
    )
  },
)

ChaptersList.displayName = 'ChaptersList'

const Image = styled(Box)`
  border-radius: 4px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-right: 8px;
`
