import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { firebaseLogout } from '~/infra/firebase/auth'
import { userStore } from '~/store'

import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'

import { Logo } from './logo'

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleLogout = useCallback(() => {
    firebaseLogout()
    handleClose()
    window.location.reload()
  }, [handleClose])

  const { displayName, email, photoURL } = userStore.currentUser || {}

  return (
    <HeaderContainer as="header">
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px={2} py={2}>
        <Link to="/">
          <Logo />
        </Link>
        {userStore.isAuthenticated ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2" style={{ maxWidth: 100 }}>
              {displayName || email}
            </Typography>
            <ProfileIcon onClick={handleOpen}>
              {photoURL ? (
                <img style={{ width: 34, height: 34 }} src={photoURL} alt={displayName || email || ''} />
              ) : (
                <div style={{ width: 34, height: 34 }}></div>
              )}
            </ProfileIcon>
          </Box>
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Button onClick={() => window.location.reload()}>
              <Typography style={{ maxWidth: 100 }}>Log in</Typography>
            </Button>
          </Box>
        )}
        <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </Menu>
      </Box>
    </HeaderContainer>
  )
}

const HeaderContainer = styled(Box)`
  background-color: #fff;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.05);
`

const ProfileIcon = styled(Button)`
  margin-left: 16px !important;
  background-color: #ffecb8;
  border-radius: 50% !important;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  min-width: 0 !important;
  width: 34px;
  height: 34px;
`
