import { DragAndDropOption } from '~/types/course'

export function removeCardFromColumn(state: DragAndDropOption, id: string, column: 'left' | 'right' | 'choose-column') {
  return state.columns[column].correctCardIds.filter(cardId => cardId !== id)
}

/**
 * Удалеям карточку из левого или правого колонка, если там есть
 * @param state DragAndDropOption
 * @param id card id
 * @returns the state in which the transferred card is removed from the left or right column
 */
export function removeCardFromLeftAndRightColumns(state: DragAndDropOption, id: string) {
  const isCardInColumnLeft = state?.columns?.left?.correctCardIds.includes(id)
  const isCardInColumnRight = state?.columns?.right?.correctCardIds.includes(id)

  if (isCardInColumnLeft) {
    state.columns.left.correctCardIds = removeCardFromColumn(state, id, 'left')
  }

  if (isCardInColumnRight) {
    state.columns.right.correctCardIds = removeCardFromColumn(state, id, 'right')
  }

  return state
}
