import { getUniqueId } from '~/helpers/id'
import { getServerTimestamp, FieldValue, Timestamp } from '~/infra/firebase/firestore'

export type CourseUsersMetaType = {
  id: string
  courseId: string
  firstName: string
  lastName?: string
  role?: string
  description?: string
  avatar?: string
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp
}

export class UserMeta {
  id: string
  courseId: string
  firstName: string
  lastName?: string
  role?: string
  description?: string
  avatar?: string
  createdAt?: FieldValue | Timestamp
  updatedAt?: FieldValue | Timestamp

  constructor(userMeta: CourseUsersMetaType) {
    const updatedAt = getServerTimestamp()

    this.id = userMeta.id ?? getUniqueId()
    this.courseId = userMeta.courseId ?? ''
    this.firstName = userMeta.firstName ?? ''
    this.lastName = userMeta.lastName ?? ''
    this.role = userMeta.role ?? ''
    this.description = userMeta.description ?? ''
    this.avatar = userMeta.avatar ?? ''
    this.createdAt = userMeta.createdAt ?? updatedAt
    this.updatedAt = updatedAt
  }

  static toRaw(userMeta: CourseUsersMetaType) {
    return {
      id: userMeta.id,
      courseId: userMeta.courseId,
      firstName: userMeta.firstName,
      lastName: userMeta.lastName,
      role: userMeta.role,
      description: userMeta.description,
      avatar: userMeta.avatar,
      createdAt: userMeta.createdAt,
      updatedAt: userMeta.updatedAt,
    }
  }

  static fromRaw(rawUserMeta: any) {
    return {
      id: rawUserMeta.id,
      courseId: rawUserMeta.courseId,
      firstName: rawUserMeta.firstName,
      lastName: rawUserMeta.lastName,
      role: rawUserMeta.role,
      description: rawUserMeta.description,
      avatar: rawUserMeta.avatar,
      createdAt: rawUserMeta.createdAt,
      updatedAt: rawUserMeta.updatedAt,
    }
  }
}
