import React from 'react'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

export type MoveMessageProps = {
  onClickUp: () => void
  onClickDown: () => void
}

export const MoveMessage = ({ onClickUp, onClickDown }: MoveMessageProps) => (
  <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
    <Button onClick={onClickUp}>
      <ArrowUpwardIcon />
    </Button>
    <Button onClick={onClickDown}>
      <ArrowDownwardIcon />
    </Button>
  </ButtonGroup>
)
