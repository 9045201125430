import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useMemo, useState } from 'react'
import styled from 'styled-components'
import { SkillsList } from '~/components/skills-list'
import { SkillType, SKILL_NEST_TYPE } from '~/domain/skills'
import { SkillModal } from '~/modals/skill-modal'
import { skillStore } from '~/store'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button, FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material'

import { filterSkills } from './helpers'

export const SkillsListGroup: FunctionComponent = observer(() => {
  const [selectedKnowlegeAreaId, setSelectedKnowlegeAreaId] = useState<string>()
  const [selectedSkillGroupId, setSelectedSkillGroupId] = useState<string>()
  const [selectedSkillId, setSelectedSkillId] = useState<string>()
  const [selectedSkillType, setSelectedSkillType] = useState<SKILL_NEST_TYPE>(SKILL_NEST_TYPE.AREA)
  const [skillModalVisible, setSkillModalVisible] = useState<boolean>(false)
  const [skill, setSkill] = useState<SkillType | null>(null)
  const [activeChecked, setActiveChecked] = useState(true)
  const [archiveChecked, setArchiveChecked] = useState(false)

  const knowlegeAreas = toJS(skillStore.knowlegeAreas).filter(skill =>
    filterSkills(skill, activeChecked, archiveChecked),
  )
  const skillGroups = toJS(skillStore.skillGroups)
  const skills = toJS(skillStore.thirdLevelSkills)
  const parentSkillsWithFetchedChildren = toJS(skillStore.parentSkillsWithFetchedChildren)

  const skillGroupsInKnowlegeArea = useMemo(() => {
    return selectedKnowlegeAreaId
      ? skillGroups?.filter(
          skillGroup =>
            skillGroup.parentIds?.includes(selectedKnowlegeAreaId) &&
            filterSkills(skillGroup, activeChecked, archiveChecked),
        )
      : []
  }, [activeChecked, archiveChecked, selectedKnowlegeAreaId, skillGroups])

  const skillsInSkillGroup = useMemo(() => {
    return selectedSkillGroupId
      ? skills?.filter(
          skill =>
            skill.parentIds?.includes(selectedSkillGroupId) && filterSkills(skill, activeChecked, archiveChecked),
        )
      : []
  }, [activeChecked, archiveChecked, selectedSkillGroupId, skills])

  const handleSelectKnowledgeArea = (id: string | undefined) => {
    if (selectedKnowlegeAreaId !== id) {
      setSelectedKnowlegeAreaId(id)
      setSelectedSkillGroupId('')
      if (id && !parentSkillsWithFetchedChildren.includes(id)) {
        skillStore.fetchChildrenSkillsByParentId(SKILL_NEST_TYPE.GROUP, id)
      }
    }
  }

  const handleSelectSkillGroup = (id: string | undefined) => {
    if (selectedSkillGroupId !== id) {
      setSelectedSkillGroupId(id)
      setSelectedSkillId('')
      if (id && !parentSkillsWithFetchedChildren.includes(id)) {
        skillStore.fetchChildrenSkillsByParentId(SKILL_NEST_TYPE.SKILL, id)
      }
    }
  }

  const handleOpenSkillModal = (skillType: SKILL_NEST_TYPE) => {
    setSelectedSkillType(skillType)
    setSkillModalVisible(true)
  }

  const handleCloseSkillModal = () => {
    setSkillModalVisible(false)
    setSkill(null)
  }

  const handleSaveSkill = (skill: SkillType) => {
    skillStore.updateSkill(skill)
  }

  const handleSelectSkill = (skill: SkillType | null) => {
    if (skill === null || skill?.type === null) return

    setSkill(skill)
    setSelectedSkillType(skill.type ?? SKILL_NEST_TYPE.AREA)
    setSkillModalVisible(true)
  }

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => setActiveChecked(e.target.checked)
  const handleArchiveChange = (e: React.ChangeEvent<HTMLInputElement>) => setArchiveChecked(e.target.checked)

  return (
    <>
      <Box py={2}>
        <FormLabel id="filter-label">Filter</FormLabel>
        <FormGroup row aria-labelledby="filter-label">
          <FormControlLabel
            control={<Checkbox checked={activeChecked} onChange={handleActiveChange} />}
            label="active"
          />
          <FormControlLabel
            control={<Checkbox checked={archiveChecked} onChange={handleArchiveChange} />}
            label="archive"
          />
        </FormGroup>
      </Box>
      <Box flex={1} display="flex" flexDirection="row">
        <Aside component="aside" flex={1} pr={2}>
          <Button
            size="small"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            fullWidth={true}
            onClick={() => handleOpenSkillModal(SKILL_NEST_TYPE.AREA)}
          >
            Add Knowlege Area
          </Button>
          <SkillsList
            skills={knowlegeAreas}
            selectedSkillId={selectedKnowlegeAreaId}
            onSelectSkill={handleSelectKnowledgeArea}
            onEditSkill={handleSelectSkill}
          />
        </Aside>

        <Aside component="aside" flex={1} px={2}>
          <Button
            size="small"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            fullWidth={true}
            onClick={() => handleOpenSkillModal(SKILL_NEST_TYPE.GROUP)}
          >
            Add Skill Group
          </Button>
          <SkillsList
            skills={skillGroupsInKnowlegeArea}
            selectedSkillId={selectedSkillGroupId}
            onSelectSkill={handleSelectSkillGroup}
            onEditSkill={handleSelectSkill}
          />
        </Aside>

        <Aside component="aside" flex={1} px={2}>
          <Button
            size="small"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            fullWidth={true}
            onClick={() => handleOpenSkillModal(SKILL_NEST_TYPE.SKILL)}
          >
            Add Skill
          </Button>
          <SkillsList
            skills={skillsInSkillGroup}
            selectedSkillId={selectedSkillId}
            onSelectSkill={setSelectedSkillId}
            onEditSkill={handleSelectSkill}
          />
        </Aside>

        <SkillModal
          isOpen={skillModalVisible}
          onSave={handleSaveSkill}
          onClose={handleCloseSkillModal}
          skillType={selectedSkillType}
          skill={skill}
        />
      </Box>
    </>
  )
})

const Aside = styled(Box)`
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.15);
`
