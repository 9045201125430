import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'

type Props = {
  chaptersLength: number
  paymentCount: number
  pricePerPayment: number
  modulesPerPayment: number
}

export const PartialPaymentsList = ({
  chaptersLength,
  paymentCount = 0,
  pricePerPayment,
  modulesPerPayment,
}: Props) => {
  const payments = useMemo(
    () =>
      Array.from({ length: paymentCount }, (_, i) => i + 1).map((_, index) => {
        const count = index + 1
        return `Payment #${count}: ${modulesPerPayment * count}/${chaptersLength}`
      }),
    [modulesPerPayment, chaptersLength, paymentCount],
  )

  const chaptersByPartialPayments = modulesPerPayment * paymentCount
  const chaptersLengthNotEqualPaymentsCount = chaptersByPartialPayments !== chaptersLength
  const differenceChaptersLength =
    chaptersByPartialPayments > chaptersLength
      ? chaptersByPartialPayments - chaptersLength
      : chaptersLength - chaptersByPartialPayments

  return (
    <Box my={2}>
      {chaptersLengthNotEqualPaymentsCount && (
        <Box mb={1}>
          <Typography color="red" variant="body2" component="p">
            Please note that the number of modules available for installment should not differ significantly.
          </Typography>
          <Typography variant="body2" component="p">
            Currently, the difference is{' '}
            <Typography color="red" variant="body1" component="span">
              {differenceChaptersLength}
            </Typography>
          </Typography>
        </Box>
      )}
      {payments?.map(text => (
        <Typography key={text} variant="body2" component="p">
          {text}
        </Typography>
      ))}
      <Box mt={1}>
        <Typography variant="body2" component="p">
          The total amount to be paid is: {paymentCount * pricePerPayment} USD for {chaptersByPartialPayments} chapters
          out of {chaptersLength} existing in the course.
        </Typography>
      </Box>
    </Box>
  )
}
