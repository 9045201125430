import React, { useEffect } from 'react'
import { DefaultLayout } from '~/layouts/default-layout'
import { taskStore } from '~/store'

import { Container } from '@mui/material'

import { AddNewTask } from './add-new-task'
import { TableTasks } from './list'

export const Tasks = () => {
  useEffect(() => {
    taskStore.fetchTasks()
  }, [])

  return (
    <DefaultLayout>
      <Container maxWidth="md">
        <AddNewTask />
        <TableTasks />
      </Container>
    </DefaultLayout>
  )
}
