import React from 'react'

import { Box, BoxProps } from '@mui/material'

import { CustomButton, CustomButtonProps } from './button'

type Button = {
  containerBoxProps?: BoxProps
  containerInnerBoxProps?: BoxProps
  text?: string
  children?: React.ReactElement
  type?: 'button' | 'submit'
  disabled?: boolean
  onClick: (props: any) => void
} & CustomButtonProps

type Props = {
  buttons: Button[]
}

export const ButtonsGroup = ({ buttons, ...boxProps }: Props & BoxProps) => {
  return (
    <Box mx={-1} my={2} display="flex" justifyContent="flex-start" alignItems="center" {...boxProps}>
      {buttons.map(
        (
          { text, type, disabled, containerBoxProps, containerInnerBoxProps, children, onClick, ...buttonProps },
          index,
        ) => (
          <Box key={`${text}-${index}`} mb={2} mx={1} {...containerBoxProps}>
            <CustomButton type={type} onClick={onClick} disabled={disabled} {...buttonProps}>
              {text ? (
                <Box p={1} {...containerInnerBoxProps}>
                  {text}
                </Box>
              ) : (
                children
              )}
            </CustomButton>
          </Box>
        ),
      )}
    </Box>
  )
}
