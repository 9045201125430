import React from 'react'

import MoveDownIcon from '@mui/icons-material/MoveDown'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

export type MoveGroupProps = {
  onMoveToSeparateGroup: () => void
  onMoveGroupUp: () => void
  onMoveGroupDown: () => void
}

export const MoveGroup = ({ onMoveToSeparateGroup, onMoveGroupUp, onMoveGroupDown }: MoveGroupProps) => (
  <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
    <Button onClick={onMoveToSeparateGroup} startIcon={<MoveToInboxIcon />}>
      Move to separate group
    </Button>
    <Button onClick={onMoveGroupUp} startIcon={<MoveUpIcon />}>
      Mode to the group above
    </Button>
    <Button onClick={onMoveGroupDown} startIcon={<MoveDownIcon />}>
      Move to the group below
    </Button>
  </ButtonGroup>
)
