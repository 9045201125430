import React, { useCallback, useState } from 'react'
import { useCommonStores } from '~/store/common'
import { LessonGoalType } from '~/types/course'

import { Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

interface Props {
  goals: LessonGoalType[]
  onSave: (goalId: string) => void
}

export const CloseGoalModal = ({ goals, onSave }: Props) => {
  const {
    dialogStore: { closeDialog },
  } = useCommonStores()

  const [selectedGoal, setSelectedGoal] = useState('')

  const handleClose = useCallback(() => {
    setSelectedGoal('')
    closeDialog()
  }, [closeDialog])

  const handleSubmit = () => {
    onSave(selectedGoal)
    handleClose()
  }

  const goalItems = goals.map(item => (
    <MenuItem key={item.id} value={item.id}>
      {item.text}
    </MenuItem>
  ))

  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>Select a goal</InputLabel>
        <Select
          value={selectedGoal}
          label="goal"
          onChange={({ target }) => {
            setSelectedGoal(target.value)
          }}
          required
          variant="outlined"
        >
          {goalItems}
        </Select>
      </FormControl>
      <DialogActions style={{ marginTop: 20 }}>
        <Button onClick={handleSubmit} variant="contained" disabled={!selectedGoal}>
          Close a goal
        </Button>
      </DialogActions>
    </Box>
  )
}
