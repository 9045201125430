import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export type FirebaseUserType = firebase.User

const getFirebaseAuth = () => firebase.auth()
const getFirebaseUser = () => firebase.auth().currentUser
const getUserJwt = async () => await firebase.auth()?.currentUser?.getIdToken()
const firebaseLogout = () => firebase.auth().signOut()

const SignInProviders = {
  GoogleAuthProvider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  FacebookAuthProvider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  EmailAuthProvider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
}

const onUserSignIn = (subscriber: (authUser: FirebaseUserType) => any) => {
  const firebaseAuth = getFirebaseAuth()

  firebaseAuth.onAuthStateChanged((authUser: any) => authUser !== null && subscriber(authUser))
}

const onUserSignOut = (subscriber: () => any) => {
  const firebaseAuth = getFirebaseAuth()

  firebaseAuth.onAuthStateChanged((authUser: any) => authUser === null && subscriber())
}

const Auth = {
  onUserSignIn,
  onUserSignOut,
}

export { getFirebaseAuth, getFirebaseUser, firebaseLogout, getUserJwt, SignInProviders, Auth }
