import { Specialization } from '~/domain/specializations'
import { dbProduction } from '~/infra/firebase/firebase'
import { getCollectionRef, getQuerySnapshot } from '~/infra/firebase/firestore'

import { Firestore } from '@firebase/firestore'

const SPECIALIZATIONS_COLLECTION_KEY = 'specializations'

const getSpecializationsCollection = (skillId?: string, db?: Firestore) =>
  getCollectionRef(SPECIALIZATIONS_COLLECTION_KEY, {
    id: skillId,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => Specialization.fromRaw(snapshot.data()),
      toFirestore: (specialization: Specialization) => Specialization.toRaw(specialization),
    },
  })

const fetchSpecialization = async (specializationId: string) => {
  const specialization = await getSpecializationsCollection(specializationId).get()

  return specialization.exists() ? specialization.data() : null
}

const createOrUpdateSpecializationHandler = async (specialization: Specialization, db?: Firestore) => {
  const specializationDocument = getSpecializationsCollection(specialization.id, db)
  await specializationDocument.set(specialization)

  return specialization
}

const createOrUpdateSpecialization = async (specialization: Specialization) =>
  await createOrUpdateSpecializationHandler(specialization)

const createOrUpdateSpecializationInProductionDB = async (specialization: Specialization) =>
  await createOrUpdateSpecializationHandler(specialization, dbProduction)

const getAllSpecializations = async () => {
  const specializationDocs = await getQuerySnapshot(SPECIALIZATIONS_COLLECTION_KEY).get()

  return specializationDocs.docs.map(doc => Specialization.fromRaw({ ...doc.data(), id: doc.id }))
}

export const SpecializationApi = {
  fetchSpecialization,
  createOrUpdateSpecialization,
  getAllSpecializations,
  /**
   * production
   */
  createOrUpdateSpecializationInProductionDB,
}
