import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DefaultLayout } from '~/layouts/default-layout'
import { paymentLinksStore } from '~/store'

import { Container, Typography } from '@mui/material'

import { AddNewLink } from './add-new-link'
import { PaymentLinksTable } from './payment-links-table'

export const PaymentLinksPage = () => {
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  useEffect(() => {
    paymentLinksStore.fetchLinks(id || '')
  }, [id])

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`List with "${course?.name}"`} />}
    >
      <Container maxWidth="lg">
        <Typography>Links list</Typography>
        <AddNewLink course={course} />
        <PaymentLinksTable />
      </Container>
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
