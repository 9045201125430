import React, { FunctionComponent, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ButtonsGroup } from '~/components/buttons-group'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'
import { Chapter } from '~/types/course'

import { Box, Dialog, TextField } from '@mui/material'

interface ChapterModalProps {
  onClose: () => void
  isOpen: boolean
  chapter?: Chapter
  onSave: (chapter: Chapter) => void
}

export const ChapterModal: FunctionComponent<ChapterModalProps> = React.memo(props => {
  const { onClose, isOpen, chapter, onSave } = props
  const id = chapter?.id ?? getUniqueId()
  const lessons = useMemo(() => chapter?.lessons ?? [], [chapter?.lessons])
  const isEdit = Boolean(chapter)

  const {
    value: name,
    handleSetValue: handleSetName,
    isValid: nameIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: chapter?.shortName })

  const {
    value: chapterVersion,
    handleSetValue: handleSetChapterVersion,
    isValid: chapterVersionIsValid,
  } = useInput({ defaultValue: `${chapter?.chapterVersion ?? '0.0.1'}`, validators: containValueValidator })

  const {
    value: imageUrl,
    handleSetValue: handleSetImageUrl,
    isValid: imageUrlIsValid,
  } = useInput({ defaultValue: chapter?.imageUrl })

  const isSaveEnabled = useMemo(() => nameIsValid && chapterVersionIsValid, [chapterVersionIsValid, nameIsValid])

  const handleClose = useCallback(() => {
    handleSetName('')
    handleSetImageUrl('')
    handleSetChapterVersion('0.0.1')

    onClose()
  }, [handleSetName, handleSetImageUrl, handleSetChapterVersion, onClose])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newChapter: Chapter = {
        id,
        name,
        imageUrl,
        shortName: name,
        lessons,
        chapterVersion,
      }

      onSave(newChapter)

      return handleClose()
    },
    [isSaveEnabled, id, name, imageUrl, lessons, chapterVersion, onSave, handleClose],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: handleClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Chapter',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [handleClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <CourseImage {...(imageUrl ? { as: 'img', src: imageUrl } : {})} />
          <Box py={2}>
            <TextField
              fullWidth
              label="Chapter Image"
              value={imageUrl}
              onChange={handleSetImageUrl}
              error={!imageUrlIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Chapter Title"
              required
              value={name}
              onChange={handleSetName}
              error={!nameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Version"
              required
              value={chapterVersion}
              onChange={handleSetChapterVersion}
              error={!chapterVersionIsValid}
              variant="outlined"
            />
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})

const CourseImage = styled(Box)`
  border-radius: 24px;
  overflow: hidden;
  max-height: 375px;
  width: 100%;
`
