import React, { createContext, FunctionComponent, useEffect } from 'react'
import { useAuthors } from '~/hooks/use-authors'

type AuthorsContextType = ReturnType<typeof useAuthors>

const authorsContext = createContext({} as AuthorsContextType)

const { Provider } = authorsContext

const AuthorsProvider: FunctionComponent = ({ children }) => {
  const authorsValue = useAuthors()

  const { getAuthors } = authorsValue

  useEffect(() => {
    getAuthors()
  }, [getAuthors])

  return <Provider value={authorsValue}>{children}</Provider>
}

export { authorsContext, AuthorsProvider }
