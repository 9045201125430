import { useCallback, useState, useMemo, useEffect } from 'react'

export type DefaultInputFieldProps = {
  value: string | undefined | null
  handleSetValue?: (str: string | undefined | null) => void
  setValue?: (str: string | undefined | null) => void
}

type ValidatorFunction = (value?: string) => string | boolean

type Props = {
  defaultChecked?: boolean
  defaultValue?: string[] | string | number | null
  validators?: ValidatorFunction[]
}

export const useInput = (props?: Props) => {
  const {
    defaultChecked = false,
    defaultValue = '',
    validators,
  } = useMemo<Props>(
    () =>
      props || {
        defaultChecked: false,
        defaultValue: '',
        validators: [],
      },
    [props],
  )
  const [value, setValue] = useState<any>(defaultValue)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(defaultChecked)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (!validators || !Array.isArray(validators)) return () => {}

    let isValid = true,
      error = ''
    const results = validators
      .map(validateFn => {
        if (typeof validateFn === 'string') return validateFn

        const validationResult = validateFn(value || '')

        return typeof validationResult === 'string' ? validationResult : ''
      })
      .filter(message => message !== '')

    if (results.length) {
      isValid = false
      error = results[0] ?? ''
    }

    setIsValid(isValid)
    setError(error)

    return () => {}
  }, [validators, value])

  const handleFocus = useCallback(() => setIsFocus(true), [])
  const handleBlur = useCallback(() => setIsFocus(false), [])
  const handleSetValue = useCallback(e => setValue(e?.target ? e.target.value : e), [])
  const handleSetChecked = useCallback(e => setChecked(e?.target ? e.target.checked : e), [])

  return {
    value,
    disabled,
    isFocus,
    checked,
    isValid,
    error,
    setValue,
    setDisabled,
    setIsFocus,
    setChecked,
    handleFocus,
    handleBlur,
    handleSetValue,
    handleSetChecked,
  }
}
