export const getIsStringContainValue = (value?: string | number) => {
  const stringableValue = `${value}`

  try {
    const trimedValue = stringableValue?.trim()?.replace(/ /g, '')

    return !!trimedValue
  } catch (error) {
    console.error(error)

    return false
  }
}

export const containValueValidator = [
  (value?: string | number) => !getIsStringContainValue(value) && 'This field is required',
]

export const containValueNeedMoreThanZeroValidator = [
  (value?: string | number) => (value ? +`${value}` <= 0 && 'Value must be greater than 0' : ''),
]
