import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import { getUniqueId } from '~/helpers/id'

export enum UserDropletStatus {
  new = 'new',
  active = 'active',
  deleted = 'deleted',
  frozen = 'frozen',
}

enum NetworkAccessType {
  private = 'private',
  public = 'public',
}

type V4 = {
  ipAddress: string
  netmask: string
  gateway: string
  type: NetworkAccessType
}

type V6 = {
  ipAddress: string
  netmask: number
  gateway: string
  type: NetworkAccessType
}

export type Network = {
  v4: V4[]
  v6: V6[]
}

export type Credentials = {
  username: string
  password: string
}

export type UserDropletType = {
  id?: string
  dropletId: string
  status: UserDropletStatus
  courseDropletId: string
  userId: string
  networks: Network | null
  features?: string[] | null
  courseId: string
  credentials?: Credentials | null
  createdAt?: number
  updatedAt?: number
  lastTouchedTimeStamp: number | null
}

export class UserDroplet {
  id: string
  dropletId: string
  status: UserDropletStatus
  courseDropletId: string
  userId: string
  networks: Network | null
  features?: string[] | null
  courseId: string
  credentials?: Credentials | null
  createdAt?: number
  updatedAt?: number

  constructor(droplet: UserDropletType) {
    const updatedAt = Date.now()

    const { v4, v6 } = droplet.networks ?? {}

    const networks = {
      v4:
        v4?.map(item => ({
          ...item,
          ...mapKeys(item, (_, key) => camelCase(key)),
        })) ?? [],
      v6:
        v6?.map(item => ({
          ...item,
          ...mapKeys(item, (_, key) => camelCase(key)),
        })) ?? [],
    }
    this.id = droplet?.id ?? getUniqueId()
    this.dropletId = droplet.dropletId
    this.status = droplet.status
    this.userId = droplet.userId
    this.courseDropletId = droplet.courseDropletId
    this.networks = networks ?? null
    this.features = droplet.features ?? null
    this.credentials = droplet.credentials ?? null
    this.courseId = droplet.courseId
    this.createdAt = droplet.createdAt ?? updatedAt
    this.updatedAt = updatedAt
  }

  static fromRaw(raw: any) {
    return new UserDroplet(raw)
  }

  static toRaw(droplet: UserDropletType) {
    return Object.assign({}, droplet)
  }
}
