import { initializeApp } from 'firebase/app'
import firebase from 'firebase/compat/app'
import {
  FIREBASE_APP_ID,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BITBUCKET,
  FIREBASE_APP_ID_PRODUCTION,
  FIREBASE_API_KEY_PRODUCTION,
  FIREBASE_AUTH_DOMAIN_PRODUCTION,
  FIREBASE_MESSAGING_SENDER_ID_PRODUCTION,
  FIREBASE_PROJECT_ID_PRODUCTION,
  FIREBASE_STORAGE_BITBUCKET_PRODUCTION,
} from '~/config/env'

import { getFirestore } from '@firebase/firestore'

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BITBUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
}

const secondFirebaseConfig = {
  apiKey: FIREBASE_API_KEY_PRODUCTION,
  authDomain: FIREBASE_AUTH_DOMAIN_PRODUCTION,
  projectId: FIREBASE_PROJECT_ID_PRODUCTION,
  storageBucket: FIREBASE_STORAGE_BITBUCKET_PRODUCTION,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID_PRODUCTION,
  appId: FIREBASE_APP_ID_PRODUCTION,
}

export const isSecondFirebaseReady = () => Object.values(secondFirebaseConfig).every(value => !!value)

class FirebaseProvider {
  private app: any = {}
  private static instance: FirebaseProvider

  constructor() {
    if (!!FirebaseProvider.instance) {
      return FirebaseProvider.instance
    }

    this.app = initializeApp(firebaseConfig)
    firebase.initializeApp(firebaseConfig)

    FirebaseProvider.instance = this

    return this
  }

  getFirestore() {
    return getFirestore(this.app)
  }

  initFirebaseApp() {
    return () => {
      if (!this?.app) {
        this.app = initializeApp(firebaseConfig)
        firebase.initializeApp(firebaseConfig)
      }
    }
  }
}

class SecondFirebaseProvider {
  private app: any = {}
  private static instance: SecondFirebaseProvider

  constructor() {
    if (!isSecondFirebaseReady()) {
      return this
    }

    if (!!SecondFirebaseProvider.instance) {
      return SecondFirebaseProvider.instance
    }

    this.app = initializeApp(secondFirebaseConfig, 'production')

    SecondFirebaseProvider.instance = this

    return this
  }

  getFirestore() {
    if (!isSecondFirebaseReady()) {
      return undefined
    }

    return getFirestore(this.app)
  }

  initFirebaseApp() {
    return () => {
      if (!this?.app && isSecondFirebaseReady()) {
        this.app = initializeApp(secondFirebaseConfig, 'production')
      }
    }
  }
}

export const firebaseProvider = new FirebaseProvider()
export const db = firebaseProvider.getFirestore()
export const initFirebaseApp = firebaseProvider.initFirebaseApp()

export const secodFirebaseProvider = new SecondFirebaseProvider()
export const dbProduction = secodFirebaseProvider.getFirestore()
export const initProductionFirebaseApp = secodFirebaseProvider.initFirebaseApp()
