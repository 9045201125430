import { useState, useCallback } from 'react'
import { AUTHORS_KEY } from '~/constants/storage-keys'
import { safetyParseJson } from '~/helpers/json'
import { Author } from '~/modals/author-modal'
import { Storage } from '~/services/storage'

export const useAuthors = () => {
  const [authors, setAuthors] = useState<Author[]>([])

  const handleSaveAuthor = useCallback((payload: Author) => {
    setAuthors(state => {
      const authors = [...state, payload]

      Storage.set(AUTHORS_KEY, authors)

      return authors
    })
  }, [])

  const getAuthors = useCallback(() => {
    const authors = Storage.get(AUTHORS_KEY)
    const res = safetyParseJson(authors)

    setAuthors(res || [])
  }, [])

  return {
    authors,
    getAuthors,
    handleSaveAuthor,
  }
}
