import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { CustomButton } from '~/components/button'
import { InputSelect } from '~/components/input-select'
import { authorsContext } from '~/contexts/authors'
import { Author, AuthorModal } from '~/modals/author-modal'

import { Box } from '@mui/material'

interface AuthorMetaFieldProps {
  authorValue: Author
  handleSetAuthor: (author: Author) => void
}

export const AuthorMetaField: FC<AuthorMetaFieldProps> = ({ authorValue, handleSetAuthor }) => {
  const [authorModalIsVisible, setAuthorModalVisible] = useState(false)

  const { authors, handleSaveAuthor } = useContext(authorsContext)

  const authorsOptions = useMemo(() => {
    return authors.map(item => ({
      text: `${item.firstName} ${item.lastName}, ${item.description?.slice(0, 15)}...`,
      value: `${item.firstName} ${item.lastName}`,
    }))
  }, [authors])

  const handleOpenAuthorModal = useCallback(() => {
    setAuthorModalVisible(true)
  }, [])

  const handleCloseAuthorModal = useCallback(() => {
    setAuthorModalVisible(false)
  }, [])

  return (
    <>
      <Box py={2}>
        <InputSelect value={authorValue} onChange={handleSetAuthor} label="Author" options={authorsOptions} />
        <Box py={2}>
          <CustomButton type="button" onClick={handleOpenAuthorModal} variant="contained" color="blue">
            Add Author
          </CustomButton>
        </Box>
      </Box>
      <AuthorModal onSave={handleSaveAuthor} onClose={handleCloseAuthorModal} isOpen={authorModalIsVisible} />
    </>
  )
}
