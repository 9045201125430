import { getUniqueId } from '~/helpers/id'

export type UserHistoryItemType = {
  chapterId: string
  lessonId: string
  lastMessageId: string
  createdAt?: number
  updatedAt?: number
}

export type UserHistoryType = {
  id?: string
  userId: string
  courseId: string
  histories: UserHistoryItemType[]
  createdAt?: number
  updatedAt?: number
}

export class UserHistory {
  id: string
  userId: string
  courseId: string
  histories: UserHistoryItemType[]
  createdAt?: number
  updatedAt?: number

  constructor(userHistory: UserHistoryType & { id?: string }) {
    const updatedAt = Date.now()

    this.id = userHistory.id ?? getUniqueId()
    this.userId = userHistory.userId
    this.courseId = userHistory.courseId
    this.histories = userHistory.histories ?? []
    this.createdAt = userHistory.createdAt ?? updatedAt
    this.updatedAt = userHistory.updatedAt ?? updatedAt
  }

  static toRaw(item: UserHistoryType) {
    return new UserHistory(item)
  }

  static fromRaw(item: UserHistory): UserHistoryType {
    return Object.assign({}, item)
  }
}
