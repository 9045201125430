import { getUniqueId } from '~/helpers/id'
import { IMessageGroup, LessonGoalType, LessonType } from '~/types/course'

export class Lesson implements LessonType {
  id: string
  name: string
  messages?: IMessageGroup[]
  goals?: LessonGoalType[]
  isOpenedAccess?: boolean
  description?: string
  imageUrl?: string | null
  type: 'CONTENT'
  duration?: number

  constructor(lesson: LessonType) {
    this.id = lesson?.id ?? getUniqueId()
    this.name = lesson?.name ?? ''
    this.messages = lesson?.messages ?? []
    this.goals = lesson?.goals ?? []
    this.isOpenedAccess = lesson?.isOpenedAccess ?? false
    this.description = lesson?.description ?? ''
    this.imageUrl = lesson?.imageUrl ?? null
    this.type = lesson?.type
    this.duration = lesson?.duration ?? 0
  }

  static fromRaw(rawCourse: any) {
    return new Lesson(rawCourse)
  }

  static toRaw(course: LessonType) {
    return Object.assign({}, course)
  }
}
