import React, { HTMLAttributes } from 'react'
import { IntrinsicElementsKeys } from 'styled-components'

import Button from '@mui/material/Button'

type SliderButtonProps = {
  dir: 'prev' | 'next'
  onClick?: () => void
} & HTMLAttributes<IntrinsicElementsKeys>

export const SliderButton: React.FC<SliderButtonProps> = ({ dir, onClick }) => {
  return (
    <Button type="button" onClick={onClick}>
      {dir === 'prev' ? 'Prev' : 'Next'}
    </Button>
  )
}
