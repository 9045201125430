import { SkillType } from '~/domain/skills'

export const filterSkills = (skill: SkillType, activeChecked: boolean, archiveChecked: boolean) => {
  // user has selected both active and archived skills
  const userCheckedActiveAndArchive = activeChecked && archiveChecked

  //user has selected ony active skills
  const userCheckedOnlyActive = activeChecked && !archiveChecked

  // user has selected only archived skills
  const userCheckedOnlyArchive = !activeChecked && archiveChecked

  if (userCheckedActiveAndArchive) return true
  if (userCheckedOnlyActive) return skill.isVisible
  if (userCheckedOnlyArchive) return !skill.isVisible

  // user did not select any of the options, so we do not select any of the skills
  return false
}
