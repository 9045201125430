import { makeAutoObservable, flow, toJS } from 'mobx'
import { newPaymentParams, PaymentsApi } from '~/api/firebase/payment'
import { Payment, PaymentStatuses } from '~/domain/payment/payment'

class PaymentsStore {
  payments: Payment[] = []
  isFetching = false
  isFetched = false
  isCreating = false
  isUpdating = false

  constructor() {
    makeAutoObservable(this)
  }

  fetch = flow(function* (this: PaymentsStore, courseId: string, userId?: string) {
    this.isFetching = true
    try {
      this.payments = yield PaymentsApi.fetch(courseId, userId)
      this.isFetched = true
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isFetching = false
    }
  })

  create = flow(function* (this: PaymentsStore, data: newPaymentParams) {
    this.isCreating = true
    try {
      const payload = yield PaymentsApi.createNewPayment(data)
      this.payments = [...toJS(this.payments), payload]
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isCreating = false
    }
  })

  update = flow(function* (this: PaymentsStore, paymentId: string, status: PaymentStatuses = PaymentStatuses.REJECTED) {
    this.isUpdating = true
    try {
      const updated = yield PaymentsApi.update(paymentId, status)

      const payments = toJS(this.payments).map(item => {
        if (item.orderId === paymentId) {
          return updated
        }

        return item
      })
      this.payments = payments
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdating = false
    }
  })
}

export { PaymentsStore }
