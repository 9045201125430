import { makeAutoObservable, flow, toJS } from 'mobx'
import { SkillApi } from '~/api/skills'
import { Skill, SkillType, SKILL_NEST_TYPE } from '~/domain/skills'

import { mergeFetchedAndStoreSkills } from './utils'

class SkillStore {
  skills: SkillType[] = []
  parentSkillsWithFetchedChildren: string[] = []
  skillTypesWithFetchedSkills: SKILL_NEST_TYPE[] = []
  lastSkill: SkillType | undefined = undefined
  isFetchingSkills = false
  isSkillsFetched = false
  isCreatingSkills = false
  isUpdatingSkills = false

  constructor() {
    makeAutoObservable(this)
  }

  get skillsForOptions() {
    if (!this.isSkillsFetched) {
      return []
    }
    return this.skills.map(skill => skill.name ?? 'Skill name is not set')
  }

  get knowlegeAreas() {
    return this.skills.filter(skill => skill.type === 'AREA')
  }
  get skillGroups() {
    return this.skills.filter(skill => skill.type === 'GROUP')
  }
  get thirdLevelSkills() {
    return this.skills.filter(skill => skill.type === 'SKILL')
  }

  fetchAllSkills = flow(function* (this: SkillStore) {
    this.isFetchingSkills = true
    try {
      this.skills = yield SkillApi.getAllSkills()
      this.isFetchingSkills = true
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetchingSkills = false
      this.isSkillsFetched = true
    }
  })

  fetchSkills = flow(function* (this: SkillStore, limit: number) {
    this.isFetchingSkills = true
    try {
      this.skills = yield SkillApi.getSkills(limit).then(value => value)
      this.lastSkill = this.skills.length === limit ? this.skills.pop() : undefined
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetchingSkills = false
    }
  })

  fetchAllSkillsByType = flow(function* (this: SkillStore, skillType: SKILL_NEST_TYPE) {
    this.isFetchingSkills = true
    try {
      const fetchedSkills = yield SkillApi.getSkillsByType(skillType)
      this.skills = mergeFetchedAndStoreSkills(fetchedSkills, toJS(this.skills))
      this.skillTypesWithFetchedSkills.push(skillType)
      this.isFetchingSkills = true
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetchingSkills = false
      this.isSkillsFetched = true
    }
  })

  fetchChildrenSkillsByParentId = flow(function* (this: SkillStore, childSkillType: SKILL_NEST_TYPE, parentId: string) {
    try {
      const childrenSkills: Skill[] = yield SkillApi.getChildSkillsByParentId(childSkillType, parentId)
      this.skills = mergeFetchedAndStoreSkills(childrenSkills, toJS(this.skills))
      this.isFetchingSkills = true
      this.parentSkillsWithFetchedChildren.push(parentId)
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetchingSkills = false
    }
  })

  fetchNextSkills = flow(function* (this: SkillStore, lastSkill: SkillType, limit: number) {
    this.isFetchingSkills = true
    try {
      const newSkills = yield SkillApi.getNextPageSkills(lastSkill, limit).then(value => value)
      this.lastSkill = newSkills.length === limit ? newSkills.pop() : undefined
      this.skills = [...this.skills, ...newSkills]
    } catch (e) {
      console.error(e)
    } finally {
      this.isFetchingSkills = false
    }
  })

  updateSkill = flow(function* (this: SkillStore, updatedSkill: SkillType) {
    this.isUpdatingSkills = true
    try {
      const updatedRawSkill = new Skill(updatedSkill)
      yield SkillApi.createOrUpdateSkill(updatedRawSkill)

      const skillIndex = toJS(this.skills).findIndex(skill => skill.id === updatedSkill.id)

      if (skillIndex > -1) {
        this.skills = toJS(this.skills).map(skill => (skill.id === updatedRawSkill.id ? updatedRawSkill : skill))
      } else {
        this.skills.push(updatedRawSkill)
      }
    } catch (e) {
      // TODO: Display notification with error message
      console.error(e)
    } finally {
      this.isUpdatingSkills = false
    }
  })
}

export { SkillStore }
