import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SkillPlaceholder from '~/assets/common/default-skill-placeholder.png'
import { SkillType } from '~/domain/skills'

import EditIcon from '@mui/icons-material/Edit'
import { Box, List, ListItemAvatar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(to right, rgba(161, 196, 253, 0.3), rgba(194, 233, 251, 0.3))',
  },
  isNotVisible: {
    background: 'silver',
  },
})

interface SkillsProps {
  skills?: SkillType[]
  onSelectSkill: (skillId: string | undefined) => void
  selectedSkillId?: string
  onEditSkill: (skill: SkillType | null) => void
}

export const SkillsList: FunctionComponent<SkillsProps> = React.memo(
  ({ skills, onSelectSkill, selectedSkillId, onEditSkill }) => {
    const classes = useStyles()
    return (
      <>
        {skills && (
          <List>
            {skills.map(skill => (
              <ListItem
                button
                key={skill.id}
                onClick={() => onSelectSkill(skill.id)}
                className={classNames(
                  { [classes.root]: selectedSkillId === skill.id },
                  { [classes.isNotVisible]: !skill.isVisible },
                )}
              >
                <ListItemAvatar>
                  <Image as="img" src={skill?.imageUrl ?? SkillPlaceholder} />
                </ListItemAvatar>
                <ListItemText primary={skill.name} secondary={skill.description} />
                <IconButton edge="end" size="large" onClick={() => onEditSkill(skill)}>
                  <EditIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </>
    )
  },
)

SkillsList.displayName = 'SkillsList'

const Image = styled(Box)`
  border-radius: 4px;
  overflow: hidden;
  width: 24px;
  height: 24px;
  object-fit: cover;
`
