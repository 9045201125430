import React, { useCallback, useState } from 'react'
import { Task, TaskType } from '~/domain/task'
import { TaskModal } from '~/modals/task-modal'
import { taskStore } from '~/store'

import { Box, Button } from '@mui/material'

export const AddNewTask = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false)

  const handleUpdate = useCallback((updateTask: TaskType) => {
    const task = new Task(updateTask)

    taskStore.createTask(task)
  }, [])

  const handleOpenTaskModal = () => {
    setModalIsVisible(true)
  }

  const handleCloseTaskModal = () => {
    setModalIsVisible(false)
  }

  return (
    <Box my={2}>
      <Button variant="contained" color="primary" onClick={handleOpenTaskModal}>
        Add coding task
      </Button>
      {modalIsVisible && <TaskModal onSave={handleUpdate} onClose={handleCloseTaskModal} isOpen={modalIsVisible} />}
    </Box>
  )
}
