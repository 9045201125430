import { UserMeta, CourseUsersMetaType } from '~/domain/usersMeta'
import { dbProduction } from '~/infra/firebase/firebase'
import { getCollectionRef, getQuerySnapshot, getQuerySnapshotWithLimit } from '~/infra/firebase/firestore'

import { where, Firestore } from '@firebase/firestore'

const USERS_META_COLLECTION_KEY = 'meta-users'

const getUsersMetaCollection = (userMetaId?: string, db?: Firestore) =>
  getCollectionRef(USERS_META_COLLECTION_KEY, {
    id: userMetaId,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => UserMeta.fromRaw(snapshot.data()),
      toFirestore: (userMeta: UserMeta) => UserMeta.toRaw(userMeta),
    },
  })

const fetchUserMeta = async (userMetaId: string) => {
  const userMeta = await getUsersMetaCollection(userMetaId).get()

  return userMeta.exists() ? userMeta.data() : null
}

const createOrUpdateUserMetaHandler = async (userMeta: UserMeta, db?: Firestore) => {
  const userMetaDocument = getUsersMetaCollection(userMeta.id, db)
  await userMetaDocument.set(userMeta)

  return userMeta
}

const createOrUpdateUserMeta = async (userMeta: UserMeta) => await createOrUpdateUserMetaHandler(userMeta)

const createOrUpdateUserMetaInProductionDB = async (userMeta: UserMeta) =>
  await createOrUpdateUserMetaHandler(userMeta, dbProduction)

const getUsersMeta = async (courseId: string) => {
  const usersMetaDocs = await getQuerySnapshot(USERS_META_COLLECTION_KEY, where('courseId', '==', courseId)).get()

  return usersMetaDocs.docs.map(doc => UserMeta.fromRaw({ ...doc.data(), id: doc.id }))
}

const getUsersMetaWithLimit = async (limit: number, courseId: string, lastUserMeta?: CourseUsersMetaType) => {
  const userMetaSnapshot = lastUserMeta ? await getUsersMetaCollection(lastUserMeta.id).get() : null

  const usersMetaDocs = await getQuerySnapshotWithLimit(
    USERS_META_COLLECTION_KEY,
    'id',
    limit,
    userMetaSnapshot,
    where('courseId', '==', courseId),
  ).get()

  return usersMetaDocs.docs.map(doc => UserMeta.fromRaw({ ...doc.data(), id: doc.id }))
}

export const UsersMetaApi = {
  fetchUserMeta,
  createOrUpdateUserMeta,
  getUsersMeta,
  getUsersMetaWithLimit,

  /**
   * production methods
   */
  createOrUpdateUserMetaInProductionDB,
}
