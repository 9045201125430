import React from 'react'
import { DefaultLayout } from '~/layouts/default-layout'

import { Container, Typography } from '@mui/material'

import { TableSpecializations } from './list'

export const Specialization = () => (
  <DefaultLayout>
    <Container maxWidth="md">
      <Typography>Specialization</Typography>
      <TableSpecializations />
    </Container>
  </DefaultLayout>
)
