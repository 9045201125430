import { getUniqueId } from '~/helpers/id'
import { PriceType } from '~/types/course'

export enum LinkPaymentStatus {
  ACTIVE = 'ACTIVE',
  USED = 'USED',
  DISABLED = 'DISABLED',
  PROSECCING = 'PROSECCING',
}

export type PaymentLinksType = {
  id?: string
  courseId: string
  price: PriceType
  status: LinkPaymentStatus
  chapterId?: string | null
  usedUserId?: string | null
  usedAt?: number | null
  createdAt?: number | null
  updatedAt?: number | null
}
export class PaymentLinks {
  id: string
  courseId: string
  price: PriceType
  status: LinkPaymentStatus
  chapterId?: string | null
  usedUserId?: string | null
  usedAt?: number | null
  createdAt?: number | null
  updatedAt?: number | null

  constructor(payment: PaymentLinksType) {
    const createdAt = Date.now()

    this.id = payment.id ?? getUniqueId()
    this.courseId = payment.courseId
    this.price = payment.price
    this.chapterId = payment.chapterId ?? null
    this.status = payment.status ?? null
    this.usedUserId = payment.usedUserId ?? null
    this.usedAt = payment.usedAt ?? null
    this.createdAt = payment.createdAt ?? createdAt
    this.updatedAt = createdAt
  }

  static toRaw(item: PaymentLinksType) {
    return new PaymentLinks(item)
  }

  static fromRaw(item: any): PaymentLinksType {
    return Object.assign({}, item)
  }
}
