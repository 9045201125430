/**
 * Конвертирует обычный `url` в `api url`
 * @param url `url` ввиде https://github.com/fullstack-development/developers-roadmap/blob/master/backend/junior-1/haskell.md
 * @returns преобразованный `url` в https://api.github.com/repos/fullstack-development/developers-roadmap/contents/backend/junior-1/haskell.md
 */

export const convertGithubUrlToApiUrl = (url: string) => {
  const { protocol, host, pathname } = new URL(url)

  // конвертируем pathname в массив
  // from: '/fullstack-development/developers-roadmap/blob/master/backend/junior-1/haskell.md'
  // to: ['fullstack-development', 'developers-roadmap', 'blob', 'master', 'backend', 'junior-1', 'haskell.md']
  const path = pathname.slice(1).split('/')

  const userName = path.shift()
  const repo = path.shift()

  // вытаскиваем путь до файл
  // from: ['blob', 'master', 'backend', 'junior-1', 'haskell.md']
  // to: 'backend/junior-1/haskell.md'

  const pathName = path.slice(2).join('/')

  // В итоге получается вот такой `url`:
  // https://api.github.com/repos/fullstack-development/developers-roadmap/contents/backend/junior-1/haskell.md
  return `${protocol}//api.${host}/repos/${userName}/${repo}/contents/${pathName}`
}
