import React, { FunctionComponent, useCallback, useContext, useRef, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CustomButton } from '~/components/button'
import ChapterEdit from '~/components/chapter-edit'
import { ChaptersList } from '~/components/chapters-list'
import { coursesContext } from '~/contexts/courses'
import { useExport } from '~/hooks/use-export'
import { DefaultLayout } from '~/layouts/default-layout'
import { ChapterModal } from '~/modals/chapter-modal'
import { LessonModal } from '~/modals/lesson-modal'
import { Chapter, Course, LessonType } from '~/types/course'

import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

import { Header } from '../header'
import { LessonsList } from '../lessons-list'
import { SyncFireStoreBtn, SyncToProductionFirestoreBtn } from '../sync-firestore-btn'

interface EditorProps {
  course: Course
}

export const Editor: FunctionComponent<EditorProps> = ({ course }) => {
  const getButtons = (
    handleCreateChapter: () => void,
    handleCreateLesson: () => void,
    handleEdit: () => void,
    selectedChapterId: string | undefined,
  ) => [
    {
      text: 'Add Chapter',
      onPress: handleCreateChapter,
    },
    {
      text: 'Add Task',
      onPress: handleCreateLesson,
      disabled: Boolean(!selectedChapterId),
    },
    {
      text: 'Edit',
      onPress: handleEdit,
    },
  ]

  const { id } = course
  const history = useHistory()
  const { chapters } = course

  const [chapterModalIsVisible, setChapterModalIsVisible] = useState(false)
  const [lessonModalIsVisible, setLessonModalIsVisible] = useState(false)
  const [selectedChapterId, setSelectedChapterId] = useState<string>()
  const [selectedLessonId, setSelectedLessonId] = useState<string>()
  const { handleUpdateCourse } = useContext(coursesContext)

  const handleCloseChapterModal = useCallback(() => {
    setChapterModalIsVisible(false)
  }, [])
  const handleCloseLessonModal = useCallback(() => {
    setLessonModalIsVisible(false)
  }, [])

  const handleEditButtonClick = useCallback(() => {
    if (id) {
      history.push(`/edit/${id}`)
    }
  }, [history, id])

  const handleCreateChapterButtonClick = useCallback(() => {
    setChapterModalIsVisible(true)
  }, [])

  const handleCreateLessonButtonClick = useCallback(() => {
    setLessonModalIsVisible(true)
  }, [])

  const selectedChapter = useMemo(
    () => chapters?.find(chapter => chapter.id === selectedChapterId),
    [chapters, selectedChapterId],
  )

  const handleUpdateChapter = useCallback(
    (updatedChapter: Chapter) => {
      const updatedCourse = {
        ...course,
        chapters: chapters?.map(chapter => (chapter.id === updatedChapter.id ? updatedChapter : chapter)),
      }
      handleUpdateCourse(updatedCourse)
    },
    [chapters, course, handleUpdateCourse],
  )

  const handleUpdateLesson = useCallback(
    (updatedLesson: LessonType) => {
      if (selectedChapter === undefined) {
        return
      } else {
        const updatedChapter: Chapter = {
          ...selectedChapter,
          lessons: selectedChapter.lessons?.map(lesson => (lesson.id === updatedLesson.id ? updatedLesson : lesson)),
        }
        const updatedCourse = {
          ...course,
          chapters: chapters?.map(chapter => (chapter.id === updatedChapter.id ? updatedChapter : chapter)),
        }
        handleUpdateCourse(updatedCourse)
      }
    },
    [selectedChapter, course, chapters, handleUpdateCourse],
  )

  const handleDeleteChapter = useCallback(
    id => {
      // eslint-disable-next-line no-restricted-globals
      const isConfirmed = confirm('Are you sure you want to delete this chapter?')
      if (!isConfirmed) return

      handleUpdateCourse({
        ...course,
        chapters: chapters?.filter(chapter => chapter.id !== id),
      })
    },
    [chapters, course, handleUpdateCourse],
  )

  const handleDeleteLesson = useCallback(
    id => {
      if (selectedChapter === undefined) {
        return
      } else {
        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm('Are you sure you want to delete this lesson?')
        if (!isConfirmed) return

        const updatedChapter: Chapter = {
          ...selectedChapter,
          lessons: selectedChapter.lessons?.filter(lesson => lesson.id !== id),
        }
        const updatedCourse = {
          ...course,
          chapters: chapters?.map(chapter => (chapter.id === updatedChapter.id ? updatedChapter : chapter)),
        }
        handleUpdateCourse(updatedCourse)
      }
    },
    [selectedChapter, course, chapters, handleUpdateCourse],
  )

  const handleSelectChapter = useCallback((chapterId: string) => {
    setSelectedChapterId(chapterId)
  }, [])

  const handleSelectLesson = useCallback((lessonId: string) => {
    setSelectedLessonId(lessonId)
  }, [])

  const selectedLesson = useMemo(
    () => selectedChapter?.lessons?.find(lesson => lesson.id === selectedLessonId),
    [selectedChapter, selectedLessonId],
  )

  const handleCreateChapter = useCallback(
    (chapter: Chapter) => {
      const updatedCourse = { ...course, chapters: [...(course.chapters || []), chapter] }
      handleUpdateCourse(updatedCourse)
    },
    [course, handleUpdateCourse],
  )

  const handleCreateLesson = useCallback(
    (lesson: LessonType) => {
      if (selectedChapter === undefined) {
        return
      } else {
        const updatedChapter = { ...selectedChapter, lessons: [...(selectedChapter?.lessons || []), lesson] }
        // const updatedCourse: Course = { ...course, chapters: [...(course.chapters || []), updatedChapter] }
        const updatedCourse = {
          ...course,
          chapters: chapters?.map(chapter => (chapter.id === updatedChapter.id ? updatedChapter : chapter)),
        }
        handleUpdateCourse(updatedCourse)
      }
    },
    [chapters, course, handleUpdateCourse, selectedChapter],
  )

  const buttons = useMemo(
    () =>
      getButtons(
        handleCreateChapterButtonClick,
        handleCreateLessonButtonClick,
        handleEditButtonClick,
        selectedChapterId,
      ),
    [handleCreateChapterButtonClick, handleCreateLessonButtonClick, handleEditButtonClick, selectedChapterId],
  )

  const anchorOnDownloadRef = useRef<HTMLAnchorElement>(null)

  const { fileDownloadUrl, getDownloadLink, resetDownloadLink } = useExport()

  useEffect(() => {
    const anchorOnDownload = anchorOnDownloadRef.current

    if (fileDownloadUrl && anchorOnDownload) {
      anchorOnDownload.click()
    }

    resetDownloadLink()
  }, [fileDownloadUrl, resetDownloadLink])

  return (
    <>
      <ChapterModal onSave={handleCreateChapter} onClose={handleCloseChapterModal} isOpen={chapterModalIsVisible} />
      <LessonModal onSave={handleCreateLesson} onClose={handleCloseLessonModal} isOpen={lessonModalIsVisible} />
      <DefaultLayout
        backProps={{ text: '<- Return to menu', url: `/courses/${id}/course` }}
        navChapters={
          <ChaptersList
            selectedChapterId={selectedChapterId}
            onDeleteChapter={handleDeleteChapter}
            onSelectChapter={handleSelectChapter}
            onUpdateChapter={handleUpdateChapter}
            course={course}
          />
        }
        navLessons={
          <LessonsList
            selectedChapter={selectedChapter}
            selectedLessonId={selectedLessonId}
            onDeleteLesson={handleDeleteLesson}
            onSelectLesson={handleSelectLesson}
            onUpdateLesson={handleUpdateLesson}
            onUpdateChapter={handleUpdateChapter}
            course={course}
            onUpdateCourse={handleUpdateCourse}
          />
        }
        headerComponent={<Header title={`Edit Quest «${course.name}»  `} buttons={buttons} />}
      >
        {selectedChapter && selectedLesson && (
          <ChapterEdit
            selectedLesson={selectedLesson}
            selectedChapter={selectedChapter}
            chapters={chapters}
            onUpdateLesson={handleUpdateLesson}
          />
        )}
        <CustomButton
          onClick={() => getDownloadLink(course.id)}
          variant="contained"
          color="blue"
          style={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <Typography>Export JSON</Typography>
        </CustomButton>
        <a
          style={{ display: 'none' }}
          download={`${course.name}.json`}
          href={fileDownloadUrl}
          ref={anchorOnDownloadRef}
        >
          JSON download link (hidden)
        </a>
        <FixedFirestoreSyncBtnWrapper>
          <SyncFireStoreBtn course={course} />
          <SyncToProductionFirestoreBtn course={course} />
        </FixedFirestoreSyncBtnWrapper>
      </DefaultLayout>
    </>
  )
}

const FixedFirestoreSyncBtnWrapper = styled(Box)`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 10px;
  bottom: 50px;
`
