import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { MatchingOption } from '~/modals/message-modal/message-modal'
import { IMessage } from '~/types/course'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { CommentFields, CommentFieldsProps } from './fields/comment-fields'

type MatchingProps = {
  message?: IMessage
  matchingTitle: string
  handleMatchingTitle: (str: string) => void
  setMatchingTitle: (str: string | undefined) => void
  matchingOptions: MatchingOption[]
  setMatchingOptions: React.Dispatch<React.SetStateAction<MatchingOption[]>>
} & CommentFieldsProps

export const Matching = ({
  message,
  successAnswer,
  wrongAnswer,
  matchingTitle,
  handleMatchingTitle,
  setMatchingTitle,
  matchingOptions,
  setMatchingOptions,
}: MatchingProps) => {
  useEffect(() => {
    if (!message) return
    if (message.type === 'MATCHING') {
      setMatchingTitle(message.title)
      setMatchingOptions(message.options)
      wrongAnswer?.setValue?.(message.wrongAnswer)
      successAnswer?.setValue?.(message.successAnswer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, setMatchingTitle, setMatchingOptions])

  const handleAddingOption = useCallback(() => {
    setMatchingOptions(prevState => [
      ...prevState,
      {
        id: prevState.length + 1,
        content: '',
        position: prevState.length + 1,
        isEditing: true,
      },
    ])
  }, [setMatchingOptions])

  const handleDeletingOption = useCallback(
    (optionId: number) => {
      setMatchingOptions(prevState => prevState.filter(opt => opt.id !== optionId))
    },
    [setMatchingOptions],
  )

  const handleEditingOption = useCallback(
    (optionId: number) => {
      setMatchingOptions(prevState => prevState.map(opt => ({ ...opt, isEditing: opt.id === optionId })))
    },
    [setMatchingOptions],
  )

  const handleAcceptingChanges = useCallback(
    (optionId: number) => {
      setMatchingOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== optionId) return opt
          return { ...opt, isEditing: false }
        }),
      )
    },
    [setMatchingOptions],
  )

  const handleChangeOptionContent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
      setMatchingOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, content: e.target.value }
        }),
      )
    },
    [setMatchingOptions],
  )

  const handleChangeCount = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string | number) => {
      setMatchingOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, position: +e.target.value }
        }),
      )
    },
    [setMatchingOptions],
  )

  return (
    <Box>
      <Box py={2}>
        <TextField
          fullWidth
          label="Title"
          value={matchingTitle}
          onChange={e => handleMatchingTitle(e.target.value)}
          variant="outlined"
          style={{ marginTop: 10 }}
        />

        <CommentFields wrongAnswer={wrongAnswer} successAnswer={successAnswer} />

        {matchingOptions?.length > 0 && (
          <Box py={2} display="flex" alignItems="center">
            <FormControl fullWidth>
              <ColumnLabels>
                <FormLabel component="legend">Name:</FormLabel>
                <FormLabel component="legend">Position:</FormLabel>
              </ColumnLabels>

              {matchingOptions.map(option => (
                <OptionRow width="100%" key={option.id}>
                  {option.isEditing ? (
                    <TextField
                      value={option.content}
                      onChange={e => handleChangeOptionContent(e, option.id)}
                      autoFocus
                      multiline
                    />
                  ) : (
                    <FormControlLabel value={option.id} control={<Radio color="primary" />} label={option.content} />
                  )}
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    type="number"
                    size="medium"
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    value={option.position}
                    disabled={!option.isEditing}
                    onChange={e => handleChangeCount(e, option.id)}
                  />
                  <Box flex="0 0 auto">
                    {option.isEditing ? (
                      <IconButton
                        edge="end"
                        aria-label="accept"
                        onClick={() => handleAcceptingChanges(option.id)}
                        size="large"
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEditingOption(option.id)}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    )}

                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeletingOption(option.id)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </OptionRow>
              ))}
            </FormControl>
          </Box>
        )}
        <Button size="small" startIcon={<AddCircleOutlineIcon />} onClick={handleAddingOption}>
          Add an option
        </Button>
      </Box>
    </Box>
  )
}

const OptionRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 80px 80px;
  grid-gap: 10px;
`
const ColumnLabels = styled(OptionRow)`
  margin-bottom: 12px;
`
