import { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

type MatchParams = {
  id?: string
}

export const useCourseRoute = () => {
  const history = useHistory()
  const route = useRouteMatch<MatchParams>()
  const { id } = route.params
  const pathIdentifier = route.path.replace(/\/courses\/:id/gm, '')

  useEffect(() => {
    if (!id) {
      history.push('/')
    }
  }, [id, history])

  return {
    id,
    pathIdentifier,
  }
}
