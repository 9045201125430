import { observer } from 'mobx-react-lite'
import React from 'react'
import { useCommonStores } from '~/store/common'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

export const CustomDialog = observer(() => {
  const {
    dialogStore: { title, body, isOpen, closeDialog, contentStyle },
  } = useCommonStores()

  return (
    <Dialog onClose={closeDialog} fullWidth open={isOpen}>
      {title && (
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {title}
          <IconButton
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent style={contentStyle} dividers>
        {body}
      </DialogContent>
    </Dialog>
  )
})
