import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { isDevelopment } from '~/constants/platform'
import { coursesContext } from '~/contexts/courses'
import { PaymentLinks, PaymentLinksType } from '~/domain/payment-links'
import { PaymentLinksModal } from '~/modals/payment-links-modal'
import { paymentLinksStore } from '~/store'

import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type Column = {
  id: 'course' | 'amount' | 'type' | 'status' | 'usedUserId'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'course',
    label: 'Payment link',
  },
  { id: 'amount', label: 'Price (USD)', minWidth: 100 },
  {
    id: 'status',
    label: 'Status',
    align: 'right',
  },
  {
    id: 'type',
    label: 'Payment Type',
    align: 'right',
  },
  {
    id: 'usedUserId',
    label: 'Who used this',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '100vh',
    },
  }),
)

export const PaymentLinksTable = observer(() => {
  const classes = useStyles()
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const [link, setLink] = useState<PaymentLinksType>()
  const linksFromStore = toJS(paymentLinksStore.paymentLinks)

  const { course } = useContext(coursesContext)

  const links = useMemo(() => {
    return linksFromStore?.map(link => ({
      ...(link || {}),
      amount: isNaN(link?.price?.amountCents / 100)
        ? `${link?.price?.amountCents}`
        : `${link?.price?.amountCents / 100}`,
      usedAt: `${link?.usedAt}`,
      type: link?.chapterId ? 'Chapter (Module)' : 'Whole Quest',
      course: course?.slug,
    }))
  }, [linksFromStore, course])

  const handleUpdate = useCallback((updated: PaymentLinksType) => {
    const data = new PaymentLinks(updated)

    paymentLinksStore.updateLink(data)
  }, [])

  const handleCloseModal = () => {
    setModalIsVisible(false)
  }

  const handleEdit = useCallback(
    (id: string) => {
      const currentLink = linksFromStore?.find(item => item.id === id)

      setModalIsVisible(true)
      setLink(currentLink)
    },
    [linksFromStore],
  )

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={'edit'} style={{ minWidth: 50 }}>
                  Edit
                </TableCell>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {links?.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key={row.id} style={{ minWidth: 100 }}>
                      <IconButton edge="end" onClick={() => handleEdit(row.id)} size="large">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    {columns.map(column => {
                      const value = row[column.id]

                      if (column.id === 'course') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <a
                              href={
                                isDevelopment
                                  ? `https://develop--builderrank-customer.netlify.app/purchase/${value}?paymentType=link&link=${row.id}`
                                  : `https://builderrank-customer.netlify.app/purchase/${value}?paymentType=link&link=${row.id}`
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {value}
                            </a>
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {modalIsVisible && (
        <PaymentLinksModal
          course={course}
          onSave={handleUpdate}
          onClose={handleCloseModal}
          isOpen={modalIsVisible}
          link={link}
        />
      )}
    </>
  )
})
