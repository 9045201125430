import styled from 'styled-components'

const colors = {
  blue: '#2196f3',
  white: '#9e9e9e',
  disabled: 'rgba(0, 0, 0, 0.12)',
  green: '#3eb206',
}

const textContrastColors = {
  blue: '#fff',
  white: '#fff',
  green: '#fff',
  disabled: 'rgba(0, 0, 0, 0.26)',
}

export type CustomButtonProps = {
  variant?: 'contained' | 'text'
  color?: 'blue' | 'white' | 'transparent' | 'disabled' | 'green'
  bordered?: boolean
  disabled?: boolean
}

export const CustomButton = styled.button<CustomButtonProps>`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  border-radius: ${({ bordered = true }) => (bordered ? 4 : 0)}px;

  ${({ variant = 'text', color = 'transparent', disabled = false }) => `
    ${
      variant === 'contained' &&
      color !== 'transparent' &&
      `
      background-color: ${colors[color] || 'transparent'};
      color: ${textContrastColors[color] || '#333'};
      opacity: ${disabled ? 0.6 : 1};
      padding: 4px 8px;
    `
    }
  `}
`
