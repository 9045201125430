import { SkillType } from '~/domain/skills'

export const mergeFetchedAndStoreSkills = (fetchedSkills: SkillType[], storeSkills: SkillType[]) => {
  const fetchedSkillsToConcate = fetchedSkills.reduce<SkillType[]>((accumulatedSkills, fetchedSkill) => {
    const isFetchedSkillInStoreSkills = storeSkills.find(skill => skill.id === fetchedSkill.id)
    return isFetchedSkillInStoreSkills ? accumulatedSkills : [...accumulatedSkills, fetchedSkill]
  }, [])

  return [...storeSkills, ...fetchedSkillsToConcate]
}
