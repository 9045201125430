import hljs from 'highlight.js'
// @ts-ignore
import hljsDefineSolidity from 'highlightjs-solidity'
import * as React from 'react'
import Highlight from 'react-highlight'
import styled from 'styled-components'

import CloseIcon from '@mui/icons-material/Close'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'

hljsDefineSolidity(hljs)
hljs.initHighlightingOnLoad()

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type Structure = {
  name: string
  content: string
}

type UserStructure = {
  id: string
  userId: string
  taskId: string
  isValidFinished: boolean
  testResult: Structure[]
  task: null
  userStructure: Structure[]
  createdAt: number
  updatedAt: number
}

type CodePreviewModalProps = {
  open: boolean
  handleClose: () => void
  code: UserStructure[]
}

export default function CodePreviewModal({ open, handleClose, code = [] }: CodePreviewModalProps) {
  const [version, setVersion] = React.useState('')

  const structure = React.useMemo(() => {
    if (version !== '') {
      return code.find(structure => structure.id === version)?.userStructure
    }

    return []
  }, [code, version])

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Code
            </Typography>
          </Toolbar>
        </AppBar>
        <List sx={{ p: 4 }}>
          <FormControl sx={{ width: 300 }}>
            <InputLabel id="select-label">Select version</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={version}
              label="Version"
              required
              variant="outlined"
              onChange={e => {
                console.log(e)
                setVersion(e.target.value)
              }}
            >
              {code.map(structure => (
                <MenuItem key={structure.id} value={structure.id}>
                  {structure.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Preview files={structure ?? []} />
        </List>
      </Dialog>
    </React.Fragment>
  )
}

const Preview = ({ files }: { files: Structure[] }) => {
  const [content, setContent] = React.useState('')

  const handleClick = (file: Structure) => {
    setContent(file.content)
  }

  return (
    <PreviewLayout>
      <FilesLayout>
        {files.map(file => (
          <FileName key={file.name + file.content} onClick={() => handleClick(file)}>
            {file.name}
          </FileName>
        ))}
      </FilesLayout>
      <CodeLayout>
        <Highlight>{content}</Highlight>
      </CodeLayout>
    </PreviewLayout>
  )
}

const PreviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 75%;

  padding: 16px 0px;
`

const FilesLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
const FileName = styled.div`
  padding: 8px 0;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }
`

const CodeLayout = styled.div`
  display: flex;
`
