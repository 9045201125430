import React, { useCallback, useState } from 'react'
import { PaymentLinks, PaymentLinksType } from '~/domain/payment-links'
import { useCourseRoute } from '~/hooks/use-course-route'
import { PaymentLinksModal } from '~/modals/payment-links-modal'
import { paymentLinksStore } from '~/store'
import { Course } from '~/types/course'

import { Button } from '@mui/material'

export const AddNewLink = ({ course }: { course: Course }) => {
  const { id: courseId } = useCourseRoute()

  const [modalIsVisible, setModalIsVisible] = useState(false)

  const handleUpdate = useCallback(
    (data: PaymentLinksType) => {
      const payment = new PaymentLinks({ ...data, courseId: courseId ?? '' })

      paymentLinksStore.createLink(payment)
    },
    [courseId],
  )

  const handleOpenModal = () => {
    setModalIsVisible(true)
  }

  const handleCloseModal = () => {
    setModalIsVisible(false)
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Add payment link
      </Button>
      {modalIsVisible && (
        <PaymentLinksModal course={course} onSave={handleUpdate} onClose={handleCloseModal} isOpen={modalIsVisible} />
      )}
    </div>
  )
}
