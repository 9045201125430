import { UserRole } from '~/domain/user'

export const courseNavButtons = [
  { text: 'Edit Course Content', path: 'course/edit', roles: [UserRole.admin] },
  { text: 'Analytics', path: 'analytics', roles: [UserRole.admin] },
  { text: 'Users-meta', path: 'users-meta', roles: [UserRole.admin] },
  // { text: 'Droplet Configurations (Virtual Machines)', path: 'droplets', roles: [UserRole.admin] },
  // { text: 'User Droplets', path: 'user-droplets', roles: [UserRole.admin] },
  // { text: 'Payment Links', path: 'links', roles: [UserRole.admin] },
  // { text: 'Payments', path: 'payments', roles: [UserRole.admin] },
]
